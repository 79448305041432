import Layout from "@/layout";
import __ from "@/translation";
import * as Five9ManagedUser from "@/utils/Five9AdminConsoleManagedUser";

const manageModuleRouter = {
  // define the route
  path: "/manage",

  // this component describes the structure of the app after a user logs in. This component houses the sidebar and sets remaining area to populate contents of the child views
  component: Layout,

  redirect: "/", // when user tries to reach /manage, it automatically tries to redirect to dashboard
  meta: {
    // meta data to pass additional information about the route
    auth: true, // this specifies that only logged in user can access this route. If someone tries to access this route, we will redirect to /login route
    // singleChild property is a custom information and is used in populating sidebar, to say that the child routes should be shown as sub menu to main menu 'Manage',
    // if it is true then first child will be shown as the main menu in the sidebar without any submenus.
    singleChild: false,
    icon: "icon-manage.svg",
    scopes: ["sp", "ac"],
    get title() {
      return __("Manage");
    }, // label to show in the menu
    index: "sp-1" // unique index to identify the entry from the sidebar menu
  },
  children: [
    // {
    //   path: "profile",
    //   component: () => import("@/views/manage/profile/index"),
    //   meta: {
    //     title: "Profile",
    //     icon: "icon-users.svg",
    //     index: "sp-1-13"
    //   },
    //   children: [
    //     {
    //       path: "",
    //       component: () => import("@/views/manage/profile/index"),
    //       name: "profile",
    //       meta: {
    //         title: "User Profile"
    //       },
    //       props: true,
    //       hidden: true
    //     }
    //   ]
    // },
    {
      path: "users",
      component: () => import("@/views/manage/users/index"),
      get hidden() {
        return Five9ManagedUser.currentUserIsManaged();
      },
      meta: {
        get title() {
          return __("Users");
        },
        icon: "icon-users.svg",
        index: "sp-1-1",
        permissions: ["manage.users"],
        scopes: ["ac", "sp"]
      },
      children: [
        {
          path: "",
          component: () => import("@/views/manage/users/index"),
          name: "users",
          meta: {
            get title() {
              return __("Users");
            },
            permissions: ["manage.users"]
          },
          props: true,
          hidden: true
        }
      ]
    },
    // {
    //   path: "provisioning",
    //   component: () => import("@/views/manage/provisioning/index"),
    //   redirect: "/manage/provisioning",
    //   meta: {
    //     title: "Provisioning",
    //     icon: "el-icon-magic-stick",
    //     index: "sp-1-2"
    //     // scopes: ["sp"]
    //   },
    //   children: [
    //     {
    //       path: "",
    //       component: () => import("@/views/manage/provisioning/pages/index"),
    //       name: "Provisioning",
    //       meta: {
    //         title: "Provisioning"
    //       },
    //       props: true,
    //       hidden: true
    //     }
    //   ]
    // },
    {
      path: "telephony",
      component: () => import("@/views/manage/telephony/index"),
      meta: {
        get title() {
          return __("Telephony");
        },
        icon: "icon-telephony.svg",
        index: "sp-1-4",
        permissions: ["manage.telephony"],
        scopes: ["sp"]
      },
      children: [
        {
          path: "",
          component: () => import("@/views/manage/telephony/index"),
          name: "telephony",
          meta: {
            get title() {
              return __("Telephony");
            },
            permissions: ["manage.telephony"]
          },
          props: true,
          hidden: true
        }
      ]
    },
    // {
    //   path: "studio-credits",
    //   component: () => import("@/views/manage/studio-credits/index"),
    //   redirect: "/manage/studio-credits",
    //   meta: {
    //     title: "Studio Credits",
    //     icon: "el-icon-s-finance",
    //     index: "sp-1-4"
    //     // scopes: ["sp"]
    //   },
    //   children: [
    //     {
    //       path: "",
    //       component: () => import("@/views/manage/studio-credits/pages/index"),
    //       name: "studio-credits",
    //       meta: {
    //         title: "Studio Credits"
    //       },
    //       props: true,
    //       hidden: true
    //     }
    //   ]
    // },
    {
      path: "email-profiles",
      component: () => import("@/views/manage/emailprofiles/index"),
      meta: {
        get title() {
          return __("Email Profiles");
        },
        icon: "icon-email-profile.svg",
        index: "sp-1-5",
        permissions: ["manage.email-profiles"]
      },
      children: [
        {
          path: "",
          component: () => import("@/views/manage/emailprofiles/pages/list"),
          name: "emailprofiles",
          meta: {
            get title() {
              return __("Email Profiles");
            },
            permissions: ["manage.email-profiles"]
          },
          props: true,
          hidden: true
        }
      ]
    },
    {
      path: "accounts",
      component: () => import("@/views/manage/accounts/index"),
      redirect: "/manage/accounts",
      meta: {
        get title() {
          return __("Accounts");
        },
        icon: "icon-accounts.svg",
        index: "sp-1-6",
        scopes: ["sp"],
        permissions: ["manage.accounts"]
      },
      children: [
        {
          path: "",
          component: () => import("@/views/manage/accounts/pages/list"),
          name: "accounts",
          meta: {
            get title() {
              return __("Accounts");
            },
            permissions: ["manage.accounts"]
          },
          props: true,
          hidden: true
        }
      ]
    },
    {
      path: "ac-plans",
      component: () => import("@/views/billing/ac-plans/index"),
      redirect: "/manage/ac-plans",
      meta: {
        get title() {
          return __("Account Plans");
        },
        icon: "icon-account-plans.svg",
        index: "sp-1-12",
        scopes: ["sp"],
        permissions: ["manage.account-plan"]
      },
      children: [
        {
          path: "",
          component: () => import("@/views/billing/ac-plans/pages/listAcPlan"),
          name: "ac-plans",
          meta: {
            get title() {
              return __("Account Plans");
            },
            permissions: ["manage.account-plan"]
          },
          props: true,
          hidden: true
        }
      ]
    },
    {
      path: "payment-profiles",
      component: () => import("@/views/manage/payment/payment-profiles/index"),
      meta: {
        get title() {
          return __("Payment Profiles");
        },
        icon: "icon-payment-profile.svg",
        index: "sp-1-7",
        permissions: ["manage.payment-profiles"]
      },
      children: [
        {
          path: "",
          component: () =>
            import("@/views/manage/payment/payment-profiles/pages/list"),
          name: "paymentprofiles",
          meta: {
            get title() {
              return __("Payment Profiles");
            },
            permissions: ["manage.payment-profiles"]
          },
          props: true,
          hidden: true
        }
      ]
    },
    {
      path: "auth-profiles",
      component: () => import("@/views/manage/auth-profiles/index"),
      meta: {
        get title() {
          return __("Authentication");
        },
        icon: "icon-users.svg",
        index: "ac-1-7",
        permissions: ["manage.auth-profiles"]
      },
      children: [
        {
          path: "",
          component: () => import("@/views/manage/auth-profiles/pages/list"),
          name: "authprofiles",
          meta: {
            get title() {
              return __("Authentication");
            },
            permissions: ["manage.auth-profiles"]
          },
          props: true,
          hidden: true
        }
      ]
    },
    {
      path: "wsl-profiles",
      component: () => import("@/views/manage/wsl-profiles/index"),
      meta: {
        get title() {
          return __("Well Said Labs Profiles");
        },
        icon: "icon-well-said-labs.svg",
        index: "ac-1-8",
        permissions: ["manage.wsl-profiles"]
      },
      children: [
        {
          path: "",
          component: () => import("@/views/manage/wsl-profiles/pages/list"),
          name: "wslprofiles",
          meta: {
            get title() {
              return __("Well Said Labs Profiles");
            },
            permissions: ["manage.wsl-profiles"]
          },
          props: true,
          hidden: true
        }
      ]
    },
    {
      path: "node-logging-profiles",
      component: () => import("@/views/manage/node-logging-profiles/index"),
      meta: {
        get title() {
          return __("Node Logging Profiles");
        },
        icon: "icon-node-logging-profiles.svg",
        index: "sp-1-16",
        permissions: ["manage.node-logging-profiles"]
      },
      children: [
        {
          path: "",
          component: () => import("@/views/manage/node-logging-profiles/index"),
          name: "nodeLoggingProfiles",
          meta: {
            get title() {
              return __("Node Logging Profiles");
            },
            permissions: ["manage.node-logging-profiles"]
          },
          props: true,
          hidden: true
        }
      ]
    },
    {
      path: "sms-profiles",
      component: () => import("@/views/manage/sms/sms-profiles/index"),
      meta: {
        get title() {
          return __("SMS Profiles");
        },
        icon: "icon-email-profiles.svg",
        index: "sp-1-8",
        permissions: ["manage.sms-profiles"]
      },
      children: [
        {
          path: "",
          component: () => import("@/views/manage/sms/sms-profiles/pages/list"),
          name: "smsprofiles",
          meta: {
            get title() {
              return __("SMS Profiles");
            },
            permissions: ["manage.sms-profiles"]
          },
          props: true,
          hidden: true
        }
      ]
    },
    {
      path: "password-policies",
      component: () => import("@/views/manage/password-policy/index"),
      meta: {
        get title() {
          return __("Password Policy");
        },
        icon: "icon-password-policy.svg",
        index: "sp-1-15",
        permissions: ["manage.password-policies"],
        scopes: ["sp"]
      },
      children: [
        {
          path: "",
          component: () => import("@/views/manage/password-policy/pages/list"),
          name: "passwordpolicies",
          meta: {
            get title() {
              return __("Password Policies");
            },
            permissions: ["manage.password-policies"]
          },
          props: true,
          hidden: true
        }
      ]
    },
    {
      path: "dnis-pool",
      component: () => import("@/views/manage/dnis-pool/index"),
      meta: {
        get title() {
          return __("DNIS Pool");
        },
        icon: "icon-telephony.svg",
        index: "sp-1-17",
        permissions: ["manage.dnis-pool"]
      },
      children: [
        {
          path: "",
          component: () => import("@/views/manage/dnis-pool/pages/list"),
          name: "dnispool",
          meta: {
            get title() {
              return __("DNIS Pool");
            },
            permissions: ["manage.dnis-pool"]
          },
          props: true,
          hidden: true
        }
      ]
    },
    {
      path: "api-keys",
      component: () => import("@/views/manage/api-keys/index"),
      meta: {
        get title() {
          return __("API Keys");
        },
        icon: "icon-api-key.svg",
        index: "ac-1-4",
        permissions: ["manage.api-keys"]
      },
      children: [
        {
          path: "",
          component: () => import("@/views/manage/api-keys/pages/list"),
          name: "apikeys",
          meta: {
            get title() {
              return __("API Keys");
            },
            permissions: ["manage.api-keys"]
          },
          props: true,
          hidden: true
        }
      ]
    },
    {
      path: "sp-profile",
      component: () => import("@/views/manage/sp-profile/index"),
      meta: {
        get title() {
          return __("Service Provider Profile");
        },
        icon: "icon-service-providers.svg",
        index: "sp-1-19",
        permissions: ["manage.sp-profile"],
        scopes: ["sp"]
      },
      children: [
        {
          path: "",
          component: () => import("@/views/manage/sp-profile/index"),
          name: "spprofiles",
          meta: {
            get title() {
              return __("Service Provider Profile");
            },
            permissions: ["manage.sp-profile"]
          },
          props: true,
          hidden: true
        }
      ]
    },
    {
      path: "ac-profile",
      component: () => import("@/views/manage/ac-profile/index"),
      meta: {
        get title() {
          return __("Account Profile");
        },
        icon: "icon-accounts.svg",
        index: "ac-1-5",
        permissions: ["manage.ac-profile"],
        scopes: ["sp", "ac"]
      },
      children: [
        {
          path: "",
          component: () => import("@/views/manage/ac-profile/index"),
          name: "acprofiles",
          meta: {
            get title() {
              return __("Account Profile");
            },
            permissions: ["manage.ac-profile"]
          },
          props: true,
          hidden: true
        }
      ]
    },
    //hidden for now as this feature is no longer used https://five9inc.atlassian.net/browse/STUD-10968?focusedCommentId=1603987
    // {
    //   path: "notifications",
    //   component: () => import("@/views/manage/notifications/index"),
    //   meta: {
    //     get title() {
    //       return __("Notifications");
    //     },
    //     icon: "icon-notification-bell.svg",
    //     index: "ac-1-7",
    //     permissions: ["manage.notifications"]
    //   },
    //   children: [
    //     {
    //       path: "",
    //       component: () => import("@/views/manage/notifications/index"),
    //       name: "manage-notifications",
    //       meta: {
    //         get title() {
    //           return __("Notifications");
    //         },
    //         permissions: ["manage.notifications"]
    //       },
    //       props: true,
    //       hidden: true
    //     }
    //   ]
    // },
    {
      path: "exports",
      component: () => import("@/views/manage/exports/index"),
      meta: {
        title: "Exports",
        icon: "icon-notification-bell.svg",
        index: "ac-1-8",
        permissions: ["manage.exports"]
      },
      children: [
        {
          path: "",
          component: () => import("@/views/manage/exports/index"),
          name: "manage-exports",
          meta: {
            title: "Exports",
            permissions: ["manage.exports"]
          },
          props: true,
          hidden: true
        }
      ]
    }
  ]
};

export default manageModuleRouter;
