<template>
  <div>
    <h2 class="group-name-title">{{ __("Included Data") }}</h2>
    <p>
      {{ __("Select data to be included in export (optional).") }}
    </p>
    <div class="input-block">
      <el-form-item prop="included_data" class="grey-background">
        <el-collapse>
          <el-collapse-item v-for="(type, key) in nonEmptyDataType" :key="key">
            <template slot="title">
              {{ __(type.title) }}
              <el-tooltip
                placement="right"
                content="Selected datastores will include the last 10,000 records."
              >
                <i
                  class="header-icon el-icon-info"
                  v-if="type.title === 'Datastores'"
                ></i>
              </el-tooltip>
            </template>
            <el-checkbox
              style="margin-left: 20px; font-weight: bold"
              v-model="checkAll[key]"
              @change="handleCheckAllChange(key, $event)"
              >{{ allTitle(type.title) }}</el-checkbox
            >
            <el-checkbox-group
              v-model="checkedData[key]"
              @change="handleCheckedChange(key, $event)"
            >
              <el-checkbox
                style="vertical-align: middle; position: relative; display: block; margin: 20px"
                v-for="data in getData(key)"
                :label="data[type.id]"
                :key="data[type.id]"
                >{{ data[type.name] }}</el-checkbox
              >
            </el-checkbox-group>
            <div v-if="isAudioDataStore(key)">
              <span style="margin: 20px; color: #A0A8B5">{{
                __(
                  "Cannot export data as the following datastores have audio columns"
                )
              }}</span>
              <el-checkbox-group>
                <el-checkbox
                  style="vertical-align: middle; position: relative; display: block; margin: 20px"
                  v-for="data in audioDataStore"
                  :label="data[type.id]"
                  :key="data[type.id]"
                  disabled
                  >{{ data[type.name] }}</el-checkbox
                >
              </el-checkbox-group>
            </div>
          </el-collapse-item>
        </el-collapse>
      </el-form-item>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import __ from "@/translation";
const DATA_STORES = "data_stores";
const DATA_STORE_INFO = {
  id: "data_store_id",
  name: "display_name",
  title: "Datastores"
};
const AUTH_PROFILES = "auth_profiles";
const AUTH_PROFILE_INFO = {
  id: "auth_profile_id",
  name: "auth_profile_name",
  title: "Auth Profiles"
};
const VARIABLES = "variables";
const VARIABLE_INFO = {
  id: "variable_id",
  name: "variable_name",
  title: "Secure Variables"
};

export default {
  name: "ExportIncludedData",
  props: {
    snapData: {
      required: true,
      type: Object,
      default: () => ({
        data_stores: [],
        auth_profiles: [],
        variables: []
      })
    },
    checkedDataProps: {
      type: Object,
      required: true,
      default: () => ({
        data_stores: [],
        auth_profiles: [],
        variables: []
      })
    }
  },
  data() {
    return {
      checkAll: {
        data_stores: false,
        auth_profiles: false,
        variables: false
      },
      dataType: {
        [DATA_STORES]: DATA_STORE_INFO,
        [AUTH_PROFILES]: AUTH_PROFILE_INFO,
        [VARIABLES]: VARIABLE_INFO
      },
      audioDataStore: []
    };
  },
  methods: {
    handleCheckAllChange(path, val) {
      let allData = _.map(
        _.get(this.snapData, path, []),
        data => data[this.dataType[path].id]
      );
      _.set(this.checkedData, path, val ? allData : []);
    },
    handleCheckedChange(path, val) {
      let checkedCount = val.length;
      let dataLen = this.getData(path).length;
      _.set(this.checkAll, path, checkedCount === dataLen && dataLen > 0);
    },
    getData(path) {
      return _.get(this.snapData, path, []);
    },
    allTitle(title) {
      return __(`All ${title}`);
    },
    removeAudioDataStore(snapData) {
      return _.remove(snapData.data_stores, data_store => {
        return _.some(data_store.collection_structure, { type: "audio" });
      });
    },
    isAudioDataStore(type) {
      return type === DATA_STORES && !_.isEmpty(this.audioDataStore);
    }
  },
  computed: {
    checkedData: {
      get() {
        return this.checkedDataProps;
      },
      set(val) {
        this.$emit("select", val);
      }
    },
    nonEmptyDataType() {
      return _.pickBy(this.dataType, (value, key) => {
        return !_.isEmpty(this.getData(key));
      });
    }
  },
  watch: {
    snapData: {
      immediate: true,
      handler(value) {
        this.audioDataStore = this.removeAudioDataStore(value);
        _.forEach(this.dataType, (val, key) => {
          this.handleCheckedChange(key, this.checkedData[key]);
        });
      }
    }
  }
};
</script>

<style scoped lang="scss">
.grey-background {
  ::v-deep * {
    background-color: #f5f5f5;
  }
  ::v-deep div {
    border: 0;
  }
  ::v-deep div.el-collapse-item__header {
    display: block;
    font-weight: bold;
    i.el-icon-arrow-right {
      margin-top: 17px;
      float: left;
    }
  }
}
</style>
