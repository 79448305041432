<template>
  <div
    v-loading="isReceiving"
    :element-loading-text="loadingMessage"
    class="loading-dialog"
  >
    <el-button
      icon="el-icon-arrow-left"
      plain
      text
      class="backBtn"
      @click="redirectToLoginPage"
      >{{ __("Back to Login") }}
    </el-button>
    <div class="reset-twofa-title">
      <div>
        {{ __("Reset 2FA") }}
      </div>
      <div class="welcome-dot"><span>.</span></div>
    </div>
    <template v-if="resetHasError">
      <div style="margin-bottom:40px;text-align:center" id="reset-twofa-error">
        <span>
          {{ resetErrorMessage }}
        </span>
      </div>
    </template>
  </div>
</template>
<script>
import ValidateReset from "@/views/mixins/ValidateReset.vue";
import { mapActions } from "vuex";

export default {
  name: "Reset2FAForm",
  components: {},
  mixins: [ValidateReset],
  data() {
    return {
      credentials: {
        email: "",
        token: "",
        password: ""
      },
      loadingMessage: ""
    };
  },
  methods: {
    ...mapActions("users", {
      reset2FA: "reset2FA"
    }),
    handleReset2FA() {
      this.loadingMessage = __("Resetting 2FA...");
      this.credentials.email = this.email;
      this.credentials.token = this.token;
      this.reset2FA(this.credentials)
        .then(() => {
          this.isReceiving = false;
          this.$message.success(
            __("Request Verified. Login to proceed with 2FA setup")
          );
          setTimeout(() => {
            this.redirectToLoginPage();
          }, 500);
        })
        .catch(err => {
          this.isReceiving = false;
          this.resetHasError = true;
          const errorMessage = err.message || __("Operation failed");
          this.resetErrorMessage = errorMessage;
          this.$message.error(errorMessage);
        });
    }
  },
  async created() {
    await this.processReset();
    this.handleReset2FA();
  }
};
</script>
<style lang="scss" scoped>
@import "~@/styles/element-variables.scss";

.loading-dialog {
  padding-top: 80px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 40px;
  width: 400px;
  background: #ffffff;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.15);
  border-radius: 10px;

  .backBtn {
    border: none;
    font-size: 14px;
    font-weight: bold;
    padding-left: 0px;

    ::v-deep i {
      font-size: 18px;
      font-weight: bolder;
    }

    ::v-deep span {
      margin-bottom: 6px;
    }
  }

  .reset-twofa-title {
    font-weight: bolder;
    font-size: 36px;
    display: flex;
    flex-direction: row;
    color: $--color-text-primary;
    margin-bottom: 50px;

    .welcome-dot {
      text-shadow: 0 4px 4px rgba(28, 209, 161, 0.26);
      color: $--color-primary;
    }
  }
}
</style>
