import _ from "lodash";
import {
  getNodeTypes,
  createNodeType,
  updateNodeType,
  deleteNodeType
} from "@/api/nodeTypes";

import { accountNodeTypes } from "@/api/nodes";
import { NODE_TYPES } from "@/constants/nodes";
import __ from "@/translation";

export const NODE_TYPES_DEFAULT_HIDDEN_UNDER_LD = [
  NODE_TYPES.SUMMARIZATION.NODE_TYPE,
  NODE_TYPES.PROACTIVE_MESSAGE.NODE_TYPE,
  NODE_TYPES.KNOWLEDGE_DISTILLER.NODE_TYPE,
  NODE_TYPES.AI_CUSTOM_PROMPT.NODE_TYPE
];

export const NODE_TYPES_AVAILABLE_FOR_ACCOUNT_WITH_LINKED_DOMAIN = [
  NODE_TYPES.KNOWLEDGE_DISTILLER.NODE_TYPE,
  NODE_TYPES.AI_CUSTOM_PROMPT.NODE_TYPE
];

export const state = {
  nodeTypes: [],
  originalNodeTypes: [],
  isLoading: false,
  categories: [
    {
      label: __("System"),
      value: "system"
    },
    {
      label: __("One Child"),
      value: "one_child"
    },
    {
      label: __("Multiple Child"),
      value: "multiple_child"
    },
    {
      label: __("No Child"),
      value: "no_child"
    }
  ],
  accountNodeTypeMap: [],
  accountRawNodes: []
};

export const mutations = {
  SET_NODE_TYPES(state, nodeTypes) {
    state.nodeTypes = nodeTypes;
  },

  CREATE_NODE_TYPE(state, nodeType) {
    state.nodeTypes.push(nodeType);
  },

  UPDATE_LOADING_STATUS(state, isLoading) {
    state.isLoading = isLoading;
  },

  UPDATE_NODE_TYPE(state, { index, nodeType }) {
    state.nodeTypes.splice(index, 1, nodeType);
  },

  DELETE_NODE_TYPE(state, nodeType) {
    state.nodeTypes = state.nodeTypes.filter(
      oldNodeType => oldNodeType.node_type !== nodeType.node_type
    );
  },

  SET_NODE_TYPES_FOR_ACCOUNT(state, { ac_id, task_type, allowedNodeTypes }) {
    const accountToNodeTypeMap = _.find(state.accountNodeTypeMap, { ac_id });
    if (_.isEmpty(accountToNodeTypeMap)) {
      let map = { ac_id };
      map[task_type] = _.cloneDeep(allowedNodeTypes);
      state.accountNodeTypeMap.push(map);
    } else {
      accountToNodeTypeMap[task_type] = _.cloneDeep(allowedNodeTypes);
    }
    state.accountNodeTypeMap = _.cloneDeep(state.accountNodeTypeMap);
  },

  SET_RAW_NODE_TYPES_FOR_ACCOUNT(
    state,
    { ac_id, task_type, allowedNodeTypeNames }
  ) {
    const accountToNodeTypeMap = _.find(state.accountRawNodes, { ac_id });
    if (_.isEmpty(accountToNodeTypeMap)) {
      let map = { ac_id };
      map[task_type] = _.cloneDeep(allowedNodeTypeNames);
      state.accountRawNodes.push(map);
    } else {
      accountToNodeTypeMap[task_type] = _.cloneDeep(allowedNodeTypeNames);
    }
    state.accountRawNodes = _.cloneDeep(state.accountRawNodes);
  },

  RESET_ACCOUNT_TO_NODE_TYPES_MAP(state) {
    state.accountNodeTypeMap = [];
  },

  SET_ORIGINAL_PLAN_NODE_TYPES(state, nodeTypes) {
    state.originalNodeTypes = nodeTypes;
  }
};

export const actions = {
  getNodeTypes({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      getNodeTypes(options)
        .then(async ({ data }) => {
          commit("SET_NODE_TYPES", data.data);
          commit("SET_ORIGINAL_PLAN_NODE_TYPES", data.data);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("UPDATE_LOADING_STATUS", false);
        });
    });
  },

  setNodeTypesExcludingBasePlanNodes({ commit }, nodeTypes) {
    commit("SET_NODE_TYPES", nodeTypes);
  },

  resetNodeTypesAsPerPlanType({ commit, state }) {
    commit("SET_NODE_TYPES", state.originalNodeTypes);
  },

  createNodeType({ commit, state, dispatch }, nodeType) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      createNodeType(nodeType)
        .then(async ({ data }) => {
          if (!state.nodeTypes.length) {
            await dispatch("getNodeTypes");
            commit("UPDATE_LOADING_STATUS", false);
            resolve(data);
          } else {
            commit("CREATE_NODE_TYPE", data.data);
            commit("UPDATE_LOADING_STATUS", false);
            resolve(data);
          }
        })
        .catch(err => {
          console.log(err);
          commit("UPDATE_LOADING_STATUS", false);
          reject(err);
        });
    });
  },

  updateNodeType({ commit }, nodeType) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);

      updateNodeType(nodeType)
        .then(({ data }) => {
          let index = _.findIndex(state.nodeTypes, function(oldNodeType) {
            return oldNodeType.node_type === nodeType.node_type;
          });
          commit("UPDATE_TTS_VOICE", { index, nodeType: data.data });
          commit("UPDATE_LOADING_STATUS", false);

          resolve(data);
        })
        .catch(err => {
          console.log(err);
          commit("UPDATE_LOADING_STATUS", false);
          reject(err);
        });
    });
  },

  deleteNodeType({ commit }, nodeType) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LOADING_STATUS", true);
      deleteNodeType(nodeType)
        .then(data => {
          commit("DELETE_NODE_TYPE", nodeType);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("UPDATE_LOADING_STATUS", false);
        });
    });
  },

  accountNodeTypes({ state, rootState, dispatch }, { task_type }) {
    return new Promise((resolve, reject) => {
      let ac_id = rootState.app.selectedAccountId;
      let accountToNodeTypes = _.find(state.accountNodeTypeMap, {
        ac_id
      });

      if (
        rootState.businessplans.planChanged ||
        rootState.acplans.planChanged ||
        _.isEmpty(accountToNodeTypes) ||
        _.isEmpty(accountToNodeTypes[task_type])
      ) {
        dispatch("forceUpdateAccountNodeTypes", { ac_id, task_type })
          .then(() => resolve())
          .catch(err => reject(err));
      } else {
        resolve();
      }
    });
  },

  /**
   * force update account node types
   * @param commit
   * @param ac_id
   * @param task_type
   * @returns {Promise<unknown>}
   */
  forceUpdateAccountNodeTypes({ commit }, { ac_id, task_type }) {
    return new Promise((resolve, reject) => {
      accountNodeTypes({ task_type })
        .then(({ data }) => {
          let allowedNodeTypeNames = _.map(data.data, "node_type");
          commit("SET_RAW_NODE_TYPES_FOR_ACCOUNT", {
            ac_id,
            task_type,
            allowedNodeTypeNames
          });
          let allowedNodeTypes = {};
          _.map(NODE_TYPES, (node_type, name) => {
            if (
              allowedNodeTypeNames.includes(node_type.NODE_TYPE) ||
              node_type.AVAILABLE_TO_ALL_PLANS
            ) {
              // Hidden Override logic to hide node based on task type
              if (
                node_type.HIDE_NODE_TASK_OVERRIDE &&
                node_type.HIDE_NODE_TASK_OVERRIDE.includes(task_type)
              ) {
                // add this line to allow visual form nodes for chatbot
                //allowedNodeTypes[name] = _.cloneDeep(node_type);
                return false;
              } else {
                allowedNodeTypes[name] = _.cloneDeep(node_type);
              }
            }
            if (
              node_type.NODE_TYPE === "open_form" &&
              allowedNodeTypeNames.includes("form") &&
              !allowedNodeTypeNames.includes("open_form")
            ) {
              allowedNodeTypes[name] = _.cloneDeep(node_type);
            }
          });

          commit("SET_NODE_TYPES_FOR_ACCOUNT", {
            ac_id,
            task_type,
            allowedNodeTypes
          });
          resolve();
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  }
};

export const getters = {
  getCategoryLabel(state) {
    return category => {
      return _.find(state.categories, { value: category }).label;
    };
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
