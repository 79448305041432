import Vue from "vue";
import router from "./router";
import store from "./store";
import storage from "@/utils/localStorageManager";
import _ from "lodash";

import { Message } from "element-ui";
import { EventBus } from "./EventBus";
import routerUtils from "@/utils/router";
import { appendSector } from "@/utils/url";

const whiteList = [
  "/login",
  "/login/google/callback",
  "/login/okta/callback",
  "/login/okta/launch",
  "/profile",
  "/invite",
  "/reset/password",
  "/reset/twofa",
  "/forgot",
  "/login/five9-sso",
  "/visualform"
]; // no redirect whitelist

router.beforeEach(async (to, from, next) => {
  var hostaddress = window.location.host;
  //console.log(window.location.host);
  //console.log(storage.get("subdomain"));

  // determine whether the user has logged in
  const isLoggedIn = await Vue.auth.check();
  EventBus.$emit("close-modal"); // close the modal if its opened

  if (!isLoggedIn && whiteList.indexOf(to.path) !== -1) {
    //console.log("check subdomain");
    var subdomain = hostaddress.split(".")[0].toLowerCase();
    let store_subdomain = storage.get("subdomain");
    if (store_subdomain !== undefined) {
      store_subdomain = store_subdomain.toLowerCase();
    }

    if (
      store_subdomain !== "" &&
      store_subdomain !== undefined &&
      store_subdomain !== subdomain
    ) {
      // when user tries to manually change subdomain in url and navigate to original domain
      let target =
        storage.get("subdomain") +
        "." +
        _.tail(hostaddress.split(".")).join(".");
      // console.log("target is ", target);
      next(target);
    } else {
      //  console.log(store.getters.redirected);
      var xhttp = new XMLHttpRequest();
      const url = appendSector(
        window.location.hostname,
        process.env.VUE_APP_BACKEND_BASE_API,
        "/subdomain/" + hostaddress
      );
      xhttp.onreadystatechange = function() {
        if (xhttp.readyState === 4 && xhttp.status === 200) {
          // Typical action to be performed when the document is ready:
          //console.log(xhttp.responseText);
          if (xhttp.responseText === "invalid subdomain") {
            Message.error(xhttp.responseText);
          } else if (xhttp.responseText === "not a subdomain") {
            if (whiteList.indexOf(to.path) !== -1) {
              next();
            } else if (from.path !== "/" && to.path !== "/login") {
              //store.dispatch("app/setRedirected", true);
              if (
                [
                  "/login/google/callback",
                  "/login/five9-sso",
                  "/login/okta/callback",
                  "/verify/otp",
                  "/setup/otp",
                  "/forgot",
                  "/reset/password",
                  "/reset/twofa"
                ].includes(to.path)
              ) {
                next("/login");
              } else {
                const params = new URLSearchParams(to.query);
                let redirectUrl = params
                  ? to.path + "?" + params.toString()
                  : to.path;
                next({ name: "Login", query: { redirectUrl } });
              }
            } else {
              next();
            }
          } else {
            let data = JSON.parse(xhttp.responseText);
            store.dispatch("app/setAppSubdomain", data.data.subdomain);
            store.dispatch("app/setSpLogo", data.data.logo_url);
            store.dispatch("theme/setThemeColors", data.data.theme_setting);
            next();
          }
        }
      };
      xhttp.open("GET", url, false);
      xhttp.send();
      //}
    }
  } else if (
    [
      "/login",
      "/login/google/callback",
      "/login/five9-sso",
      "/login/okta/callback",
      "/verify/otp",
      "/setup/otp"
    ].includes(from.path)
  ) {
    next();
  } else if (isLoggedIn) {
    if (
      [
        "/login",
        "/verify/otp",
        "/reset/password",
        "/reset/twofa",
        "/forgot"
      ].includes(to.path)
    ) {
      // if is logged in, redirect to the home page
      if (to.query.redirectUrl) {
        next(to.query.redirectUrl);
      } else {
        next({ path: "/" });
      }
      // next();
    } else {
      // if route permissions are enabled then check if route is present in an array of features
      if (!routerUtils.hasPrivilege(to)) {
        return next("/");
      }

      // determine whether the user has defined his app view type or is user going to set up the app view scope, then proceed
      if (store.getters.userType) {
        next();
      } else {
        try {
          let obj = {};
          const params = new URLSearchParams(to.query);
          let ac_id = params.get("ac_id");
          let sp_id = params.get("sp_id");
          if (sp_id) {
            obj.sp_id = sp_id;
          }
          if (ac_id) {
            obj.ac_id = ac_id;
          }

          if (_.isEmpty(obj)) {
            await store.dispatch("app/generateUserAccountSwitcherOptions");
          } else {
            await store.dispatch("app/generateUserAccountSwitcherOptions", obj);
          }
          next({ ...to, replace: true });
        } catch (error) {
          // remove token and go to login page to re-login
          Message.error(error || "Has Error");
          next("/login");
        }
      }
    }
  } else {
    /* has no token*/
    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next();
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      if (
        [
          "/login/google/callback",
          "/login/five9-sso",
          "/login/okta/callback",
          "/verify/otp",
          "/setup/otp",
          "/forgot",
          "/reset/password",
          "/reset/twofa"
        ].includes(to.path)
      ) {
        next("/login");
      } else {
        const params = new URLSearchParams(to.query);
        let redirectUrl = params ? to.path + "?" + params.toString() : to.path;
        next({ name: "Login", query: { redirectUrl } });
      }
    }
  }
});

router.afterEach(() => {
  window.popStateDetected = false;
});
