<template>
  <div style="width: 100%;" @click="handleClear">
    <page-header
      class="absolute"
      style="padding: 20px 30px; width: calc(100% - 335px)"
      :title="__('Users')"
    />
    <el-button
      class="createBtn"
      @click.stop="createContentItem"
      :disabled="!this.canWrite()"
      >{{ __("Create User") }}</el-button
    >
    <div style="padding: 0 36px; margin-top: 136px;">
      <el-form class="form-container">
        <PaginationToolbar
          :sort-by-options="sortByOptions"
          :show-only="showOnly"
          :content-api="contentAPI"
          :show-slot="true"
          @success="$emit('replaceContent', $event)"
        >
          <el-col class="user-filter" :span="3" slot="buttonGroup">
            <el-select
              v-if="showUserFilter"
              id="user-filter"
              :placeholder="__('Filter Users')"
              default-first-option
              v-model="showOnly"
              filterable
            >
              <el-option
                v-for="(item, index) in filterOptions"
                :label="item.label"
                :value="item.value"
                :key="index"
              />
            </el-select>
          </el-col>
          <template v-if="this.multipleSelectionHasItem">
            <el-col :span="4" slot="buttonGroup">
              <el-select
                ref="MultiSelectAction"
                class="multiSelectAction"
                default-first-option
                filterable
                v-model="action"
                :placeholder="__('Select Action For')"
                @change="handleActionChange"
              >
                <el-option
                  v-for="option in multiSelectActions"
                  :key="option.value"
                  :label="option.label"
                  :value="option.value"
                  :disabled="option.shouldDisable"
                >
                  <i :class="`${option.icon} table-actions`"></i>
                  {{ option.label }}
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="4" slot="buttonGroup">
              <span
                style="margin: 10px 18px;align-items: initial;display: inline-block;"
                id="selectionCount"
              >
                {{
                  __(":selecteduserscount user(s)", {
                    selecteduserscount: this.selectedUsersCount
                  })
                }}
              </span>
            </el-col>
          </template>
        </PaginationToolbar>
      </el-form>
      <el-scrollbar :native="true">
        <div class="contentListContainer">
          <el-table
            ref="usersTable"
            class="list-table"
            :data="users"
            :row-key="row => row.user_id"
            highlight-current-row
            @row-click="handleSingleClick"
            @row-dblclick="handleDoubleClick"
            @selection-change="handleSelectRows"
            v-loading="loading"
            v-show="this.canRead()"
          >
            <el-table-column
              id="usersSelection"
              v-if="userRoleHasSystemRoleOrScope"
              type="selection"
              width="55"
              :reserve-selection="true"
            />
            <el-table-column :label="__('Email')" prop="email" />
            <el-table-column :label="__('Login time')" prop="login_time">
              <template slot-scope="{ row }">
                {{ formattedDateTime(row.login_time) }}
              </template>
            </el-table-column>
            <el-table-column :label="__('User Role')" prop="role_name">
              <template
                slot-scope="{ row }"
                v-if="Array.isArray(row.role_name)"
              >
                <div v-if="row.role_name.length > 1">
                  {{ row.role_name[0] }} + {{ row.role_name.length - 1 }} more
                </div>
                <div v-else-if="row.role_name.length === 1">
                  {{ row.role_name[0] }}
                </div>
                <div v-else></div>
              </template>
            </el-table-column>
            <el-table-column :label="__('Service Provider')" prop="sp_name">
              <template slot-scope="{ row }" v-if="Array.isArray(row.sp_name)">
                <div v-if="row.sp_name === ''">
                  {{ row.sp_name }}
                </div>
                <div v-else-if="row.sp_name.length > 1">
                  {{ row.sp_name[0] }} + {{ row.sp_name.length - 1 }} more
                </div>
                <div v-else>
                  {{ row.sp_name[0] }}
                </div>
              </template>
            </el-table-column>
            <el-table-column :label="__('Account')">
              <template slot-scope="{ row }" v-if="Array.isArray(row.scope)">
                <div v-if="row.scope === 'Service Provider'">
                  <el-tag type="danger">
                    {{ row.scope }}
                  </el-tag>
                </div>
                <div v-else>
                  <!--                  validate whether the user can access more than one account-->
                  <div v-if="row.scope === 'System'">
                    {{ row.scope }}
                  </div>
                  <div v-else-if="row.scope.length > 1">
                    {{ row.scope[0].ac_name }} + {{ row.scope.length - 1 }} more
                  </div>
                  <div v-else-if="!!row.scope.length">
                    {{ row.scope[0].ac_name }}
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column :label="__('Status')" prop="user_status_label" />
            <el-table-column
              :label="__('2FA')"
              prop="system_controlled_twofa_label"
            />
          </el-table>
        </div>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import PageHeader from "@/components/PageHeader";
import PaginationToolbar from "@/components/PaginationToolbar";
import BaseContent from "@/views/build/content/mixins/BaseContent";
import { EventBus } from "@/EventBus";
import _ from "lodash";
const defaultFilterOptions = [
  {
    get label() {
      return __("All Users");
    },
    value: "all"
  },
  {
    get label() {
      return __("Only SP Users");
    },
    value: "sp"
  }
];

export default {
  name: "UsersManageList",
  mixins: [BaseContent],
  components: {
    PaginationToolbar,
    PageHeader
  },
  data() {
    return {
      sortByColumns: ["display_name", "user_id"],
      showOnly: "all",
      search: "",
      filters: ["display_name", "email"],
      listLoading: false,
      listQuery: {
        page: 1,
        limit: 10
      },
      multipleSelection: [],
      multiSelectActions: [
        {
          value: "enable_2fa",
          label: __("Enable Mandatory 2FA"),
          icon: "el-icon-open",
          shouldDisable: false
        },
        {
          value: "disable_2fa",
          label: __("Disable Mandatory 2FA"),
          icon: "el-icon-turn-off",
          shouldDisable: false
        }
      ],
      action: ""
    };
  },

  computed: {
    ...mapState("users", {
      users: state => state.users,
      loading: state => state.isLoading
    }),

    ...mapState("app", {
      userRole: state => state.userRole,
      selectedServiceProviderId: state => state.selectedServiceProviderId,
      selectedAccountId: state => state.selectedAccountId,
      userAccountSwitcherOptions: state => state.userAccountSwitcherOptions
    }),
    ...mapGetters("app", {
      accountsForCreate: "accountsForCreate",
      formattedDateTime: "formattedDateTime",
      selectedServiceProviderAccountCombination:
        "selectedServiceProviderAccountCombination",
      inSystemScope: "inSystemScope",
      inSpScope: "inSpScope",
      userRoleHasSystemRoleOrScope: "userRoleHasSystemRoleOrScope"
    }),

    /**
     * Show user filter, only if it's a system user and
     * if either in sp or system scope
     */
    showUserFilter() {
      return (
        this.$auth.check("system") && (this.inSystemScope || this.inSpScope)
      );
    },

    /**
     * Provide filter options for user
     */
    filterOptions() {
      let filterOptions = defaultFilterOptions;
      if (this.inSystemScope) {
        filterOptions = _.union(filterOptions, [
          {
            get label() {
              return __("Only System Users");
            },
            value: "system"
          }
        ]);
      }
      return filterOptions;
    },
    multipleSelectionHasItem() {
      return this.multipleSelection.length > 0;
    },
    selectedUsersCount() {
      return this.multipleSelection.length;
    },
    shouldShowDisable2FA() {
      return !_.some(
        this.multipleSelection,
        user => !user.system_controlled_twofa
      );
    }
  },

  methods: {
    ...mapActions("users", {
      contentAPI: "getUsers",
      deleteContentMethod: "deleteUser",
      undoDeleteContent: "undoDeleteUser",
      enableOrDisable2faBySystemUser: "enableOrDisable2faBySystemUser"
    }),
    handleClear() {
      this.$refs.usersTable && this.$refs.usersTable.setCurrentRow();
      this.handleClearSelection();
    },
    handleSingleClick(row, column, event) {
      event.stopPropagation();
      this.handleSelect(null, row);
    },
    handleDoubleClick(row, column, event) {
      event.stopPropagation();

      //Block edit if User is Inactivate
      if (row.user_status !== "Active") {
        this.$message.info(
          // eslint-disable-next-line
          __("Please activate the user")
        );
        return;
      }

      const isServiceProviderAll = this.selectedServiceProviderId === "all";
      const isAccountAll = this.selectedAccountId === "all";

      //System Level & Login as a System User & Target user is a system user
      if (
        isServiceProviderAll &&
        isAccountAll &&
        row.current_role &&
        !row.ac_sp_map_role_permission.length &&
        this.userRole === "system"
      ) {
        this.handleEdit(null, row);
      } else if (
        //Sp Level
        (!isServiceProviderAll && isAccountAll) ||
        //Ac Level && Only one Ac under the Sp
        (!isServiceProviderAll &&
          !isAccountAll &&
          this.userAccountSwitcherOptions[0]["accounts"].length === 1)
      ) {
        const selectedUserAccScope = row.account_permissions.flatMap(ap =>
          ap.accounts.map(acc => acc.ac_id)
        );
        const loginUserAccScope = this.accountsForCreate.map(
          acc => acc.value.ac_id
        );
        if (
          selectedUserAccScope.every(ac_id => loginUserAccScope.includes(ac_id))
        ) {
          this.handleEdit(null, row);
        } else {
          //Target User has more Ac access under current Sp
          this.$message.info(
            // eslint-disable-next-line
                __("You don't have sufficient privileges to edit this user.")
          );
        }
      } else {
        const spNames = row.sp_name
          .filter((value, index, array) => array.indexOf(value) === index)
          .join(", ");
        const messageKey =
          row.sp_name.length > 1
            ? // eslint-disable-next-line
            __("Please select All Accounts under one of the following Service Providers [:spNames] to edit user info", { spNames })
            : // eslint-disable-next-line
              __("Please select All Accounts under Service Provider :spName to edit user info", { spName: row.sp_name[0] });

        this.$message.info(messageKey);
      }
    },
    handleSelectRows(selection) {
      this.multipleSelection = selection;
    },
    getActionLabel() {
      let actionObject = this.getActionObject();
      return actionObject ? actionObject.label : "";
    },
    getActionObject() {
      return _.find(this.multiSelectActions, {
        value: this.action
      });
    },
    getConfirmButtonText() {
      let actionObject = this.getActionObject();
      return actionObject
        ? __(":confirmText", {
            confirmText: actionObject.label.split(" ")[0]
          })
        : __("Confirm");
    },
    handleEnableOrDisable() {
      let message = __(":selectedAction for the selected user(s)?", {
        selectedAction: this.getActionLabel()
      });
      this.$confirm(message, __("Mandatory 2FA"), {
        confirmButtonText: this.getConfirmButtonText(),
        cancelButtonText: __("Cancel"),
        showClose: false,
        confirmButtonClass: "msgBoxConfirmBtn",
        cancelButtonClass: "msgBoxCancelBtn",
        customClass: "msgBox"
      })
        .then(() => {
          this.handleEnableOrDisable2faForUsers();
        })
        .catch(() => {})
        .finally(() => {
          this.resetActionSelection();
        });
    },
    handleActionChange(action) {
      switch (action) {
        case "enable_2fa":
        case "disable_2fa":
          this.handleEnableOrDisable();
          break;
      }
    },
    resetActionSelection() {
      this.action = "";
      //collapse the dropdown
      this.$refs.MultiSelectAction.blur();
    },
    resetSelections() {
      this.$refs.usersTable.clearSelection();
    },
    handleEnableOrDisable2faForUsers() {
      let payload = {
        user_ids: _.map(this.multipleSelection, user => user.user_id),
        action: this.action
      };
      let actionLabel = this.getActionLabel();
      this.enableOrDisable2faBySystemUser(payload)
        .then(() => {
          this.resetSelections();
          EventBus.$emit("list-changed", null);
          this.$message.success(
            __(":actionLabel successfully", {
              actionLabel: actionLabel
            })
          );
        })
        .catch(error => {
          this.$message.error(error.message);
        });
    },
    tagsArray(tagsString) {
      return tagsString.split(",", 2);
    }
  },
  watch: {
    selectedItemIndex: {
      immediate: true,
      handler: function(val) {
        if (val !== -1 && val !== null) {
          this.$refs.usersTable.setCurrentRow(this.users[val]);
        }
      }
    },
    selectedServiceProviderId: {
      immediate: true,
      handler: function(val) {
        if (val !== -1 && val !== null) {
          EventBus.$emit("list-changed", null);
        }
      }
    },
    selectedServiceProviderAccountCombination: {
      handler() {
        this.showOnly = "all";
        if (this.$refs.usersTable) {
          this.resetSelections();
        }
      }
    },
    multipleSelection: {
      deep: true,
      handler() {
        this.multiSelectActions = _.map(this.multiSelectActions, action => {
          if (action.value === "disable_2fa") {
            action.shouldDisable = !this.shouldShowDisable2FA;
          }
          return action;
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
@import "~@/styles/content-buttons.scss";
@import "~@/styles/pagination-toolbar.scss";
@import "~@/styles/content-list.scss";
.user-filter {
  display: flex;
  padding-left: 15px;
}
.msgBoxConfirmBtn {
  background: var(--theme-color) !important;
  border-color: var(--theme-color) !important;
  border: none;
  color: white;

  &:hover {
    background: var(--theme-hover-color) !important;
    color: white;
  }
}

.msgBoxCancelBtn {
  border: none;
  background: transparent !important;
}

.multiSelectAction {
  margin-left: 10px;
  height: 44px;
  width: 30px;
  border-color: #f5f5f8;
  color: #a0a8b5;
  &:hover {
    background-color: transparent;
    border-color: $content-theme-color;
    ::v-deep svg {
      fill: $content-theme-hover-color;
      path {
        fill: $content-theme-hover-color;
      }
    }
  }
  .slotImg {
    width: 24px;
    margin-left: -10px !important;
  }
  &.is-disabled:hover,
  &.is-disabled {
    color: #a0a8b5;
    border-color: #f5f5f8;
    ::v-deep svg {
      fill: #a0a8b5;
      path {
        fill: #a0a8b5;
      }
    }
  }
}
</style>
