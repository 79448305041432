<template>
  <div class="resetPswdContainer" v-loading="isReceiving">
    <el-button
      icon="el-icon-arrow-left"
      plain
      text
      class="backBtn"
      @click="redirectToLoginPage"
      >{{ __("Back to Login") }}
    </el-button>

    <div class="reset-pswd-title">
      <div>
        {{ __("Reset Password") }}
      </div>
      <div class="welcome-dot"><span>.</span></div>
    </div>
    <template>
      <el-form
        ref="resetPasswordForm"
        label-position="top"
        label-width="100px"
        :model="credentials"
        class="form-container"
        :rules="rules"
        hide-required-asterisk
      >
        <el-form-item prop="email" label="Email" class="email-form-item text">
          <el-input
            v-model="credentials.email"
            :placeholder="__('Please input email')"
          />
        </el-form-item>

        <el-form-item
          prop="password"
          label="Password"
          class="password-form-item text"
        >
          <el-input
            type="password"
            v-model="credentials.password"
            :placeholder="__('Please input password')"
            autocomplete="off"
            show-password
          />
        </el-form-item>

        <el-form-item
          prop="password_confirmation"
          :label="__('Confirm Password')"
          class="password-form-item text"
        >
          <el-input
            v-model="credentials.password_confirmation"
            :placeholder="__('Please input the password again')"
            type="password"
            autocomplete="off"
          />
        </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            id="reset-pswd-btn"
            class="w-full text"
            @click="sendResetPassword"
          >
            {{ __("Reset Password") }}
          </el-button>
        </el-form-item>
      </el-form>
    </template>
    <template v-if="resetHasError">
      <div style="margin-bottom:40px;text-align:center" id="reset-pswd-error">
        <span>
          {{ resetErrorMessage }}
        </span>
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { validEmail } from "@/utils/validate";
import ValidateReset from "@/views/mixins/ValidateReset.vue";

export default {
  name: "ResetPasswordForm",
  components: {},
  mixins: [ValidateReset],
  data() {
    const validateEmail = (rule, value, callback) => {
      if (!validEmail(value)) {
        callback(new Error(__("Please enter valid email")));
      } else {
        callback();
      }
    };
    const validatePassword = (rule, value, callback) => {
      if (value === "") {
        callback(__("Please input the password"));
      } else {
        if (this.credentials.password_confirmation !== "") {
          this.$refs.resetPasswordForm.validateField("password_confirmation");
        }
        callback();
      }
    };
    const validateConfirmPassword = (rule, value, callback) => {
      if (value === "") {
        callback(__("Please input the password again"));
      } else if (value !== this.credentials.password) {
        callback(__("Two passwords don't match!"));
      } else {
        callback();
      }
    };
    return {
      credentials: {
        email: "",
        token: "",
        password: "",
        password_confirmation: ""
      },
      rules: {
        email: [{ required: true, validator: validateEmail, trigger: "blur" }],
        password: [
          { required: true, validator: validatePassword, trigger: "blur" }
        ],
        password_confirmation: [
          {
            required: true,
            validator: validateConfirmPassword,
            trigger: "blur"
          }
        ]
      }
    };
  },

  async created() {
    await this.processReset();
    this.isReceiving = false;
  },

  methods: {
    ...mapActions("users", {
      resetPassword: "resetPassword"
    }),
    sendResetPassword: function() {
      this.$refs.resetPasswordForm.validate(valid => {
        if (valid) {
          this.isReceiving = true;
          this.credentials.token = this.token;
          this.resetPassword(this.credentials)
            .then(() => {
              this.isReceiving = false;
              this.$message.success(
                __(
                  "Your password has been reset successfully. Please login to continue."
                )
              );
              setTimeout(() => {
                this.redirectToLoginPage();
              }, 500);
            })
            .catch(error => {
              this.isReceiving = false;
              this.resetHasError = true;
              const errorMessage = error.message || __("Operation failed");
              this.resetErrorMessage = errorMessage;
              this.$message.error(errorMessage);
            });
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/element-variables.scss";

.resetPswdContainer {
  padding-top: 80px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 40px;
  width: 400px;
  background: #ffffff;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.15);
  border-radius: 10px;

  .text {
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
  }

  .backBtn {
    border: none;
    font-size: 14px;
    font-weight: bold;
    padding-left: 0px;

    ::v-deep i {
      font-size: 18px;
      font-weight: bolder;
    }

    ::v-deep span {
      margin-bottom: 6px;
    }
  }

  .reset-pswd-title {
    font-weight: bolder;
    font-size: 36px;
    display: flex;
    flex-direction: row;
    color: $--color-text-primary;
    margin-bottom: 50px;

    .welcome-dot {
      text-shadow: 0 4px 4px rgba(28, 209, 161, 0.26);
      color: $--color-primary;
    }
  }

  ::v-deep .el-form-item--medium .el-form-item__label {
    line-height: 7px;
  }

  ::v-deep .el-input--medium .el-input__inner {
    height: 50px;
    line-height: 50px;
  }

  .email-form-item {
    margin-bottom: 36px;
  }

  .password-form-item {
    margin-bottom: 36px;
  }

  #reset-pswd-btn {
    border-radius: 5px;
    height: 50px;
  }
}

.w-full {
  width: 100%;
}
</style>
