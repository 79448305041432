import request, { sp_headers, ac_headers } from "@/utils/studio7ApiService";
import store from "@/store";
import _ from "lodash";

export function getUsers(options, userType, inAllAccounts = true) {
  const params = new URLSearchParams(options);
  return request({
    url: "/auth/users?" + params.toString(),
    method: "get",
    headers:
      userType === "ac"
        ? _.merge(ac_headers(), {
            "sp-id": store.state.app.selectedServiceProviderId
          })
        : inAllAccounts
        ? sp_headers()
        : ac_headers()
  });
}

export function getUser(user_id, userType) {
  return request({
    url: `/auth/users/${user_id}`,
    method: "get",
    headers:
      userType === "ac"
        ? _.merge(ac_headers(), {
            "sp-id": store.state.app.selectedServiceProviderId
          })
        : sp_headers()
  });
}

export function createUser(user, scope) {
  return request({
    url: "/auth/users",
    method: "post",
    data: user,
    // headers: user.account_mappings[0].mapping_scope === "ac" ? ac_headers() : user.account_mappings[0].mapping_scope === "sp" ? sp_headers() : {}
    headers:
      scope === "ac"
        ? _.merge(ac_headers(), {
            "sp-id": store.state.app.selectedServiceProviderId
          })
        : sp_headers()
  });
}

export function updateUser(user, scope) {
  return request({
    url: `/auth/users/${user.user_id}`,
    method: "put",
    data: user,
    headers:
      scope === "ac"
        ? _.merge(ac_headers(), {
            "sp-id": store.state.app.selectedServiceProviderId
          })
        : sp_headers()
  });
}

export function updateUserProfile(user) {
  return request({
    url: `/auth/users/manage/profile`,
    method: "post",
    data: user,
    headers: sp_headers()
  });
}

export function updateUserPassword(user) {
  return request({
    url: `/auth/users/password/${user.user_id}`,
    method: "put",
    data: user,
    headers: sp_headers()
  });
}

export function deleteUser(user, scope) {
  return request({
    url: `/auth/users/${user.user_id}`,
    method: "delete",
    data: user,
    headers:
      scope === "ac"
        ? _.merge(ac_headers(), {
            "sp-id": store.state.app.selectedServiceProviderId
          })
        : sp_headers()
  });
}

export function forceDeleteUser(user, scope) {
  return request({
    url: `/auth/users/force-delete/${user.user_id}`,
    method: "post",
    data: user,
    headers:
      scope === "ac"
        ? _.merge(ac_headers(), {
            "sp-id": store.state.app.selectedServiceProviderId
          })
        : sp_headers()
  });
}

export function updateAccountMap(user_id, data) {
  return request({
    url: `/auth/users/accountmaps/${user_id}`,
    method: "put",
    data,
    headers: sp_headers()
  });
}

export function listAccountsForUser(user_id) {
  return request({
    url: `/auth/users/accountmaps/list/${user_id}`,
    method: "get",
    headers: sp_headers()
  });
}

export function validateEmailAndToken(email, token, type) {
  return request({
    url: `/auth/users/email/${email}/token/${token}/${type}`,
    method: "get"
  });
}

export function validateUniqueEmailAndInvite(user) {
  return request({
    url: `/auth/users/validate/invite`,
    method: "post",
    data: user,
    headers: sp_headers()
  });
}

export function createUserProfile(user) {
  return request({
    url: `/auth/users/create/profile`,
    method: "post",
    data: user
  });
}

export function revokeInvitedUser(user) {
  return request({
    url: `/auth/users/delete/invite`,
    method: "post",
    data: user
  });
}

export function reVerifyInactiveUser(user, scope) {
  return request({
    url: `/auth/users/re-verify`,
    method: "post",
    data: user,
    headers:
      scope === "ac"
        ? _.merge(ac_headers(), {
            "sp-id": store.state.app.selectedServiceProviderId
          })
        : sp_headers()
  });
}

export function mapInvitesToUserAccountMaps(email, token) {
  return request({
    url: `/auth/users/create/map/${email}/token/${token}`,
    method: "get"
  });
}

export function sendForgotPasswordEmail(email) {
  return request({
    url: `/forgot-password`,
    method: "post",
    data: email
  });
}

export function sendForgot2FAEmail(email) {
  return request({
    url: `/forgot-twofa`,
    method: "post",
    data: email
  });
}

export function resetPassword(user) {
  return request({
    url: `/reset-password`,
    method: "post",
    data: user
  });
}

export function reset2FA(user) {
  return request({
    url: `/reset-twofa`,
    method: "post",
    data: user
  });
}

export function validatePasswordResetEmailAndToken(user) {
  return request({
    url: `reset-password/validate`,
    method: "post",
    data: user
  });
}

export function validate2FAResetEmailAndToken(user) {
  return request({
    url: `reset-twofa/validate`,
    method: "post",
    data: user
  });
}

export function get2FA() {
  return request({
    url: `/auth/users-twofa`,
    method: "get"
  });
}

export function getPasswordValidationSummary() {
  return request({
    url: `/auth/password-policy/summary`,
    method: "get",
    headers: sp_headers()
  });
}

export function enableOrDisable2faBySystemUser(data) {
  return request({
    url: `/auth/system-controlled-twofa`,
    method: "put",
    data: data
  });
}
