<template>
  <div v-if="nonEmptyNode">
    <component
      :is="nodeType"
      :clickedNode="node"
      :task_id="task_id"
      :selectedNodeType="selectedNodeType"
      @handleCancel="handleCancel"
    ></component>
  </div>
</template>

<script>
import { mapState } from "vuex";
import _ from "lodash";
import { NODE_TYPES } from "@/constants/nodes";

// should add all node types here
// static imports first for nodes
import SendMessageNode from "./node-type-forms/messaging/SendMessageNode";
import ReplyMessageNode from "./node-type-forms/messaging/ReplyMessageNode";
import ConversationMessageNode from "./node-type-forms/messaging/ConversationMessageNode";
import WebServiceNode from "./node-type-forms/web-service/WebServiceNode";
import ProactiveMessage from "./node-type-forms/messaging/ProactiveMessageNode";

const IntegrationNode = () =>
  import(
    /* webpackChunkName: "integrationNode" */ "./node-type-forms/web-service/IntegrationNode"
  );
const VariableNode = () =>
  import(
    /* webpackChunkName: "variableNode" */ "./node-type-forms/VariableNode"
  );
const PromptNode = () =>
  import(
    /* webpackChunkName: "promptNode" */ "./node-type-forms/audio/PromptNode"
  );
const SendEmailNode = () =>
  import(
    /* webpackChunkName: "sendEmailNode" */ "./node-type-forms/SendEmailNode"
  );
const OpenFormNode = () =>
  import(
    /* webpackChunkName: "openFormNode" */ "./node-type-forms/audio/OpenFormNode"
  );
const DecisionNode = () =>
  import(
    /* webpackChunkName: "decisionNode" */ "./node-type-forms/decision/DecisionNode"
  );
const PaymentProcessNode = () =>
  import(
    /* webpackChunkName: "paymentProcessNode" */ "./node-type-forms/PaymentProcessNode"
  );
const CalendarNode = () =>
  import(
    /* webpackChunkName: "calendarNode" */ "./node-type-forms/flow/CalendarNode"
  );
const DaytimeNode = () =>
  import(
    /* webpackChunkName: "daytimeNode" */ "./node-type-forms/flow/DaytimeNode"
  );
const GotoNode = () =>
  import(/* webpackChunkName: "gotoNode" */ "./node-type-forms/flow/GotoNode");
const DistributeNode = () =>
  import(
    /* webpackChunkName: "distributeNode" */ "./node-type-forms/flow/DistributeNode"
  );
const DatastoreNode = () =>
  import(
    /* webpackChunkName: "datastoreNode" */ "./node-type-forms/data/DatastoreNode"
  );
const HangupNode = () =>
  import(
    /* webpackChunkName: "hangupNode" */ "./node-type-forms/flow/HangupNode"
  );
const SetVoiceNode = () =>
  import(
    /* webpackChunkName: "setVoiceNode" */ "./node-type-forms/SetVoiceNode"
  );

const KeywordFinderNode = () =>
  import(
    /* webpackChunkName: "keywordFinderNode" */ "./node-type-forms/KeywordFinderNode"
  );
const CheckWaiterNode = () =>
  import(
    /* webpackChunkName: "checkWaiterNode" */ "./node-type-forms/qforme/CheckWaiterNode"
  );
const AddToQueueNode = () =>
  import(
    /* webpackChunkName: "addToQueueNode" */ "./node-type-forms/qforme/AddToQueueNode"
  );
const PauseNode = () =>
  import(/* webpackChunkName: "pauseNode" */ "./node-type-forms/PauseNode");
const CommentNode = () =>
  import(
    /* webpackChunkName: "commentNode" */ "./node-type-forms/data/CommentNode"
  );
const RecordNode = () =>
  import(
    /* webpackChunkName: "recordNode" */ "./node-type-forms/audio/RecordNode"
  );
const BlindTransferNode = () =>
  import(
    /* webpackChunkName: "blindTransferNode" */ "./node-type-forms/transfer/BlindTransferNode"
  );
const BridgeTransferNode = () =>
  import(
    /* webpackChunkName: "bridgeTransferNode" */ "./node-type-forms/transfer/BridgeTransferNode"
  );
const ScreenpopTransferNode = () =>
  import(
    /* webpackChunkName: "sceenpopTransferNode" */ "./node-type-forms/transfer/ScreenpopTransferNode"
  );
const Five9TransferNode = () =>
  import(
    /* webpackChunkName: "five9TransferNode" */ "./node-type-forms/transfer/Five9TransferNode"
  );
const StartNode = () =>
  import(/* webpackChunkName: "startNode" */ "./node-type-forms/StartNode");
const BiometricsNode = () =>
  import(
    /* webpackChunkName: "biometricsNode" */ "./node-type-forms/biometrics/BiometricsNode"
  );
const TriggerCampaignNode = () =>
  import(
    /* webpackChunkName: "triggerCampaignNode" */ "./node-type-forms/web-service/TriggerCampaignNode"
  );
const SleepNode = () =>
  import(
    /* webpackChunkName: "sleepNode" */ "./node-type-forms/flow/SleepNode"
  );
const PromptRecorderNode = () =>
  import(
    /* webpackChunkName: "promptRecorderNode" */ "./node-type-forms/audio/PromptRecorderNode"
  );
const MenuNode = () =>
  import(/* webpackChunkName: "menuNode" */ "./node-type-forms/audio/MenuNode");
const HangupHeaderNode = () =>
  import(
    /* webpackChunkName: "hangupHeaderNode" */ "./node-type-forms/decision/HangupHeaderNode"
  );
const CloudSTTNode = () =>
  import(
    /* webpackChunkName: "cloudSttNode" */ "./node-type-forms/audio/CloudSTTNode"
  );
const LinkTaskNode = () =>
  import(
    /* webpackChunkName: "linkTaskNode" */ "./node-type-forms/flow/LinkTaskNode"
  );
const TextFormNode = () =>
  import(
    /* webpackChunkName: "textFormNode" */ "./node-type-forms/audio/TextFormNode"
  );
const WatsonSpeechNode = () =>
  import(
    /* webpackChunkName: "watsonSpeechNode" */ "./node-type-forms/audio/WatsonSpeechNode"
  );
const VisualFormNode = () =>
  import(
    /* webpackChunkName: "visualFormNode" */ "@/views/build/callflow/components/node-type-forms/data/VisualFormNode"
  );
const SummarizationNode = () =>
  import(
    /* webpackChunkName: "summarizationNode" */ "./node-type-forms/generative-ai/SummarizationNode"
  );

const KnowledgeDistillerNode = () =>
  import(
    /* webpackChunkName: "knowledgeDistillerNode" */ "./node-type-forms/generative-ai/KnowledgeDistillerNode"
  );

const AiCustomPromptNode = () =>
  import(
    /* webpackChunkName: "knowledgePromptNode" */ "./node-type-forms/generative-ai/KnowledgePromptNode"
  );

export default {
  components: {
    IntegrationNode,
    SendMessageNode,
    ReplyMessageNode,
    ConversationMessageNode,
    VariableNode,
    PromptNode,
    CalendarNode,
    SendEmailNode,
    OpenFormNode,
    DecisionNode,
    PaymentProcessNode,
    DaytimeNode,
    DatastoreNode,
    HangupNode,
    GotoNode,
    DistributeNode,
    SetVoiceNode,
    WebServiceNode,
    KeywordFinderNode,
    CheckWaiterNode,
    AddToQueueNode,
    PauseNode,
    CommentNode,
    RecordNode,
    BlindTransferNode,
    BridgeTransferNode,
    ScreenpopTransferNode,
    Five9TransferNode,
    StartNode,
    BiometricsNode,
    TriggerCampaignNode,
    SleepNode,
    PromptRecorderNode,
    MenuNode,
    HangupHeaderNode,
    CloudSTTNode,
    LinkTaskNode,
    TextFormNode,
    WatsonSpeechNode,
    VisualFormNode,
    SummarizationNode,
    KnowledgeDistillerNode,
    AiCustomPromptNode,
    ProactiveMessage
  },
  props: {
    task_id: {
      required: true,
      type: Number
    },
    selectedNodeType: {
      required: true,
      type: String
    }
  },
  computed: {
    ...mapState("canvas", {
      clickedNode: state => state.clickedNode
    }),
    node() {
      return _.cloneDeep(this.clickedNode);
    },
    nodeType() {
      this.$emit("nodeLoading");
      return this.selectComponentToRender(this.selectedNodeType);
    },
    nonEmptyNode() {
      return !_.isEmpty(this.node);
    }
  },

  methods: {
    handleCancel() {
      this.$emit("handleCancel");
    },
    selectComponentToRender(nodeType) {
      switch (nodeType) {
        case NODE_TYPES.INTEGRATION.NODE_TYPE:
          return "IntegrationNode";
        case NODE_TYPES.SEND_SMS.NODE_TYPE:
          return "SendMessageNode";
        case NODE_TYPES.REPLY_SMS.NODE_TYPE:
          return "ReplyMessageNode";
        case NODE_TYPES.PROACTIVE_MESSAGE.NODE_TYPE:
          return "ProactiveMessage";
        case NODE_TYPES.CONVERSATION_SMS.NODE_TYPE:
          return "ConversationMessageNode";
        case NODE_TYPES.PROMPT.NODE_TYPE:
          return "PromptNode";
        case NODE_TYPES.VARIABLE.NODE_TYPE:
          return "VariableNode";
        case NODE_TYPES.CALENDAR.NODE_TYPE:
          return "CalendarNode";
        case NODE_TYPES.SEND_EMAIL.NODE_TYPE:
          return "SendEmailNode";
        case NODE_TYPES.OPEN_FORM.NODE_TYPE:
          return "OpenFormNode";
        case NODE_TYPES.CLOUD_STT.NODE_TYPE:
          return "CloudSTTNode";
        case NODE_TYPES.DECISION.NODE_TYPE:
          return "DecisionNode";
        case NODE_TYPES.PAYMENT.NODE_TYPE:
          return "PaymentProcessNode";
        case NODE_TYPES.DAYTIME.NODE_TYPE:
          return "DaytimeNode";
        case NODE_TYPES.DISTRIBUTE.NODE_TYPE:
          return "DistributeNode";
        case NODE_TYPES.GOTO.NODE_TYPE:
          return "GotoNode";
        case NODE_TYPES.DATASTORE.NODE_TYPE:
          return "DatastoreNode";
        case NODE_TYPES.HANGUP.NODE_TYPE:
          return "HangupNode";
        case NODE_TYPES.SET_VOICE.NODE_TYPE:
          return "SetVoiceNode";
        case NODE_TYPES.WEB_SERVICE.NODE_TYPE:
          return "WebServiceNode";
        case NODE_TYPES.KEYWORD_FINDER.NODE_TYPE:
          return "KeywordFinderNode";
        case NODE_TYPES.CHECK_WAITER.NODE_TYPE:
          return "CheckWaiterNode";
        case NODE_TYPES.ADD_TO_QUEUE.NODE_TYPE:
          return "AddToQueueNode";
        case NODE_TYPES.PAUSE.NODE_TYPE:
          return "PauseNode";
        case NODE_TYPES.COMMENT.NODE_TYPE:
          return "CommentNode";
        case NODE_TYPES.RECORD.NODE_TYPE:
          return "RecordNode";
        case NODE_TYPES.BLIND_TRANSFER.NODE_TYPE:
          return "BlindTransferNode";
        case NODE_TYPES.BRIDGE_TRANSFER.NODE_TYPE:
          return "BridgeTransferNode";
        case NODE_TYPES.SCREENPOP_TRANSFER.NODE_TYPE:
          return "ScreenpopTransferNode";
        case NODE_TYPES.FIVE9_TRANSFER.NODE_TYPE:
          return "Five9TransferNode";
        case NODE_TYPES.START.NODE_TYPE:
          return "StartNode";
        case NODE_TYPES.BIOMETRICS.NODE_TYPE:
          return "BiometricsNode";
        case NODE_TYPES.TRIGGER_CAMPAIGN.NODE_TYPE:
          return "TriggerCampaignNode";
        case NODE_TYPES.SLEEP.NODE_TYPE:
          return "SleepNode";
        case NODE_TYPES.HANGUP_HEADER.NODE_TYPE:
          return "HangupHeaderNode";
        case NODE_TYPES.PROMPT_RECORDER.NODE_TYPE:
          return "PromptRecorderNode";
        case NODE_TYPES.MENU.NODE_TYPE:
          return "MenuNode";
        case NODE_TYPES.LINK_TASK.NODE_TYPE:
          return "LinkTaskNode";
        case NODE_TYPES.TEXT_FORM.NODE_TYPE:
          return "TextFormNode";
        case NODE_TYPES.WATSON_SPEECH.NODE_TYPE:
          return "WatsonSpeechNode";
        case NODE_TYPES.VISUAL_FORM.NODE_TYPE:
          return "VisualFormNode";
        case NODE_TYPES.SUMMARIZATION.NODE_TYPE:
          return "SummarizationNode";
        case NODE_TYPES.KNOWLEDGE_DISTILLER.NODE_TYPE:
          return "KnowledgeDistillerNode";
        case NODE_TYPES.AI_CUSTOM_PROMPT.NODE_TYPE:
          return "AiCustomPromptNode";
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/styles/element-variables.scss";

::v-deep .node-details-form {
  > .el-form-item {
    margin-bottom: 26px;

    > .el-form-item__label {
      padding: 0 0 12px 0;
    }

    .el-select {
      width: 100%;
    }

    .el-checkbox {
      .el-checkbox__label {
        padding-left: 15px;
      }
    }

    .tool-tips {
      font-weight: 400;
      line-height: 17px;
      padding-bottom: 11px;
    }
  }

  .read-only {
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #696969;
    background: $--color-grey;
    padding-left: 15px;
    border-radius: 4px;
  }
}
</style>
