import _ from "lodash";
import store from "@/store";
import __ from "@/translation";

export const formatAccountMappingToSave = data => {
  const user = { ...data };

  if (user.account_mappings) {
    if (user.account_mappings.length) {
      if (user.account_mappings[0].scope === "sp") {
        user.account_mappings = [
          {
            mapping_scope: user.account_mappings[0].scope,
            sp_id: store.getters.selectedServiceProviderId
          }
        ];
      } else {
        user.account_mappings = _.map(
          user.account_mappings[0].accounts,
          account => ({
            mapping_scope: user.account_mappings[0].scope,
            ac_id: account.ac_id,
            sp_id: account.sp_id
          })
        );
      }
    }
    delete user.account_maps;
  }
  return user;
};

export const formatUserDetailsToShow = users => {
  _.map(users, user => {
    let user_status = user.user_status;
    user.user_status = user_status ? "Active" : "Inactive";
    user.user_status_label = user_status ? __("Active") : __("Inactive");
    let user_roles = user.roles.data;
    user.current_role = user_roles.length
      ? user_roles[user_roles.length - 1].name
      : "";
    user.system_controlled_twofa_label = user.system_controlled_twofa
      ? __("Mandatory")
      : user.twofa_enabled
      ? __("Enabled")
      : __("Disabled");
    user.role_name = [];
    if (!_.isEmpty(user_roles)) {
      if (user_roles.length === 1) {
        user.role_name[0] = user_roles[0].name;
      } else if (user_roles.length > 1) {
        user_roles.forEach(element => user.role_name.push(element.name));
        // can do of filter same role name if is globally unique
        // user.role_name = _.uniq(user.role_name);
      }
    }
    user.current_role = _.startCase(_.camelCase(user.current_role));
    let account_mappings = user.account_mappings;
    // mappings options when all user - all sp option is selected
    let ac_sp_mappings = user.ac_sp_map_role_permission;
    if (!_.isEmpty(ac_sp_mappings)) {
      // mappings when all user - all sp option is selected
      user.sp_name = [];
      user.scope = [];
      account_mappings.forEach(element => {
        if (element.scope === "ac") {
          element.accounts.forEach(account => {
            user.sp_name.push(account.sp_name);
            user.scope.push(account);
          });
        } else if (element.scope === "sp") {
          element.service_providers.forEach(item =>
            user.sp_name.push(item.sp_name)
          );
        }
      });
      if (account_mappings.some(mapping => mapping.scope === "sp")) {
        user.scope = "Service Provider";
      }
    } else if (!_.isEmpty(account_mappings)) {
      if (account_mappings[0].scope === "ac") {
        user.sp_name = [];
        //account_mappings[0].accounts[0].sp_name;
        user.sp_name[0] = account_mappings[0].accounts[0].sp_name;
      } else if (account_mappings[0].scope === "sp") {
        //user.sp_name = account_mappings[0].service_providers[0].sp_name;
        user.sp_name = [];
        account_mappings[0].service_providers.forEach(element =>
          user.sp_name.push(element.sp_name)
        );
      } else {
        user.sp_name = "";
      }
      let scope = account_mappings.length ? account_mappings[0].scope : "";
      switch (scope) {
        case "ac":
          user.scope = [];
          account_mappings[0].accounts.forEach(element =>
            user.scope.push(element)
          );
          //user.scope = account_mappings[0].accounts[0].ac_name;
          break;
        case "sp":
          user.scope = "Service Provider";
          break;
        default:
          user.scope = [
            {
              ac_id: store.getters.selectedAccountId,
              sp_id: store.getters.selectedServiceProviderId,
              ac_name: store.getters["app/selectedAccountName"],
              sp_name: store.getters["app/selectedServiceProviderName"]
            }
          ];
      }
    } else {
      // If user is system user fetch role scope from pre-populated field
      let account_maps = user.account_maps;
      if (!_.isEmpty(account_maps)) {
        user.scope =
          account_maps[0].scope === "sp"
            ? __("Service Provider")
            : [
                {
                  ac_id: store.getters.selectedAccountId,
                  sp_id: store.getters.selectedServiceProviderId,
                  ac_name: store.getters["app/selectedAccountName"],
                  sp_name: store.getters["app/selectedServiceProviderName"]
                }
              ];
      } else {
        user.scope = _.startCase(user.user_role_scope);
        user.sp_name = "";
      }
    }
    return user;
  });

  return users;
};

export const formatAccountMappingToPopulateAccountSwitchingOptions = user => {
  let serviceProviders = [];
  _.map(user.account_maps, item => {
    if (item.scope === "sp") {
      _.map(item.service_providers, serviceProvider => {
        const {
          sp_id,
          sp_name,
          sp_logopath,
          accounts,
          sp_setting
        } = serviceProvider;
        serviceProviders.push({
          sp_id: sp_id,
          sp_name: sp_name,
          logopath: sp_logopath,
          scope: "sp",
          accounts: _.map(accounts, account => {
            const {
              ac_id,
              ac_name,
              label,
              ac_logopath,
              ac_domain_map,
              ac_setting
            } = account;
            return {
              ac_id: ac_id,
              ac_name: ac_name,
              sp_id: sp_id,
              label: label,
              sp_name: sp_name,
              logopath: ac_logopath,
              scope: "sp",
              ac_domain_map,
              ac_setting
            };
          }),
          sp_setting: sp_setting
        });
      });
    } else {
      _.map(item.accounts, account => {
        let serviceProvider = _.find(serviceProviders, {
          sp_id: account.sp_id
        });
        if (!serviceProvider) {
          const {
            sp_id,
            sp_name,
            sp_logopath,
            ac_id,
            ac_name,
            ac_logopath,
            ac_domain_map,
            ac_setting
          } = account;
          serviceProviders.push({
            sp_id,
            sp_name,
            logopath: sp_logopath,
            scope: "ac",
            accounts: [
              {
                ac_id,
                ac_name,
                sp_id,
                sp_name,
                logopath: ac_logopath,
                scope: "ac",
                ac_domain_map,
                ac_setting
              }
            ]
          });
        } else {
          const {
            sp_id,
            sp_name,
            ac_id,
            ac_name,
            ac_logopath,
            ac_domain_map,
            ac_setting
          } = account;
          serviceProvider.accounts.push({
            ac_id,
            ac_name,
            sp_id,
            sp_name,
            logopath: ac_logopath,
            scope: "ac",
            ac_domain_map,
            ac_setting
          });
        }
      });
    }
  });

  return serviceProviders;
};

export const setServiceProviderIdAndAccountIdForUser = (
  userProvidedServiceProviderId,
  selectedServiceProviderId,
  accessibleServiceProvidersWithAccounts,
  userProvidedAccountId,
  selectedAccountId
) => {
  // try to convert to integers, if failed retain original value
  userProvidedServiceProviderId =
    +userProvidedServiceProviderId || userProvidedServiceProviderId;
  selectedServiceProviderId =
    +selectedServiceProviderId || selectedServiceProviderId;
  userProvidedAccountId = +userProvidedAccountId || userProvidedAccountId;
  selectedAccountId = +selectedAccountId || selectedAccountId;

  let serviceProviderId = "all";
  let accountId = "all";

  let serviceProvider;

  function setServiceProviderId() {
    if (
      !userProvidedServiceProviderId &&
      _.includes(
        _.map(accessibleServiceProvidersWithAccounts, "sp_id"),
        selectedServiceProviderId
      )
    ) {
      // if the service provider in the session storage
      // is among the service providers the user can still access
      serviceProviderId = selectedServiceProviderId;
    } else if (
      userProvidedServiceProviderId &&
      _.includes(
        _.map(accessibleServiceProvidersWithAccounts, "sp_id"),
        userProvidedServiceProviderId
      )
    ) {
      // if the provided sp_id as part of query param is
      // indeed among the service providers the user can still access
      serviceProviderId = userProvidedServiceProviderId;
    } else if (accessibleServiceProvidersWithAccounts.length === 1) {
      // if the user has access to only 1 service provider
      // set that as the service provider id
      serviceProviderId = accessibleServiceProvidersWithAccounts[0].sp_id;
    } else if (
      selectedServiceProviderId &&
      _.includes(
        _.map(accessibleServiceProvidersWithAccounts, "sp_id"),
        selectedServiceProviderId
      )
    ) {
      serviceProviderId = selectedServiceProviderId;
    }

    if (!userProvidedAccountId) {
      serviceProvider = _.find(
        accessibleServiceProvidersWithAccounts,
        serviceProvider => serviceProvider.sp_id === serviceProviderId
      );
    }
  }

  setServiceProviderId();

  function setAccountId() {
    if (
      !userProvidedServiceProviderId &&
      !userProvidedAccountId &&
      _.includes(_.map(serviceProvider.accounts, "ac_id"), selectedAccountId)
    ) {
      // if a user provided ac_id is not there in route query params
      // and the selected account id from session storage is accessible by user
      accountId = selectedAccountId;
    } else if (
      userProvidedAccountId &&
      _.includes(
        _.map(serviceProvider.accounts, "ac_id"),
        userProvidedAccountId
      )
    ) {
      // if a user provided ac_id is present in route query params
      // and that account id is accessible by the user
      if (!userProvidedServiceProviderId) {
        accountId = userProvidedAccountId;
      } else {
        if (
          userProvidedServiceProviderId !== serviceProvider.sp_id &&
          selectedAccountId &&
          _.includes(
            _.map(serviceProvider.accounts, "ac_id"),
            selectedAccountId
          )
        ) {
          accountId = selectedAccountId;
        } else {
          accountId = userProvidedAccountId;
        }
      }
    } else if (serviceProvider.accounts.length === 1) {
      // if the user only has access to one account
      accountId = serviceProvider.accounts[0].ac_id;
    } else if (
      selectedAccountId &&
      _.includes(_.map(serviceProvider.accounts, "ac_id"), selectedAccountId)
    ) {
      // if nothing works, set it to what read from storage, else default to 'all'
      accountId = selectedAccountId;
    }
  }

  if (
    !serviceProvider &&
    // !userProvidedServiceProviderId &&
    userProvidedAccountId
  ) {
    // if for some reason only ac_id is present in query param
    serviceProvider = _.find(
      accessibleServiceProvidersWithAccounts,
      serviceProvider =>
        _.includes(
          _.map(serviceProvider.accounts, "ac_id"),
          userProvidedAccountId
        )
    );
  }

  if (!serviceProvider && selectedAccountId) {
    // if for some reason we only have selected account id
    serviceProvider = _.find(
      accessibleServiceProvidersWithAccounts,
      serviceProvider =>
        _.includes(_.map(serviceProvider.accounts, "ac_id"), selectedAccountId)
    );
  }

  if (!serviceProvider && serviceProviderId) {
    // if serviceProvider object is still empty
    // try to find it using the set service provider id
    serviceProvider = _.find(
      accessibleServiceProvidersWithAccounts,
      serviceProvider => serviceProvider.sp_id === serviceProviderId
    );
  }

  // serviceProvider will be undefined if serviceProviderId is 'all' or an inaccessible sp_id
  if (serviceProvider) {
    // if a valid service provider is found, do stuff to set the account id
    serviceProviderId = serviceProvider.sp_id;
    setAccountId();
  }

  //console.log("final sp ac combo is", serviceProviderId, accountId);

  return { serviceProviderId, accountId };
};

export default {
  formatAccountMappingToSave,
  formatUserDetailsToShow,
  formatAccountMappingToPopulateAccountSwitchingOptions,
  setServiceProviderIdAndAccountIdForUser
};
