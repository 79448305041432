import {
  createCanvas,
  createNode,
  deleteCanvas,
  deleteNode,
  editCanvas,
  editNode,
  getCanvases,
  getNodes,
  pasteNode,
  unlinkNode
} from "@/api/nodes";
import _ from "lodash";
import {
  CONNECTOR_CONDITION_TAG_PREFIX,
  DROP_RULES,
  NODE_CATEGORIES,
  NODE_TYPES
} from "@/constants/nodes";
import {
  NODE_TYPES_AVAILABLE_FOR_ACCOUNT_WITH_LINKED_DOMAIN,
  NODE_TYPES_DEFAULT_HIDDEN_UNDER_LD
} from "@/store/modules/nodeTypes";
import { includesAny } from "@/utils/collection";
import {
  dropCriteria,
  findConnectorConditionTag,
  simpleHash
} from "@/utils/canvas";
import Router from "@/router";
import { EventBus } from "@/EventBus";
import __ from "@/translation";
import menus from "@/utils/nodeMenus/menus";
import { webServiceNodeDisplayName } from "@/views/build/callflow/components/node-type-forms/web-service/WebservicesConstants";

const hasChildren = (nodes, id) => {
  return _.some(nodes, node => String(node.pid) === String(id));
};

const getImmediateChildrenIds = (nodes, id) => {
  return _.map(
    _.filter(nodes, node => String(node.pid) === String(id)),
    node => String(node.id)
  );
};

const getCleanedUpCallflowUrl = url => {
  let parts = url.split("callflow/");
  let idParts = parts[1].split("/");
  return parts[0] + "callflow/" + idParts[0];
};

export const getRenderedSubGraph = (nodes, id) => {
  if (!id) {
    return [];
  }
  const descendants = [];
  if (hasChildren(nodes, id)) {
    const children = getImmediateChildrenIds(nodes, id);
    descendants.push(children);
    descendants.push(
      _.map(children, child => getRenderedSubGraph(nodes, child))
    );
  }
  return _.uniq(_.flatMapDeep(descendants));
};

const removeHighlightOnAllSelectedNodes = (
  currentlySelectedNodeId,
  classSelector = "focused"
) => {
  let className = ("." + classSelector).toString();
  let focusedElements = document.querySelectorAll(className);
  for (let i = 0; i < focusedElements.length; i++) {
    let nodeId = focusedElements[i].getAttribute("node-id");
    if (
      !(
        nodeId &&
        currentlySelectedNodeId &&
        nodeId.toString() === currentlySelectedNodeId.toString()
      )
    ) {
      focusedElements[i].classList.remove(classSelector);
    }
  }

  if (state.chart) {
    _.map(state.chart.nodes, node => {
      if (node.tags.includes(classSelector)) {
        if (
          !(
            node.id &&
            currentlySelectedNodeId &&
            node.id.toString() === currentlySelectedNodeId.toString()
          )
        ) {
          removeFocusTagFromNodesInChart(
            node.id,
            classSelector
          ).catch(() => {});
        }
      }
    });
  }
};

const removeFocusTagFromNodesInChart = async (nodeId, classSelector) => {
  let node = state.chart.getBGNode(nodeId);
  if (node) {
    node.tags = _.filter(node.tags, tag => tag !== classSelector);
  }
};

const addFocusTagToNodeInChart = async (chart, nodeId, classSelector) => {
  let node = chart.getBGNode(nodeId);
  if (node && !node.tags.includes(classSelector)) {
    node.tags.push(classSelector);
  }
};

const addHighlightOnSelectedNode = (chart, id, classSelector = "focused") => {
  if (id) {
    let nodeElement = chart.getNodeElement(id);
    nodeElement && !nodeElement.classList.value.includes(classSelector)
      ? nodeElement.classList.add(classSelector)
      : null;
    addFocusTagToNodeInChart(chart, id, classSelector).catch(() => {});
  }
};

export const state = {
  task_type: "",
  task: null,
  nodes: [], // to collect the nodes for the current task
  canvases: [], // to collect the canvases for the current task
  canvasUsages: [],
  goToNodeUsages: [],
  pastedNodesWithReference: [],
  nodeDeleteType: "",
  newCanvas: { canvas_name: "+", canvas_id: -99 },
  selectedCanvas: "", // to store name of the current canvas
  dummyNodes: [NODE_TYPES.CONDITION_LABEL.NODE_TYPE], //list of node_type of dummy nodes
  chart: null, // to store the balkangraph chart object
  clickedNode: {}, // to keep track of the currently clicked node or the node in context for dropping new node from palette
  clickedRenderedNodeId: null,
  prevClickedRenderedNodeId: null,
  droppedRenderedNodeId: null,
  nodeClickFlag: false,
  hideBackBtn: false,

  // data required to put a new node
  addNewNodeBelow: true,
  attemptConvertSameNodeToGoto: false,
  connectorCondition: "",

  isEdit: false, // is the action currently to perform is edit the clicked node, or add a new node below it
  newNodeOption: {
    get label() {
      return `== ${__("NEW NODE")} ==`;
    },
    value: -1
  }, // default option in the drop down for creating new node from modal
  isNodeSubmit: false, // changes to true when the user clicks update/insert
  validationsInProgress: false,
  isNodeCancelCompleted: true, // changes to false when the user clicks cancel button on node configure, and when any clean up is finished, we would set it back to true
  paletteSelection: null, // the node type of the icon dragged from the node palette
  dynamicNodesFor: [
    // list of rules for which dynamic nodes has to be created at front end

    {
      category: NODE_CATEGORIES.MULTIPLE_CHILD,
      rules: [
        {
          node_type: NODE_TYPES.CONVERSATION_SMS.NODE_TYPE,
          path: "messaging_node.data.keyword_conditions.data", // key and value will be looked up from this path
          key: "keyword", // the key will be made as the new dynamic child of node in context
          value: "node_id", //  the value is the original child of the node for which dynamic nodes has to be created
          outputNodeType: NODE_TYPES.CONDITION_LABEL.NODE_TYPE,
          outputNodeCategory: NODE_CATEGORIES.ONE_CHILD,
          relation: "condition"
        },
        {
          node_type: NODE_TYPES.DECISION.NODE_TYPE,
          path: "decision_node.data.keyword_conditions.data", // key and value will be looked up from this path
          key: "keyword", // the key will be made as the new dynamic child of node in context
          value: "node_id", //  the value is the original child of the node for which dynamic nodes has to be created
          outputNodeType: NODE_TYPES.CONDITION_LABEL.NODE_TYPE,
          outputNodeCategory: NODE_CATEGORIES.ONE_CHILD,
          relation: "condition"
        },
        {
          node_type: NODE_TYPES.CALENDAR.NODE_TYPE,
          path: "calendar_node.data.keyword_conditions.data", // key and value will be looked up from this path
          key: "keyword", // the key will be made as the new dynamic child of node in context
          value: "node_id", //  the value is the original child of the node for which dynamic nodes has to be created
          outputNodeType: NODE_TYPES.CONDITION_LABEL.NODE_TYPE,
          outputNodeCategory: NODE_CATEGORIES.ONE_CHILD,
          relation: "condition"
        },
        {
          node_type: NODE_TYPES.DAYTIME.NODE_TYPE,
          path: "daytime_node.data.keyword_conditions.data", // key and value will be looked up from this path
          key: "keyword", // the key will be made as the new dynamic child of node in context
          value: "node_id", //  the value is the original child of the node for which dynamic nodes has to be created
          outputNodeType: NODE_TYPES.CONDITION_LABEL.NODE_TYPE,
          outputNodeCategory: NODE_CATEGORIES.ONE_CHILD,
          relation: "condition"
        },
        {
          node_type: NODE_TYPES.DISTRIBUTE.NODE_TYPE,
          path: "distribute_node.data.keyword_conditions.data", // key and value will be looked up from this path
          key: "keyword_text", // the key will be made as the new dynamic child of node in context
          value: "node_id", //  the value is the original child of the node for which dynamic nodes has to be created
          outputNodeType: NODE_TYPES.CONDITION_LABEL.NODE_TYPE,
          outputNodeCategory: NODE_CATEGORIES.ONE_CHILD,
          relation: "condition"
        },
        {
          node_type: NODE_TYPES.KEYWORD_FINDER.NODE_TYPE,
          path: "keyword_finder_node.data.keyword_conditions.data", // key and value will be looked up from this path
          key: "keyword", // the key will be made as the new dynamic child of node in context
          value: "node_id", //  the value is the original child of the node for which dynamic nodes has to be created
          outputNodeType: NODE_TYPES.CONDITION_LABEL.NODE_TYPE,
          outputNodeCategory: NODE_CATEGORIES.ONE_CHILD,
          relation: "condition"
        },
        {
          node_type: NODE_TYPES.MENU.NODE_TYPE,
          path: "menu_node.data.grammar_conditions.data", // key and value will be looked up from this path
          key: "label", // the key will be made as the new dynamic child of node in context
          value: "node_id", //  the value is the original child of the node for which dynamic nodes has to be created
          outputNodeType: NODE_TYPES.CONDITION_LABEL.NODE_TYPE,
          outputNodeCategory: NODE_CATEGORIES.ONE_CHILD,
          relation: "condition"
        },
        {
          node_type: NODE_TYPES.VISUAL_FORM.NODE_TYPE,
          path: "visual_form_node.data.keyword_conditions.data", // key and value will be looked up from this path
          key: "keyword", // the key will be made as the new dynamic child of node in context
          value: "node_id", //  the value is the original child of the node for which dynamic nodes has to be created
          outputNodeType: NODE_TYPES.CONDITION_LABEL.NODE_TYPE,
          outputNodeCategory: NODE_CATEGORIES.ONE_CHILD,
          relation: "condition"
        }
      ]
    }
  ],
  loading: false, // to indicate when to show loader icon
  compareNodesOnBaseColumns: ["node_id", "node_name", "tooltip"], // these two properties is shared by all nodes, so its used for compare
  nodeUpdated: false, // whether the node configuration has changed, it is to enable update button after some edit has been made
  paletteMounted: false,
  renamingCanvasSuccessful: true, // flag to indicate whether the canvas renaming process was successful
  isCanvasCreated: true, // flag to indicate whether canvas creating api call was returned
  nodeIdToCopy: null, // to keep track of the node id to be pasted later
  copyType: null, // to keep track whether just the node has to be copied or entire branch
  copyTaskType: null, // to keep track of what task type the node/branch just copied from
  branchSelected: false,
  selectionsCleared: false,
  showExpressionBuilder: false,
  isMouseOverDropBox: false,
  nonRemovableParents: [
    {
      node_type: NODE_TYPES.CONDITION_LABEL.NODE_TYPE,
      node_name: "Otherwise"
    }
    // { node_type: "variable" } // for example. Can just use node_type also
  ],
  debounce: null,
  dragErrorDelayedFn: null,
  showNotification: false,
  notificationContent: {
    title: "",
    message: "",
    type: "success"
  },
  showDeleteConfirmBox: false,
  deleteConfiguration: { id: null, deleteType: null, canvasId: null },
  typeToTitleMap: {
    get cut_branch() {
      return __("Branch Cut");
    },
    get copy_branch() {
      return __("Branch Copied");
    },
    get cut_node() {
      return __("Node Cut");
    },
    get copy_node() {
      return __("Node Copied");
    }
  },
  zoom: undefined,
  gotoBack: { goto_source: "", goto_target: "" },
  readOnlyAccess: false,
  nodeTypesHiddenUnderLD: _.cloneDeep(NODE_TYPES_DEFAULT_HIDDEN_UNDER_LD),
  nodeSearchQuery: "",
  nodesForSearch: [],
  nodeNameCopy: null
};

export const getters = {
  nodesInEnabledCanvases(state, getters) {
    return _(state.canvases)
      .filter(canvas => !!canvas.is_enabled)
      .map(canvas =>
        _.map(getters.getSubGraph(canvas.first_node_id), "node_id")
      )
      .flatten()
      .uniq()
      .value();
  },

  /**
   * Getter to generate a list of canvases with each item including all node ids present in the canvas
   *
   * @param state
   * @param getters
   * @returns {*}
   */
  canvasToNodesMap(state, getters) {
    return _(state.canvases)
      .filter(canvas => !!canvas.is_enabled)
      .map(canvas => {
        return {
          canvas_id: canvas.canvas_id,
          canvas_name: canvas.canvas_name,
          nodes: _.map(getters.getSubGraph(canvas.first_node_id), "node_id")
        };
      })
      .value();
  },

  /**
   * creating a map of enabled canvases to be looked up based on canvas id
   *
   * @param state
   * @param getters
   * @returns {Dictionary<unknown>}
   */
  canvasToNodesMapKeyedByCanvasId(state, getters) {
    return _.keyBy(getters.canvasToNodesMap, canvas =>
      _.get(canvas, "canvas_id", 0)
    );
  },

  nodesKeyedById(state) {
    return _.keyBy(state.nodes, node => _.get(node, "node_id", 0));
  },

  /**
   * creating a list of goto nodes grouped by the goto_node_id
   * @param state
   * @returns {Dictionary<{}[]>}
   */
  goToNodeGroups(state) {
    return _.groupBy(
      state.nodes.filter(node => {
        return node.node_type.data.node_type === "goto";
      }),
      goToNode => {
        return goToNode.goto_node.data.goto_node_id;
      }
    );
  },

  renderedNodesKeyedById(state, getters) {
    return _.keyBy(getters.nodesToRender, node => _.get(node, "id", 0));
  },

  nodesKeyedByParentId(state) {
    return _.groupBy(state.nodes, node =>
      _.get(node, "connector_parent.data.0.pid", 0)
    );
  },

  isNodeTypeRestrictedForAccount(state, getters, rootState) {
    return nodeType => {
      const domainId = rootState.app.selectedAccountDomain?.domain_id || null;
      return (
        !domainId &&
        _.includes(
          NODE_TYPES_AVAILABLE_FOR_ACCOUNT_WITH_LINKED_DOMAIN,
          nodeType
        )
      );
    };
  },

  // to dynamically set tag based on category to change the menu
  nodesToRender(state, getters, rootState) {
    const currentCanvas = getters.currentCanvas;

    // adding new props for rendering nodes obtained from backend
    let nodes = _.isEmpty(currentCanvas)
      ? []
      : // only getting the list of nodes for the currently selected canvas
        _.map(getters.nodesInCurrentCanvas, node => {
          let nodeToRender = _.cloneDeep(node);
          nodeToRender.id = nodeToRender.node_id;

          // parent of a node is made as the first parent id in the list of parents
          // the node will be rendered as a same node below for rest of the parent ids
          if (!_.isEmpty(nodeToRender.connector_parent.data)) {
            nodeToRender.pid = nodeToRender.connector_parent.data[0].pid;
          }
          let { category, node_type } = nodeToRender.node_type.data;
          // render node details for special node types like web_service
          if (node_type === "web_service") {
            node_type = nodeToRender.web_service_node.data.action_name;
            nodeToRender.title = webServiceNodeDisplayName(node_type) + " Node";
            // For now using png icons can be replaced with svg later
            nodeToRender.img = "/icons/" + node_type + ".png";
          } else {
            nodeToRender.img = "/icons/" + node_type + ".svg";
            if (
              node_type === "data_store" &&
              nodeToRender.data_store_node.data.data_store.data.is_log === 1
            ) {
              nodeToRender.img = "/icons/" + node_type + "_system.svg";
            }
            if (node_type === NODE_TYPES.START.NODE_TYPE) {
              nodeToRender.img =
                "/icons/" + state.task_type + "_" + node_type + ".svg";
            }

            nodeToRender.title =
              _.find(
                getters.nodeTypeOptions(
                  rootState.app.selectedAccountId,
                  state.task_type
                ),
                { NODE_TYPE: node_type }
              ).LABEL || "";
          }
          nodeToRender.img_copy = "/icons/copy_url.svg";
          nodeToRender.tags = [category, node_type];
          return nodeToRender;
        });

    // logic to create dynamic nodes for rendering
    // these nodes are not persisted at backend

    // the below list is of all the parent node for which a new dynamic node
    // has to be created as child
    const nodesToMakeDynamicChildren = _.filter(nodes, node => {
      return _.some(
        state.dynamicNodesFor,
        dynamicNodeFor =>
          dynamicNodeFor.category === node.node_type.data.category &&
          _.map(dynamicNodeFor.rules, "node_type").includes(
            node.node_type.data.node_type
          )
      );
    });

    // when dynamic nodes are inserted in the chart
    // the original parent->child relationship has to be updated
    // such that the relationship becomes parent->dynamic node->child

    // as we only keep the parent information on every nodes, we need to
    // capture all the child nodes for which parent has to be updated to be
    // the dynamic node
    const updateParentForNodes = [];

    const newNodes = _.flatMapDeep(
      _.map(nodesToMakeDynamicChildren, node => {
        const { node_type, category } = node.node_type.data;
        // getting all relevant info to create a new node from the rules
        const {
          path,
          key,
          outputNodeType,
          outputNodeCategory,
          value,
          relation
        } = _.find(_.find(state.dynamicNodesFor, { category }).rules, {
          node_type
        });

        const children = _.get(node, path, []);
        let newNodes = [];
        if (!_.isEmpty(children)) {
          newNodes = _.map(children, child => {
            let newNode = {};

            newNode.id = newNode.node_id =
              node.id +
              "_" +
              relation +
              "_" +
              child[value] +
              "_" +
              simpleHash(child[key]);

            newNode.node_name = child[key];

            // setting the parent of the newly created node to the parent node
            // in the nodesToMakeDynamicChildren list
            newNode.pid = node.id;

            newNode.tags = [
              outputNodeType,
              outputNodeCategory,
              NODE_CATEGORIES.DUMMY,
              CONNECTOR_CONDITION_TAG_PREFIX + child[key]
            ];
            newNode.img = "/icons/" + outputNodeType + ".svg";
            newNode.title =
              _.find(
                getters.nodeTypeOptions(
                  rootState.app.selectedAccountId,
                  state.task_type
                ),
                { NODE_TYPE: outputNodeType }
              ).LABEL || "";

            // so that the newly created node conforms to node object standard
            newNode.connector_parent = {};
            newNode.connector_parent.data = [];
            newNode.connector_parent.data.push({ pid: node.id });

            newNode.node_type = {};
            newNode.node_type.data = {};
            newNode.node_type.data.category = outputNodeCategory;
            newNode.node_type.data.node_type = outputNodeType;

            // the original children of the parent node will have to
            // be updated, such that their new parent is newly created node.

            // Trying to find the updated parent map from the tracker
            // because a single node can have multiple parents (for same nodes)
            // so in such cases we need to push a new parent id to the list of parents
            // this list is declared outside the loop, so that it grows as we add to this list
            const parentMap = _.find(updateParentForNodes, {
              node_id: child[value]
            });

            // when the child node is seen for the first time,
            // parentMap would be empty, in such case make the first entry
            _.isEmpty(parentMap)
              ? updateParentForNodes.push({
                  node_id: child[value],
                  new_parent_ids: [newNode.id]
                })
              : // if parentMap already exists, add to existing list of parent ids
                parentMap.new_parent_ids.push(newNode.id);

            // return the newly created node
            return newNode;
          });
        }
        // return the list of new nodes for each node that requires to have multiple dynamic children.
        // this list of lists are flattened and stored as newNodes
        return newNodes;
      })
    );

    // now we use the parentMap we created to actually update the parent id of
    // child nodes. Even though a node can have multiple parents, while rendering
    // we will only show one that node as a child of only one parent, and all other
    // instances of that node as a child will be rendered as a same node

    // so we pick the first parent, and make that as the parent of that node.
    // but we keep track of all the parents in connector_parent property of the node
    nodes = _.map(nodes, node => {
      node = !_.map(updateParentForNodes, "node_id").includes(node.node_id)
        ? node
        : {
            ...node,
            // pid property of the node decides how parent-child are connected
            // parent of a node is made as the first parent id
            pid: _.find(updateParentForNodes, {
              node_id: node.node_id
            }).new_parent_ids[0]
          };

      const parentRemap = _.find(updateParentForNodes, {
        node_id: node.node_id
      });

      // changing the connector_parent property received from backend
      // to list of newly created parents
      if (!_.isEmpty(parentRemap)) {
        node.connector_parent = {};
        node.connector_parent.data = _.map(
          parentRemap.new_parent_ids,
          new_parent_id => ({ pid: new_parent_id })
        );
      }
      return node;
    });

    nodes = _.concat(nodes, newNodes);

    // logic to create same nodes, for nodes having multiple parents
    const sameNodes = _.filter(
      _.flatMapDeep(
        _.map(nodes, node => {
          // if it has parents and if there are multiple parents
          if (
            !_.isEmpty(node.connector_parent.data) &&
            !_.isEmpty(_.tail(_.map(node.connector_parent.data, "pid")))
          ) {
            // rendering same node as child from second parent, hence getting tail
            const parents = _.tail(_.map(node.connector_parent.data, "pid"));
            let count = 0;
            return _.map(parents, parent => {
              count += 1;
              return {
                ...node,
                tags: _.concat(node.tags, NODE_TYPES.SAME_NODE.NODE_TYPE),
                pid: parent,
                id: node.id + "_" + "same_" + count,
                img: "/icons/same_node.svg"
              };
            });
          }
        })
      ),
      item => !_.isEmpty(item)
    );

    // add the same nodes to node collection
    nodes = _.concat(nodes, sameNodes);

    // logic to force rendering some nodes meeting some criteria last
    const renderLastConditions = [
      {
        node_type: NODE_TYPES.CONDITION_LABEL.NODE_TYPE,
        node_name: __("Otherwise")
      }
      // { node_type: "variable" } // for example. Can just use node_type also
    ];

    // filter and get nodes that needs to rendered last according to the specified rules
    const nodesToRenderLast = _.filter(nodes, node => {
      return _.some(renderLastConditions, condition => {
        if (_.isEmpty(condition.node_name)) {
          return node.node_type.data.node_type === condition.node_type;
        } else {
          return (
            node.node_type.data.node_type === condition.node_type &&
            node.node_name === condition.node_name
          );
        }
      });
    });

    const nodeIdsToRenderLast = _.map(nodesToRenderLast, node => node.node_id);

    // filter and remove nodes which have to rendered last from original nodes collection
    nodes = _.filter(
      nodes,
      node => !nodeIdsToRenderLast.includes(node.node_id)
    );

    // concatenating those nodes to be rendered last as the last elements in the nodes list
    nodes = _.concat(nodes, nodesToRenderLast);

    // add display name

    nodes = _.map(nodes, node => {
      if (node.node_type.data.node_type === "goto") {
        node.display_name = __("Go to: ") + node.goto_node.data.goto_node_name;
      } else if (
        node.node_type.data.node_type === NODE_TYPES.LINK_TASK.NODE_TYPE
      ) {
        node.display_name = node.link_task_node.data.task_name
          ? __("Task: ") + node.link_task_node.data.task_name
          : node.node_name;
      } else {
        node.display_name = node.node_name;
      }
      return node;
    });

    // add new tags based on each node's current conditions
    // add leaf tag to leaf nodes
    nodes = _.map(nodes, node => {
      if (
        !getters.hasChild(node.node_id) &&
        (!node.tags.includes(NODE_TYPES.CONDITION_LABEL.NODE_TYPE) ||
          !node.tags.includes(NODE_CATEGORIES.NO_CHILD))
      ) {
        !node.tags.includes(DROP_RULES.LEAF_NODE)
          ? node.tags.push(DROP_RULES.LEAF_NODE)
          : null;
      }
      return node;
    });

    // adding new tags to dynamically set node menus. Node menus are configured in @/utils/nodeMenus/menus.js
    nodes = _.map(nodes, node => {
      // NOTE: dont change the order of appending the below tags
      if (
        !includesAny(node.tags, [
          NODE_CATEGORIES.DUMMY,
          NODE_TYPES.SAME_NODE.NODE_TYPE
        ])
      ) {
        let tag = node.node_type.data.category;

        tag += !getters.hasParent(node.node_id) ? "_root" : "";
        tag += !getters.hasChild(node.node_id) ? "_leaf" : "";

        if (node.node_type.data.node_type === "link_task") {
          tag += "_link_task";
          if (getters.isTaskReadOnly) {
            tag += "_protected";
          }
        }

        if (
          state.copyType &&
          (node.tags.includes(NODE_CATEGORIES.ONE_CHILD) ||
            node.tags.includes(NODE_CATEGORIES.SYSTEM))
        ) {
          tag += ["copy_node", "cut_node"].includes(state.copyType)
            ? "_paste_node"
            : "_paste_branch";
        }

        if (!node.tags.includes(tag)) {
          node.tags.push(tag);
        }

        if (getters.isTaskReadOnly && !node.tags.includes("protected")) {
          node.tags.push("protected");
        }

        let tagGoto = "";
        if (["goto"].includes(node.node_type.data.node_type)) {
          tagGoto = "no_child";
          tagGoto += !getters.hasParent(node.node_id) ? "_root_leaf" : "";
          tagGoto += "_" + node.node_type.data.node_type;
          if (getters.isTaskReadOnly) {
            tagGoto += "_protected";
            node.tags = _.filter(node.tags, tag => tag !== "protected");
          }
          if (!node.tags.includes(tagGoto)) {
            node.tags.push(tagGoto);
          }
        }

        if (node.node_id === state.gotoBack.goto_target) {
          let tagGoBack = tagGoto === "" ? tag : tagGoto;
          tagGoBack += "_goback";
          if (getters.isTaskReadOnly) {
            tagGoBack += "_protected";
            node.tags = _.filter(node.tags, tag => tag !== "protected");
          }
          if (!node.tags.includes(tagGoBack)) {
            node.tags.push(tagGoBack);
          }
        }
      }
      return node;
    });

    // identifying nodes need to be cut
    if (["cut_node", "cut_branch"].includes(state.copyType)) {
      let node = {};
      node = _.find(nodes, { id: state.nodeIdToCopy });
      !_.isEmpty(node) ? node.tags.push("cut") : null;

      if (state.copyType === "cut_branch" && !_.isEmpty(node)) {
        _.map(getRenderedSubGraph(nodes, state.nodeIdToCopy), id => {
          const node = _.find(nodes, node => String(node.id) === id);
          return !_.isEmpty(node) && !node.tags.includes("cut")
            ? node.tags.push("cut")
            : null;
        });
      }
    }

    // identifying nodes need to be copied
    if (["copy_node", "copy_branch"].includes(state.copyType)) {
      let node = {};
      node = _.find(nodes, { id: state.nodeIdToCopy });
      !_.isEmpty(node) ? node.tags.push("copy") : null;

      if (state.copyType === "copy_branch" && !_.isEmpty(node)) {
        _.map(getRenderedSubGraph(nodes, state.nodeIdToCopy), id => {
          const node = _.find(nodes, node => String(node.id) === id);
          return !_.isEmpty(node) && !node.tags.includes("copy")
            ? node.tags.push("copy")
            : null;
        });
      }
    }

    // identifying nodes to be focused on
    let node = _.find(nodes, { id: state.clickedRenderedNodeId });

    !_.isEmpty(node) && !node.tags.includes("focused")
      ? node.tags.push("focused")
      : null;

    if (state.branchSelected) {
      _.map(getRenderedSubGraph(nodes, state.clickedRenderedNodeId), id => {
        const node = _.find(nodes, node => String(node.id) === id);
        return !_.isEmpty(node) && !node.tags.includes("focused")
          ? node.tags.push("focused")
          : null;
      });
    }

    // identifying nodes that need to be highlighted as part of search
    if (state.nodeSearchQuery) {
      let nodeIds = _.map(state.nodesForSearch, "node_id");
      _.map(nodes, node => {
        return !_.isEmpty(node) &&
          nodeIds.includes(node.node_id) &&
          !node.tags.includes("found")
          ? node.tags.push("found")
          : null;
      });
    }

    return _.uniqBy(nodes, "id");
  },

  currentTask(state) {
    return _.cloneDeep(state.task);
  },

  isTaskReadOnly(state) {
    return (state.task && !!state.task.protected) || state.readOnlyAccess;
  },

  isNodeInRenderedSubGraph(state, getters) {
    return (node, pid = state.clickedRenderedNodeId) =>
      getRenderedSubGraph(getters.nodesToRender, pid).includes(String(node.id));
  },

  /**
   * Getter to get the node configuration from the rendered graph
   */
  renderedNode(state, getters) {
    return id => {
      return _.get(getters.renderedNodesKeyedById, isNaN(id) ? id : +id);
    };
  },

  /**
   * Getter to fetch the main canvas
   */
  mainCanvas(state) {
    return _.find(state.canvases, canvas => canvas.is_main_canvas);
  },

  /**
   * Getter to return the canvas name of the provided node id
   * @param state
   * @param getters
   * @returns {function(*=): *|string}
   */
  canvasOfNode(state, getters) {
    return id => {
      let canvas = _.find(getters.canvasToNodesMap, canvas =>
        _.includes(canvas.nodes, parseInt(id))
      );
      return !_.isEmpty(canvas) ? canvas.canvas_name : "";
    };
  },

  /**
   * Getter to check whether a specified node id has child, checked against actual nodes collection
   */
  hasChild(state, getters) {
    return nodeId => {
      // return _.some(state.nodes, node => {
      //   if (!_.isEmpty(node.connector_parent.data)) {
      //     return _.map(node.connector_parent.data, "pid").includes(nodeId);
      //   } else {
      //     return false;
      //   }
      // });

      return !!_.get(getters.nodesKeyedByParentId, nodeId, []).length;
    };
  },

  /**
   * Getter to get immediate children for a specified node id
   */
  getChildren(state, getters) {
    return nodeId => {
      return _.uniq(
        // remove the duplicates
        _.flatMapDeep(
          // flat maps the array of arrays to form a single array
          _.filter(
            // removes the undefined nodes that did not pass the if condition below
            // _.map(state.nodes, node => {
            //   // check if the node has a parent and if it is a child of passed node id
            //   if (
            //     !_.isEmpty(node.connector_parent.data) &&
            //     _.map(node.connector_parent.data, "pid").includes(nodeId)
            //   ) {
            //     // if the child is not a dummy node, it is the actual child
            //     // if the child is one of the dummy nodes, recursively find the child of dummy node
            //     return !state.dummyNodes.includes(node.node_type.data.node_type)
            //       ? node
            //       : getters.getChildren(node.node_id);
            //   }
            // }),
            _.get(getters.nodesKeyedByParentId, nodeId, []),
            item => !_.isEmpty(item)
          )
        )
      );
    };
  },

  /**
   * find the event handler canvas list
   */
  getEventHandlerCanvasList(state) {
    const eventHandlerCanvasList =
      _.filter(state.canvases, function(canvas) {
        return !canvas.is_event_handler;
      }) || [];
    return eventHandlerCanvasList;
  },

  /**
   * find the no input or no match event handler canvas list
   */
  getNoInputNoMatchHandlerCanvasList(state) {
    return _.filter(state.canvases, canvas => {
      return canvas.is_ni_nm_handler;
    });
  },

  /**
   * find the canvas given the name
   */
  getCanvasByName(state) {
    return canvas_name => {
      const canvas = _.find(state.canvases, { canvas_name }) || {};
      return canvas;
    };
  },

  /**
   * check whether the canvas exists given its name
   */
  canvasExists(state, getters) {
    return canvasName => {
      return !_.isEmpty(getters.getCanvasByName(canvasName));
    };
  },

  /**
   * returns the current canvas
   */
  currentCanvas(state, getters) {
    return getters.getCanvasByName(state.selectedCanvas);
  },

  nodesInCurrentCanvas(state, getters) {
    let canvas = getters.currentCanvas;
    canvas = getters.canvasToNodesMapKeyedByCanvasId[canvas.canvas_id];
    if (!canvas) {
      return [];
    }
    return _.map(canvas.nodes, nodeId =>
      _.get(getters.nodesKeyedById, +nodeId)
    );
  },

  indexOfCurrentCanvas(state) {
    return _.findIndex(state.canvases, { canvas_name: state.selectedCanvas });
  },

  /**
   * get the list of nodes for which there are no parents, except for the start node
   * filter out all nodes that are the first node id of event handler canvas.
   */
  getOrphans(state, getters) {
    const noInputNoMatchCanvases = _.filter(state.canvases, canvas => {
      return canvas.is_ni_nm_handler;
    });
    const noInputNoMatchHandlerNodes = _.map(noInputNoMatchCanvases, canvas => {
      return canvas.first_node_id;
    });
    const orphanNodesWithNoInputNoMatchNodesIncluded = _.filter(
      getters.getValidNodes,
      node => {
        return (
          _.isEmpty(node.connector_parent.data) &&
          ![
            NODE_TYPES.START.NODE_TYPE,
            NODE_TYPES.DISCONNECT.NODE_TYPE,
            NODE_TYPES.BEEP_DETECTION.NODE_TYPE,
            NODE_TYPES.MACHINE_DETECTION.NODE_TYPE
          ].includes(node.node_type.data.node_type)
        );
      }
    );
    const orphanNodes = orphanNodesWithNoInputNoMatchNodesIncluded.filter(
      node => !noInputNoMatchHandlerNodes.includes(node.node_id)
    );
    return orphanNodes;

    // let nodesWithoutParent = _.get(getters.nodesKeyedByParentId, 0);
  },

  /**
   * returns the list of nodes which are not dynamically created
   */
  getValidNodes(state, getters) {
    if (!_.isEmpty(state.nodes)) {
      const validNodeIds = _.filter(_.keys(getters.nodesKeyedById), nodeId =>
        getters.nodesInEnabledCanvases.includes(+nodeId)
      );
      return _.map(validNodeIds, validNodeId =>
        _.get(getters.nodesKeyedById, +validNodeId)
      );
    }
    return [];
  },

  /**
   * get the list of nodes that make up all the descendants from a specified node id
   */
  getDescendants(state, getters) {
    return node_id => {
      const descendants = [];
      if (getters.hasChild(node_id)) {
        const children = getters.getChildren(node_id);
        descendants.push(children);
        descendants.push(
          _.map(children, child => getters.getDescendants(child.node_id))
        );
      }
      return _.uniq(
        _.filter(_.flatMapDeep(descendants), item => !_.isEmpty(item))
      );
    };
  },

  /**
   * get the original node object from the nodes collection returned from backend
   */
  getOriginalNode(state, getters) {
    return node_id => {
      // return _.find(state.nodes, { node_id: +node_id });
      return _.get(getters.nodesKeyedById, +node_id, undefined);
    };
  },

  getOriginalNodeFromRenderedNodeId(state, getters) {
    return id => {
      const { node_id } = getters.renderedNode(id);
      return getters.getOriginalNode(node_id);
    };
  },

  /**
   * get the node from specified node id + all its descendants, to
   * render them in the current canvas
   */
  getSubGraph(state, getters) {
    return node_id => {
      return _.concat(
        getters.getOriginalNode(node_id),
        getters.getDescendants(node_id)
      );
    };
  },

  /**
   * check whether the specified node id has a parent node
   */
  hasParent(state, getters) {
    return node_id => {
      return !_.isEmpty(getters.getOriginalNode(node_id).connector_parent.data);
    };
  },

  renderedNodeHasParent(state, getters) {
    return id => {
      return !_.isEmpty(getters.renderedNode(id).connector_parent.data);
    };
  },

  /**
   * get the parent node of a specified node id, if it exists
   */
  getParent(state, getters) {
    return node_id => {
      if (getters.hasParent(node_id)) {
        const node = getters.getOriginalNode(node_id);
        return getters.getOriginalNode(node.connector_parent.data[0].pid);
      } else {
        return null;
      }
    };
  },

  // getParentTreeIds(state, getters) {
  //   return id => {
  //     const parentNodeIds = [];
  //     const renderedNode = getters.renderedNode(id);
  //     if (getters.renderedNodeHasParent(id)) {
  //       const parentIds = _.map(renderedNode.connector_parent.data, "pid");
  //       parentNodeIds.push(parentIds);
  //       parentNodeIds.push(
  //         _.map(parentIds, parentId => getters.getParentTreeIds(parentId))
  //       );
  //     }
  //     return _.uniq(_.compact(_.flatMapDeep(parentNodeIds)));
  //   };
  // },
  //
  // getParentTree(state, getters) {
  //   return id => {
  //     let parentNodeIds = getters.getParentTreeIds(id);
  //     return _.filter(getters.nodesToRender, node =>
  //       parentNodeIds.includes(node.id)
  //     );
  //   };
  // },

  /**
   * get the parent nodes (it may be even dynamic nodes) of a particular id of a rendered node
   */
  getParentsFromRenderedGraph(state, getters) {
    return id => {
      const node = getters.renderedNode(id);
      if (getters.hasParent(node.node_id)) {
        const parentNodeIds = _.map(node.connector_parent.data, "pid");
        // return _.filter(getters.nodesToRender, node =>
        //   parentNodeIds.includes(node.id)
        // );
        return _.filter(
          _.map(parentNodeIds, parentNodeId =>
            _.get(getters.renderedNodesKeyedById, parentNodeId)
          )
        );
      } else {
        return [];
      }
    };
  },

  /**
   * Looking at immediate parents from rendered graph
   */
  parentsIncludeNonRemovableNodes(state, getters) {
    return id => {
      const parentNodes = getters.getParentsFromRenderedGraph(id);
      return (
        !_.isEmpty(parentNodes) &&
        _.some(parentNodes, parentNode =>
          _.some(state.nonRemovableParents, condition => {
            if (_.isEmpty(condition.node_name)) {
              return (
                parentNode.node_type.data.node_type === condition.node_type
              );
            } else {
              return (
                parentNode.node_type.data.node_type === condition.node_type &&
                parentNode.node_name === condition.node_name
              );
            }
          })
        )
      );
    };
  },

  /**
   * get the root parent node of a particular node_id,
   * it will be found using the path via first parent of the node in its upward hierarchy
   */
  getRootNode(state, getters) {
    return node_id => {
      if (getters.hasParent(node_id)) {
        return getters.getRootNode(getters.getParent(node_id).node_id);
      } else {
        return getters.getOriginalNode(node_id);
      }
    };
  },

  nodeTypeOptions(state, getters, rootState) {
    return (ac_id, task_type, shouldFilterNodeTypesHiddenUnderLD = false) => {
      let accountToNodeTypeMap = _.find(
        rootState.nodeTypes.accountNodeTypeMap,
        { ac_id }
      );

      if (
        _.isEmpty(accountToNodeTypeMap) ||
        _.isEmpty(accountToNodeTypeMap[task_type])
      ) {
        return {};
      }

      if (shouldFilterNodeTypesHiddenUnderLD === false) {
        return accountToNodeTypeMap[task_type];
      }

      return _.pickBy(_.cloneDeep(accountToNodeTypeMap[task_type]), function(
        value
      ) {
        return !state.nodeTypesHiddenUnderLD.includes(value.NODE_TYPE);
      });
    };
  },

  nodeNameCopy(state) {
    return state.nodeNameCopy;
  }
};

export const mutations = {
  SET_READ_ONLY_ACCESS(state, bool) {
    state.readOnlyAccess = !!bool;
  },

  SET_HIDE_BACK_BTN(state, bool) {
    state.hideBackBtn = bool;
  },

  SET_NODE_SEARCH_QUERY(state, query) {
    state.nodeSearchQuery = query;
  },

  SET_NODES_AFTER_SEARCH(state, nodes) {
    state.nodesForSearch = nodes;
  },

  SET_TASK(state, task) {
    state.task = task;
  },

  SET_CANVAS_USAGES(state, usages = []) {
    state.canvasUsages = usages;
  },

  SET_GOTO_NODE_USAGES(state, usages = []) {
    state.goToNodeUsages = usages;
  },

  SET_PASTED_NODES_WITH_REFERENCE(state, nodes = []) {
    state.pastedNodesWithReference = nodes;
  },

  SET_NODE_DELETE_TYPE(state, value) {
    state.nodeDeleteType = value;
  },

  SET_TASK_TYPE(state, task_type) {
    state.task_type = task_type;
  },

  SET_TO_CANVAS_ID(state, canvasId) {
    state.toCanvasId = canvasId;
  },

  SET_FROM_CANVAS_ID(state, canvasId) {
    state.fromCanvasId = canvasId;
  },

  SET_CHART(state, chart) {
    state.chart = chart;
  },

  SET_ZOOM_MODE(state, zoom) {
    state.zoom = zoom;
  },

  SET_PALETTE_SELECTION(state, value) {
    state.paletteSelection = value;
  },

  SET_PALETTE_MOUNTED_FLAG(state, flag) {
    state.paletteMounted = flag;
  },

  SET_NODES(state, nodes) {
    state.nodes = nodes;
  },

  SET_NODES_IN_CHART(state, nodes) {
    if (
      state.chart !== null &&
      state.chart !== undefined &&
      !_.isEmpty(state.chart) &&
      !_.isEmpty(nodes)
    ) {
      state.chart.load(nodes);
    }
  },

  ADD_CANVAS(state, canvas) {
    state.canvases.push(canvas);
  },

  SET_NEW_CANVAS(state, canvas) {
    state.newCanvas = canvas;
  },

  RENAME_CANVAS(state, { canvas_name, first_node_id }) {
    const canvas = _.find(state.canvases, { first_node_id });
    canvas.canvas_name = canvas_name;
  },

  SET_CANVASES(state, canvases) {
    state.canvases = canvases;
  },

  SET_SELECTED_CANVAS(state, canvas) {
    if (canvas) {
      state.selectedCanvas = canvas;
    }
  },

  SET_CANVAS_RENAME_STATUS(state, status) {
    state.renamingCanvasSuccessful = status;
  },

  CHANGE_CANVAS_CREATE_STATUS(state, status) {
    state.isCanvasCreated = status;
  },

  CHANGE_CANVAS_NODE_MODE(state, isEdit) {
    state.isEdit = isEdit;
  },

  SET_CLICKED_NODE(state, node) {
    state.clickedNode = node;
  },

  SET_CLICKED_RENDERED_NODE_ID(state, node_id) {
    state.clickedRenderedNodeId = node_id;
  },

  SET_PREV_CLICKED_RENDERED_NODE_ID(state) {
    state.prevClickedRenderedNodeId = state.clickedRenderedNodeId;
  },

  SET_DROPPED_RENDERED_NODE_ID(state, id) {
    state.droppedRenderedNodeId = id;
  },

  SET_MOUSE_OVER_DROP_BOX_STATUS(state, flag) {
    state.isMouseOverDropBox = flag;
  },

  TOGGLE_NODE_CLICK_FLAG(state) {
    state.nodeClickFlag = !state.nodeClickFlag;
  },

  SET_NODE_ID_TO_COPY(state, nodeId) {
    state.nodeIdToCopy = nodeId;
  },

  SET_COPY_TYPE(state, copyType) {
    state.copyType = copyType;
  },

  SET_COPY_TASK_TYPE(state, copyTaskType) {
    state.copyTaskType = copyTaskType;
  },

  SET_BRANCH_SELECTED_FLAG(state, flag) {
    state.branchSelected = flag;
  },

  SET_SELECTIONS_CLEARED_FLAG(state, flag) {
    state.selectionsCleared = flag;
  },

  RESET_COPY_STATE(state) {
    state.nodeIdToCopy = null;
    state.copyType = null;
    state.copyTaskType = null;
    state.fromCanvasId = null;
    state.toCanvasId = null;
  },

  SET_NEW_NODE_POSITION(state, position) {
    state.addNewNodeBelow = position;
  },

  SET_ATTEMPT_CONVERT_SAME_NODE_TO_GOTO(state, attempt) {
    state.attemptConvertSameNodeToGoto = attempt;
  },

  SET_NEW_NODE_TARGET_CONNECTOR_CONDITION(state, connectorCondition) {
    state.connectorCondition = connectorCondition;
  },

  RESET_ADD_NEW_NODE_STATE(state) {
    state.attemptConvertSameNodeToGoto = false;
    state.connectorCondition = "";
    state.addNewNodeBelow = true;
  },

  REMOVE_NODES(state) {
    state.nodes = [];
  },

  REMOVE_CANVASES(state) {
    state.canvases = [];
  },

  CENTER_CANVAS(state, node_id) {
    state.chart.center(node_id, { vertical: true, horizontal: true });
  },

  SET_NODE_SUBMIT(state, isSubmit) {
    state.isNodeSubmit = isSubmit;
  },

  SET_VALIDATIONS_IN_PROGRESS(state, validationsInProgress) {
    state.validationsInProgress = validationsInProgress;
  },

  SET_NODE_CANCEL_COMPLETED_STATE(state, isCancelCompleted) {
    state.isNodeCancelCompleted = isCancelCompleted;
  },

  CHANGE_LOADING(state, isLoading) {
    state.loading = isLoading;
  },

  TOGGLE_NODE_UPDATE_STATUS(state, isNodeChanged) {
    state.nodeUpdated = isNodeChanged;
  },

  SET_EXPRESSION_BUILDER_SHOW(state, flag) {
    state.showExpressionBuilder = flag;
  },

  SET_DEBOUNCE(state, value) {
    state.debounce = value;
  },

  SET_DRAG_ERROR_DELAYED_RESPONSE(state, value) {
    state.dragErrorDelayedFn = value;
  },

  SET_SHOW_NOTIFICATION_STATUS(state, flag) {
    state.showNotification = flag;
  },

  SET_NOTIFICATION_CONTENT(state, content) {
    let className =
      (content.customClass || "") + " call-flow-editor-notification";
    content.customClass = className;
    content = { position: "bottom-left", ...content };

    if (content.position === "bottom-left") {
      className = className + " call-flow-editor-notification-left";
      content = { ...content, customClass: className };
    }
    state.notificationContent = content;
  },

  SET_SHOW_DELETE_CONFIRMATION_BOX(state, flag) {
    state.showDeleteConfirmBox = flag;
  },

  SET_DELETE_CONFIGURATION(state, deleteConfig) {
    state.deleteConfiguration = deleteConfig;
  },

  SET_GOTO_BACK(state, { source, target }) {
    state.gotoBack.goto_source = source;
    state.gotoBack.goto_target = target;
  },

  SET_TASK_MODIFIED_FLAG(state, flag) {
    if (state.task) {
      state.task.version_in_use_modified = flag;
    }
  },

  SET_TASK_VERSION_IN_USE(state, id) {
    if (state.task) {
      state.task.task_version_in_use = id;
    }
  },

  SET_NODE_NAME_COPY(state, nodeName) {
    state.nodeNameCopy = nodeName;
  },

  REMOVE_NODE_TYPE_HIDDEN_UNDER_LD(state, nodeType) {
    let index = state.nodeTypesHiddenUnderLD.indexOf(nodeType);

    if (index === -1) {
      return;
    }

    state.nodeTypesHiddenUnderLD.splice(index, 1);
  },

  RESET_NODE_TYPES_HIDDEN_UNDER_LD(state) {
    state.nodeTypesHiddenUnderLD = _.cloneDeep(
      NODE_TYPES_DEFAULT_HIDDEN_UNDER_LD
    );
  }
};

export const actions = {
  setReadOnlyAccess({ commit }, bool) {
    commit("SET_READ_ONLY_ACCESS", bool);
  },

  setHideBackBtn({ commit }, bool) {
    commit("SET_HIDE_BACK_BTN", bool);
  },
  removeNodeTypeHiddenUnderLd({ commit }, node_type) {
    commit("REMOVE_NODE_TYPE_HIDDEN_UNDER_LD", node_type);
  },

  resetHiddenNodesUnderLd({ commit }) {
    commit("RESET_NODE_TYPES_HIDDEN_UNDER_LD");
  },

  setCanvasUsages({ commit }, usages) {
    commit("SET_CANVAS_USAGES", usages);
  },

  setGoToNodeUsages({ commit }, usages) {
    commit("SET_GOTO_NODE_USAGES", usages);
  },

  setPastedNodesWithReference({ commit }, nodes) {
    commit("SET_PASTED_NODES_WITH_REFERENCE", nodes);
  },

  setTask({ commit }, task) {
    commit("SET_TASK", _.cloneDeep(task));
  },

  setTaskType({ commit }, task_type) {
    commit("SET_TASK_TYPE", task_type);
  },

  setZoom({ commit }, zoom) {
    commit("SET_ZOOM_MODE", zoom);
  },

  setSearchResults({ commit }, { query, nodes }) {
    commit("SET_NODE_SEARCH_QUERY", query);
    commit("SET_NODES_AFTER_SEARCH", nodes);
  },

  dropBoxesWhenDragged(
    { getters },
    { selector, parentNodeIds, id, dragNode, subGraph }
  ) {
    let dropBoxes = document.querySelectorAll(selector);
    _.map(dropBoxes, dropBox => {
      let parent = dropBox;
      while (!parent.hasAttribute("node-id")) {
        parent = parent.parentNode;
      }
      let pid = parent.getAttribute("node-id");
      let actualPid = pid.split("_")[0];

      if (
        !includesAny(parentNodeIds, [pid, actualPid]) &&
        pid !== id &&
        actualPid !== id &&
        dragNode.node_id !== +actualPid &&
        (_.isEmpty(subGraph) ||
          (!_.isEmpty(subGraph) && !includesAny([pid, actualPid], subGraph))) &&
        !getters.renderedNode(pid).tags.includes(NODE_TYPES.SAME_NODE.NODE_TYPE)
      ) {
        dropBox.style.display = "block";
        dropBox.style["z-index"] = 99999;
      }
    });
  },
  respondToClickOrDragOrDrop({ dispatch, state }, { node, action }) {
    if (
      !_.isEmpty(node) &&
      (node.id !== state.clickedRenderedNodeId || state.branchSelected)
    ) {
      let isDummyNode = node.tags.includes(NODE_CATEGORIES.DUMMY);

      if (!isDummyNode) {
        if (state.branchSelected && action === "click") {
          dispatch("setClickedRenderedNodeId", null);
          dispatch("changeBranchSelectedFlag", false);
        }

        if (action !== "drop") {
          dispatch("setClickedRenderedNodeId", node.id);
        }

        removeHighlightOnAllSelectedNodes(state.clickedRenderedNodeId);
        addHighlightOnSelectedNode(state.chart, state.clickedRenderedNodeId);
      }
    }
  },

  removeHighlightOnAllSelectedNodesAction(
    context,
    { classSelector, nodeId = null }
  ) {
    removeHighlightOnAllSelectedNodes(nodeId, classSelector);
  },

  async addHighlightOnSelectedNodeAction({ state }, { nodeId, classSelector }) {
    await addHighlightOnSelectedNode(state.chart, nodeId, classSelector);
  },

  removeSelections({ commit, state }) {
    removeHighlightOnAllSelectedNodes(state.clickedRenderedNodeId);
    commit("SET_BRANCH_SELECTED_FLAG", false);
    commit("SET_CLICKED_RENDERED_NODE_ID", null);
    commit("SET_SELECTIONS_CLEARED_FLAG", true);
  },

  async nodeOnDrop(
    { state, commit, dispatch, getters },
    { dragNodeId, dropNodeId, showDropBoxFn }
  ) {
    showDropBoxFn.cancel();
    if (!state.isMouseOverDropBox) {
      return false;
    }

    let dragNode = getters.renderedNode(dragNodeId);

    let dropNode = getters.renderedNode(dropNodeId);

    if (
      includesAny(dropNode.tags, [
        NODE_CATEGORIES.DUMMY,
        NODE_TYPES.SAME_NODE.NODE_TYPE
      ]) ||
      !includesAny(dropNode.tags, [
        NODE_CATEGORIES.ONE_CHILD,
        NODE_CATEGORIES.SYSTEM
      ])
    ) {
      await dispatch("setupNotification", {
        title: __("Cannot drop here"),
        type: "warning",
        message: ""
      });
      console.log("cannot drop here");
      return false;
    }

    let parentNodeIds = _.map(
      getters.getParentsFromRenderedGraph(dragNodeId),
      node => String(node.id)
    );

    let subGraph =
      getters.getOriginalNodeFromRenderedNodeId(dragNodeId).node_type.data
        .category === NODE_CATEGORIES.MULTIPLE_CHILD &&
      getRenderedSubGraph(getters.nodesToRender, dragNode.node_id);

    if (
      !(
        !includesAny(parentNodeIds, [dropNodeId, String(dropNode.node_id)]) &&
        !includesAny(
          [dropNodeId, String(dropNode.node_id)],
          [dragNodeId, String(dragNode.id)]
        ) &&
        (_.isEmpty(subGraph) ||
          (!_.isEmpty(subGraph) &&
            !includesAny([dropNodeId, String(dropNode.id)], subGraph)))
      )
    ) {
      console.log(
        "cannot drop on child (if dragged node is of multiple child), or immediate parent, or itself"
      );
      return false;
    }

    let copy_type =
      state.branchSelected ||
      includesAny(dragNode.tags, [
        NODE_CATEGORIES.MULTIPLE_CHILD,
        NODE_TYPES.SAME_NODE.NODE_TYPE
      ])
        ? "cut_branch"
        : "cut_node";

    await dispatch("setDroppedRenderedNodeId", dropNodeId);

    if (
      ((copy_type === "cut_node" && !getters.hasChild(dragNode.node_id)) ||
        copy_type === "cut_branch") &&
      getters.parentsIncludeNonRemovableNodes(dragNodeId)
    ) {
      await dispatch("setupNotification", {
        title: __("Cannot drop here"),
        type: "warning",
        message: ""
      });
      console.log("cannot drop these node/s here");
      return false;
    }
    if (state.fromCanvasId === null) {
      commit("SET_FROM_CANVAS_ID", dragNode.canvas_id);
    }
    if (state.toCanvasId === null) {
      commit("SET_TO_CANVAS_ID", dropNode.canvas_id);
    }

    await dispatch("pasteNode", {
      copy_node_id: dragNode.node_id,
      copy_type,
      ac_id: dropNode.ac_id,
      task_id: dropNode.task_id,
      pid: dropNode.node_id,
      from_canvas_id: state.fromCanvasId,
      to_canvas_id: state.toCanvasId
    })
      .then(async () => {
        await dispatch("resetCopyState");
      })
      .catch(err => {
        console.log(err);
      });

    return false;
  },

  async setChart({ commit, dispatch, getters, state }, chart) {
    commit("CHANGE_LOADING", true);
    commit("SET_CHART", chart);
    state.chart.on("click", function(sender, node) {
      dispatch("respondToClickOrDragOrDrop", { node, action: "click" });
    });

    let hideDropBoxFn = function() {
      let dropBoxes = document.querySelectorAll(".node .drop-box");
      _.map(dropBoxes, dropBox => {
        dropBox.style.display = "none";
      });
      commit("SET_MOUSE_OVER_DROP_BOX_STATUS", false);
    };

    let showDropBoxFn = _.throttle(async function(ev) {
      let nodeElement = ev.target;
      nodeElement.setAttribute(
        "style",
        "transition:fill 0.6s ease;fill:rgba(28, 209, 160, 1);z-index:9999"
      );
      commit("SET_MOUSE_OVER_DROP_BOX_STATUS", true);
    }, 5000);

    state.chart.on("drag", function(chart, id) {
      let dragErrorDelayedFn = _.debounce(function(content) {
        dispatch("setupNotification", content);
      }, 200);
      commit("SET_DRAG_ERROR_DELAYED_RESPONSE", dragErrorDelayedFn);

      if (String(state.clickedRenderedNodeId) !== id) {
        dispatch("respondToClickOrDragOrDrop", {
          node: getters.renderedNode(id),
          action: "click"
        });
      }

      let dragNode = getters.renderedNode(id);
      if (getters.isTaskReadOnly) {
        return false;
      } else if (dragNode.tags.includes(NODE_CATEGORIES.DUMMY)) {
        return false;
      } else if (includesAny([NODE_CATEGORIES.SYSTEM], dragNode.tags)) {
        state.dragErrorDelayedFn({
          title: `Drag disabled`,
          type: "error",
          message: `Cannot drag system nodes`
        });
        return false;
      } else if (
        (dragNode.tags.includes(NODE_CATEGORIES.MULTIPLE_CHILD) ||
          dragNode.tags.includes(NODE_TYPES.SAME_NODE.NODE_TYPE) ||
          state.branchSelected ||
          (!state.branchSelected && !getters.hasChild(dragNode.node_id))) &&
        getters.parentsIncludeNonRemovableNodes(id)
      ) {
        state.dragErrorDelayedFn({
          title: `Drag disabled`,
          type: "error",
          message: `Invalid selection for drag`
        });

        return false;
      } else {
        let node = getters.getOriginalNodeFromRenderedNodeId(id);
        let nodeDropRules = _.cloneDeep(
          _.find(NODE_TYPES, {
            NODE_TYPE: node.node_type.data.node_type
          }).DROP_RULES || {}
        );

        // existing nodes cannot be dropped directly below conditions (studio 6 behaviour)
        if (_.has(nodeDropRules, "onNot")) {
          nodeDropRules.onNot.push(DROP_RULES.DUMMY_NODE);
        } else {
          nodeDropRules.onNot = [DROP_RULES.DUMMY_NODE];
        }

        let selector = dropCriteria(JSON.stringify(nodeDropRules));

        let parentNodeIds = _.map(
          getters.getParentsFromRenderedGraph(id),
          node => String(node.id)
        );

        let subGraph =
          (state.branchSelected ||
            node.node_type.data.category === NODE_CATEGORIES.MULTIPLE_CHILD) &&
          getRenderedSubGraph(getters.nodesToRender, dragNode.node_id);

        if (dragNode.tags.includes(NODE_TYPES.SAME_NODE.NODE_TYPE)) {
          subGraph = _.concat(
            subGraph,
            _.map(getters.getSubGraph(dragNode.node_id), node =>
              String(node.node_id)
            )
          );
        }

        let debounce = _.debounce(function() {
          dispatch("dropBoxesWhenDragged", {
            selector,
            parentNodeIds,
            id,
            dragNode,
            subGraph
          });
        }, 200);
        commit("SET_DEBOUNCE", debounce);
        state.debounce();
      }
    });

    state.chart.on("drop", function(chart, dragNodeId, dropNodeId) {
      dispatch("nodeOnDrop", {
        dragNodeId,
        dropNodeId,
        showDropBoxFn
      });
      return false;
    });

    state.chart.on("redraw", async function() {
      let chartArea = document.querySelector("#callfloweditor");

      if (chartArea !== undefined && chartArea !== null) {
        chartArea.addEventListener("mouseup", function() {
          if (state.debounce !== null) {
            state.debounce.cancel();
            commit("SET_DEBOUNCE", null);
          }

          if (state.dragErrorDelayedFn !== null) {
            state.dragErrorDelayedFn.cancel();
            commit("SET_DRAG_ERROR_DELAYED_RESPONSE", null);
          }

          let dropBoxes = document.querySelectorAll(".node .drop-box");
          _.map(dropBoxes, dropBox => {
            dropBox.style.display = "none";
          });
          commit("SET_MOUSE_OVER_DROP_BOX_STATUS", false);
        });
      }

      let canvasArea = document.querySelector("#orgchart");
      if (canvasArea !== undefined && canvasArea !== null) {
        canvasArea.addEventListener("mouseleave", hideDropBoxFn);
      }

      addHighlightOnSelectedNode(state.chart, state.clickedRenderedNodeId);

      let nodes = document.querySelectorAll(".copyImage");
      for (let i = 0; i < nodes.length; i++) {
        let node = nodes[i];
        node.onclick = function(ev) {
          let parent = ev.target;
          while (!parent.hasAttribute("node-id")) {
            parent = parent.parentNode;
          }
          let nodeId = parent.getAttribute("node-id");

          dispatch("copyNodeUrl", { id: nodeId });
        };
      }

      let nodeElements = document.querySelectorAll(".drop-box");
      for (let i = 0; i < nodeElements.length; i++) {
        let nodeElement = nodeElements[i];
        nodeElement.ondrop = function(ev) {
          showDropBoxFn.cancel();
          ev.preventDefault();

          let nodeType = ev.dataTransfer.getData("nodeType");
          let taskId = ev.dataTransfer.getData("taskId");

          let nodeElement = ev.target;
          nodeElement.setAttribute("style", "fill:transparent");
          while (!nodeElement.hasAttribute("node-id")) {
            nodeElement = nodeElement.parentNode;
          }
          let idParts = nodeElement.getAttribute("node-id").split("_");
          let pid;
          let addNewNodeBelow = true;
          let attemptConvertSameNodeToGoto = false;
          let connectorCondition = "";

          if (idParts[1] !== "condition") {
            pid = idParts[0];
          } else {
            let BGNode = state.chart.getBGNode(
              nodeElement.getAttribute("node-id")
            );

            // figure out the condition text
            connectorCondition = findConnectorConditionTag(BGNode);

            if (!_.isEmpty(BGNode.children)) {
              pid = BGNode.children[0].id.toString().split("_")[0];
              addNewNodeBelow = false;

              // if the new node is going to be placed above a same node
              attemptConvertSameNodeToGoto =
                "same" ===
                _.get(BGNode.children[0].id.toString().split("_"), 1);
            } else {
              return;
            }
          }

          commit("SET_PALETTE_SELECTION", nodeType);

          dispatch("startAddNewNodeProcess", {
            id: pid,
            addNewNodeBelow,
            attemptConvertSameNodeToGoto,
            connectorCondition
          });
          commit("SET_MOUSE_OVER_DROP_BOX_STATUS", false);

          Router.push({
            name: "edit_node",
            params: { task_id: taskId, open_node_id: "new" }
          }).catch(() => {});
        };

        nodeElement.ondragenter = nodeElement.onmousemove = showDropBoxFn;

        nodeElement.ondragleave = nodeElement.onmouseleave = async function(
          ev
        ) {
          showDropBoxFn.cancel();
          let nodeElement = ev.target;
          let parent = ev.target;
          while (!parent.hasAttribute("node-id")) {
            parent = parent.parentNode;
          }
          let pid = parent.getAttribute("node-id");
          let actualPid = pid.split("_")[0];
          nodeElement.setAttribute(
            "style",
            "transition:fill 0.6s ease;fill:transparent"
          );

          if (
            pid === String(state.droppedRenderedNodeId) ||
            actualPid === String(state.droppedRenderedNodeId)
          ) {
            nodeElement.style.display = "none";
          }
          dispatch("setDroppedRenderedNodeId", null);
          commit("SET_MOUSE_OVER_DROP_BOX_STATUS", false);
        };

        nodeElement.ondragover = function(ev) {
          ev.preventDefault();
        };
      }
      if (state.selectionsCleared) {
        commit("SET_SELECTIONS_CLEARED_FLAG", false);
      }

      if (state.zoom !== undefined) {
        state.chart.zoom(state.zoom, null, true);
        dispatch("setZoom", undefined);
      }
    });

    state.chart.nodeMenuUI.on("show", function(sender, args) {
      let node = getters.renderedNode(args.firstNodeId);
      // reverse the tags
      let tags = _.reverse(_.cloneDeep(node.tags) || []);
      let key = _.find(tags, tag => Object.keys(menus).includes(tag)) || "node";
      args.menu = menus[key].nodeMenu;
    });

    commit("CHANGE_LOADING", false);
  },

  setNodesInChart({ commit }, nodes) {
    return new Promise(resolve => {
      commit("SET_NODES_IN_CHART", nodes);
      resolve();
    });
  },

  setPaletteMounted({ commit }, flag) {
    commit("SET_PALETTE_MOUNTED_FLAG", flag);
  },

  async reInitializeNodesAndCanvases(
    { commit, dispatch, getters },
    { nodes, ac_id, task_id }
  ) {
    commit("SET_NODES", nodes);
    commit("SET_TASK_MODIFIED_FLAG", true);
    await dispatch(
      "tasks/taskModified",
      { task_id, modified: true },
      { root: true }
    );
    await dispatch("getCanvases", { ac_id, task_id });
    await dispatch("setNodesInChart", getters.nodesToRender);
    await dispatch("setClickedNode", null);

    // return Promise.all([
    //   commit("SET_NODES", nodes),
    //   commit("SET_TASK_MODIFIED_FLAG", true),
    //   dispatch(
    //     "tasks/taskModified",
    //     { task_id, modified: true },
    //     { root: true }
    //   ),
    //   dispatch("getCanvases", { ac_id, task_id }),
    //   dispatch("setNodesInChart", getters.nodesToRender),
    //   dispatch("setClickedNode", null)
    // ]);
  },

  getNodes({ commit, state, dispatch }, { ac_id, task_id }) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      getNodes({ ac_id, task_id, include: "node_type" })
        .then(async ({ data }) => {
          commit("SET_NODES", _.get(data, "data", {}));
          await dispatch("getCanvases", { ac_id, task_id });
          commit("CHANGE_LOADING", false);
          resolve(state.nodes);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          console.log(err);
          reject();
        });
    });
  },

  getCanvases({ commit }, { ac_id, task_id }) {
    return new Promise((resolve, reject) => {
      getCanvases({ ac_id, task_id })
        .then(({ data }) => {
          commit("SET_CANVASES", _.get(data, "data", {}));
          resolve();
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  },

  createCanvas({ commit, dispatch }, { ac_id, task_id }) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      commit("CHANGE_CANVAS_CREATE_STATUS", false);
      createCanvas({ ac_id, task_id })
        .then(({ data }) => {
          commit("SET_NODES", _.get(data, "nodes.data", []));
          commit("ADD_CANVAS", _.get(data, "canvas.data", {}));
          commit("SET_NEW_CANVAS", _.get(data, "canvas.data", {}));
          commit("SET_TASK_MODIFIED_FLAG", true);
          dispatch(
            "tasks/taskModified",
            { task_id, modified: true },
            { root: true }
          );
          commit("CHANGE_LOADING", false);
          commit("CHANGE_CANVAS_CREATE_STATUS", true);
          resolve(_.get(data, "canvas.data", {}));
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          commit("CHANGE_CANVAS_CREATE_STATUS", true);
          reject(err);
        });
    });
  },

  setTaskModifiedFlag({ commit }, flag) {
    commit("SET_TASK_MODIFIED_FLAG", flag);
  },

  setTaskVersionInUse({ commit }, id) {
    commit("SET_TASK_VERSION_IN_USE", id);
  },

  renameCanvas(
    { commit, state, getters, dispatch },
    { oldName, newName, ac_id }
  ) {
    let canvas = getters.getCanvasByName(oldName);
    if (
      !_.isEmpty(canvas) &&
      newName &&
      newName !== oldName &&
      !getters.canvasExists(newName)
    ) {
      const newCanvas = { ...canvas, canvas_name: newName };
      commit("RENAME_CANVAS", newCanvas);
      editCanvas(newCanvas)
        .then(() => {
          commit("SET_TASK_MODIFIED_FLAG", true);
          dispatch(
            "tasks/taskModified",
            { task_id: newCanvas.task_id, modified: true },
            { root: true }
          );
          dispatch("getCanvases", { ac_id, task_id: newCanvas.task_id });
        })
        .catch(err => {
          commit("RENAME_CANVAS", { ...canvas, canvas_name: oldName });
          commit("SET_CANVAS_RENAME_STATUS", false);
          console.log(err);
        });
      if (state.selectedCanvas === oldName) {
        dispatch("changeSelectedCanvas", newName);
      }
      commit("SET_CANVAS_RENAME_STATUS", true);
    } else {
      commit("SET_CANVAS_RENAME_STATUS", false);
    }
  },

  updateCanvas({ commit, dispatch }, canvas) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      editCanvas(canvas)
        .then(({ data }) => {
          commit("SET_TASK_MODIFIED_FLAG", true);
          dispatch(
            "tasks/taskModified",
            { task_id: canvas.task_id, modified: true },
            { root: true }
          );
          commit("CHANGE_LOADING", false);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          console.log(err);
          reject(err);
        });
    });
  },

  async changeSelectedCanvas({ commit, getters, dispatch }, canvas) {
    commit("SET_CLICKED_NODE", null);
    commit("SET_CLICKED_RENDERED_NODE_ID", null);
    commit("SET_SELECTED_CANVAS", canvas);
    await dispatch("setNodesInChart", getters.nodesToRender);
  },

  startAddNewNodeProcess(
    { commit, dispatch },
    // id coming here is the id property of the rendered node
    // in the chart, not the node_id of the node that is returned from backend
    { id, addNewNodeBelow, attemptConvertSameNodeToGoto, connectorCondition }
  ) {
    dispatch("changeCanvasNodeMode", false);
    commit("SET_NEW_NODE_POSITION", addNewNodeBelow);
    commit(
      "SET_ATTEMPT_CONVERT_SAME_NODE_TO_GOTO",
      !!attemptConvertSameNodeToGoto
    );
    commit("SET_NEW_NODE_TARGET_CONNECTOR_CONDITION", connectorCondition);
    dispatch("setClickedNode", id);
    EventBus.$emit("log-user-activity", "adding new node", null);
  },

  changeCanvasNodeMode({ commit }, isEdit) {
    commit("CHANGE_CANVAS_NODE_MODE", isEdit);
  },

  setClickedNode({ commit, getters }, id) {
    let node = {};
    if (id) {
      // rendered node may have a different id than original node_id, for instance same nodes
      // so to get the original node, get the rendered node and then fetch
      // the original node from state.nodes using node_id, instead of id
      const { node_id } = getters.renderedNode(id);
      node = getters.getOriginalNode(node_id);
    } else {
      commit("RESET_ADD_NEW_NODE_STATE");
    }
    commit("SET_CLICKED_NODE", node);
  },

  setClickedRenderedNodeId({ commit, getters }, id) {
    return new Promise(resolve => {
      let node = getters.renderedNode(id);
      let isDummyNode =
        !_.isEmpty(node) && node.tags.includes(NODE_CATEGORIES.DUMMY);
      if (!isDummyNode || id === null) {
        commit("SET_PREV_CLICKED_RENDERED_NODE_ID");
        commit("SET_CLICKED_RENDERED_NODE_ID", id);
      }
      commit("TOGGLE_NODE_CLICK_FLAG");
      resolve();
    });
  },

  setExpressionBuilderModalShow({ commit }, flag) {
    commit("SET_EXPRESSION_BUILDER_SHOW", flag);
  },

  setShowNotificationStatus({ commit }, flag) {
    commit("SET_SHOW_NOTIFICATION_STATUS", flag);
  },

  setNotificationContent({ commit }, content) {
    commit("SET_NOTIFICATION_CONTENT", content);
  },

  setShowDeleteConfirmationBox({ commit }, flag) {
    commit("SET_SHOW_DELETE_CONFIRMATION_BOX", flag);
  },

  setupNotification({ dispatch }, content) {
    dispatch("setNotificationContent", content);
    dispatch("setShowNotificationStatus", true);
  },

  setDroppedRenderedNodeId({ commit, getters }, id) {
    let node = getters.renderedNode(id);
    let isDummyNode =
      !_.isEmpty(node) && node.tags.includes(NODE_CATEGORIES.DUMMY);
    if (!isDummyNode || id === null) {
      commit("SET_DROPPED_RENDERED_NODE_ID", id);
    }
  },

  centerCanvas({ commit, state }, nodeId) {
    if (nodeId || state.clickedRenderedNodeId) {
      commit("CENTER_CANVAS", nodeId || state.clickedRenderedNodeId);
    }
  },

  addNewNode({ commit, state, dispatch }, { node, canvasId }) {
    return new Promise((resolve, reject) => {
      // NOTE: as of now, only allows adding a new node below an existing node
      // if (state.addNewNodeBelow) {
      commit("CHANGE_LOADING", true);
      createNode(
        node,
        canvasId,
        state.addNewNodeBelow,
        state.attemptConvertSameNodeToGoto,
        state.connectorCondition
      )
        .then(async ({ data }) => {
          await dispatch("reInitializeNodesAndCanvases", {
            nodes: _.get(data, "nodes.data", []),
            ac_id: node.ac_id,
            task_id: node.task_id
          });
          await dispatch(
            "setClickedRenderedNodeId",
            _.get(data, "node.data.node_id", "")
          );
          dispatch("centerCanvas", _.get(data, "node.data.node_id", ""));
          await dispatch("resetPaletteSelection");

          commit("CHANGE_LOADING", false);

          EventBus.$emit(
            "log-user-activity",
            "added",
            _.get(data, "node.data", {})
          );
          resolve();
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          console.log(err);
          dispatch("setupNotification", {
            title: __("Create failed"),
            type: "error",
            message: `${err.response.data.message}`
          });
          reject(err);
        });
      // }
    });
  },

  resetAddNodeState({ commit }) {
    commit("RESET_ADD_NEW_NODE_STATE");
  },

  resetPaletteSelection({ commit }) {
    commit("SET_PALETTE_SELECTION", null);
  },

  async changeBranchSelectedFlag({ commit, dispatch, state, getters }, flag) {
    commit("SET_BRANCH_SELECTED_FLAG", flag);
    commit("SET_SELECTIONS_CLEARED_FLAG", !flag);
    if (state.clickedRenderedNodeId) {
      commit("SET_PREV_CLICKED_RENDERED_NODE_ID");
    }
    if (flag) {
      // find all nodes
      _.map(
        getRenderedSubGraph(getters.nodesToRender, state.clickedRenderedNodeId),
        id => {
          dispatch("addHighlightOnSelectedNodeAction", {
            nodeId: id,
            classSelector: "focused"
          });
        }
      );
    }
  },

  editNode({ commit, dispatch }, { node, canvasId }) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      editNode(
        node,
        canvasId,
        state.addNewNodeBelow,
        state.attemptConvertSameNodeToGoto,
        state.connectorCondition
      )
        .then(async ({ data }) => {
          commit("CHANGE_LOADING", false);
          const { ac_id, task_id, node_id } = node;
          await dispatch("reInitializeNodesAndCanvases", {
            nodes: _.get(data, "nodes.data", []),
            ac_id,
            task_id
          });

          await dispatch(
            "setClickedRenderedNodeId",
            _.get(data, "node.data.node_id", "")
          );
          dispatch("centerCanvas", node_id);

          EventBus.$emit("log-user-activity", "updated", node);
          resolve();
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          dispatch("setupNotification", {
            title: __("Update failed"),
            type: "error",
            message: `${err.response.data.message}`
          });
          console.log(err);
          reject();
        });
    });
  },

  async resetCopyState({ commit, dispatch, state }) {
    if (!_.isEmpty(state.copyType) || !_.isEmpty(state.nodeIdToCopy)) {
      commit("RESET_COPY_STATE");

      dispatch("removeHighlightOnAllSelectedNodesAction", {
        classSelector: "focused",
        nodeId: state.clickedRenderedNodeId
      });
      dispatch("removeHighlightOnAllSelectedNodesAction", {
        classSelector: "cut"
      });
      dispatch("removeHighlightOnAllSelectedNodesAction", {
        classSelector: "copy"
      });
    }
  },

  pasteNode({ commit, dispatch, state, getters }, pasteConfig) {
    return new Promise((resolve, reject) => {
      // if we paste in between a cut branch, it should not be allowed
      if (
        state.copyType === "cut_branch" &&
        getters.isNodeInRenderedSubGraph(
          getters.renderedNode(pasteConfig.pid), // this is the desired parent of the cut branch
          pasteConfig.copy_node_id // this is the root of the cut branch
        )
      ) {
        dispatch("setupNotification", {
          title: __("Cannot paste here"),
          type: "error",
          message: ""
        });
        reject();
      } else {
        commit("CHANGE_LOADING", true);
        pasteNode(pasteConfig)
          .then(async ({ data }) => {
            let nodes_reference = data.nodes_reference_other_tasks;
            if (!_.isEmpty(nodes_reference)) {
              commit("SET_PASTED_NODES_WITH_REFERENCE", nodes_reference);
              EventBus.$emit("open-pasted-reminder-dialog-box", {
                content_model: "Node",
                content_id: nodes_reference[0].node_id
              });
            } else {
              commit("SET_PASTED_NODES_WITH_REFERENCE", []);
            }
            if (["cut_node", "cut_branch"].includes(state.copyType)) {
              commit("RESET_COPY_STATE");
            }
            const { ac_id, task_id } = pasteConfig;
            await dispatch("reInitializeNodesAndCanvases", {
              nodes: _.get(data, "nodes.data", []),
              ac_id,
              task_id
            });

            let nodeId = _.get(data, "node.data.node_id", "");

            const canvasOfNode = getters.canvasOfNode(nodeId);
            if (canvasOfNode !== getters.currentCanvas.canvas_name) {
              dispatch("changeSelectedCanvas", canvasOfNode);
            }

            dispatch("respondToClickOrDragOrDrop", {
              node: getters.renderedNode(nodeId),
              action: "click"
            });

            await dispatch("setNodesInChart", getters.nodesToRender);

            dispatch("centerCanvas", nodeId);

            commit("CHANGE_LOADING", false);

            if (["copy_branch", "copy_node"].includes(state.copyType)) {
              commit("SET_NODE_ID_TO_COPY", nodeId);
              dispatch("copyNode", { id: nodeId, copyType: state.copyType });
            }
            EventBus.$emit(
              "log-user-activity",
              "pasted",
              _.get(data, "node.data", {})
            );
            resolve();
          })
          .catch(err => {
            commit("CHANGE_LOADING", false);
            console.log(err);
            dispatch("setupNotification", {
              title: __("Paste operation failed"),
              type: "error",
              message: ""
            });
            reject();
          });
      }
    });
  },

  copyNode({ commit, dispatch, getters, state }, { id, copyType }) {
    return new Promise((resolve, reject) => {
      dispatch("removeHighlightOnAllSelectedNodesAction", {
        classSelector: "focused",
        nodeId: id
      });
      dispatch("removeHighlightOnAllSelectedNodesAction", {
        classSelector: "cut"
      });
      dispatch("removeHighlightOnAllSelectedNodesAction", {
        classSelector: "copy"
      });

      const node = getters.renderedNode(id);

      // ie, if copyType is copy_branch or copy_node
      // we only need to set the state and wait for
      // paste action to actually trigger an api request
      // to paste the node
      // copy branch to new canvas can immediately request
      // the api to create a new canvas with the copied branch
      if (node) {
        commit("SET_FROM_CANVAS_ID", getters.currentCanvas.canvas_id);
        if (copyType !== "copy_branch_to_new_canvas") {
          if (
            ((copyType === "cut_node" &&
              (!getters.hasChild(node.node_id) ||
                node.tags.includes(NODE_TYPES.SAME_NODE.NODE_TYPE))) ||
              copyType === "cut_branch") &&
            getters.parentsIncludeNonRemovableNodes(id)
          ) {
            dispatch("setupNotification", {
              title: __("Cut Paste not permitted "),
              type: "warning",
              message: __("Selected node/s cannot be cut")
            });

            reject(
              // eslint-disable-next-line
                __("Cannot cut a node that results in empty otherwise path, cancelling cut operation")
            );
          } else {
            commit("SET_COPY_TYPE", copyType);
            commit("SET_COPY_TASK_TYPE", state.task_type);
            commit("SET_NODE_ID_TO_COPY", node.node_id);

            if (
              ["copy_branch", "cut_branch"].includes(state.copyType) &&
              !state.branchSelected
            ) {
              dispatch("changeBranchSelectedFlag", true);
            } else if (["copy_branch", "cut_branch"].includes(state.copyType)) {
              // find all nodes
              _.map(getRenderedSubGraph(getters.nodesToRender, id), id => {
                dispatch("addHighlightOnSelectedNodeAction", {
                  nodeId: id,
                  classSelector: "focused"
                });
              });
            }

            // identifying nodes need to be cut
            if (["cut_node", "cut_branch"].includes(state.copyType)) {
              dispatch("addHighlightOnSelectedNodeAction", {
                nodeId: id,
                classSelector: "cut"
              });
              if (state.copyType === "cut_branch") {
                _.map(
                  getRenderedSubGraph(getters.nodesToRender, id),
                  async id => {
                    await dispatch("addHighlightOnSelectedNodeAction", {
                      nodeId: id,
                      classSelector: "cut"
                    });
                  }
                );
              }
            }

            // identifying nodes need to be copied
            if (["copy_node", "copy_branch"].includes(state.copyType)) {
              dispatch("addHighlightOnSelectedNodeAction", {
                nodeId: id,
                classSelector: "copy"
              });
              if (state.copyType === "copy_branch") {
                _.map(
                  getRenderedSubGraph(getters.nodesToRender, id),
                  async id => {
                    await dispatch("addHighlightOnSelectedNodeAction", {
                      nodeId: id,
                      classSelector: "copy"
                    });
                  }
                );
              }
            }

            resolve();
          }
        } else {
          dispatch("pasteNode", {
            copy_node_id: _.get(node, "node_id", ""),
            copy_type: "copy_branch_to_new_canvas",
            ac_id: _.get(node, "ac_id", ""),
            task_id: _.get(node, "task_id", ""),
            pid: _.get(node, "node_id", "")
          })
            .then(() => {
              return resolve();
            })
            .catch(err => {
              console.log(err);
              dispatch("setupNotification", {
                title: __("Branch Cloning Failed"),
                type: "error",
                message: `${err.response.data.message}`
              });
              reject();
            });
        }
      }
    });
  },

  /**
   * this is the action dispatched from the node menu
   */
  triggerPasteNode({ commit, state, getters, dispatch }, { id }) {
    const node = getters.renderedNode(id);
    commit("SET_TO_CANVAS_ID", getters.currentCanvas.canvas_id);

    return new Promise((resolve, reject) => {
      if (state.nodeIdToCopy && state.copyType) {
        dispatch("pasteNode", {
          copy_node_id: state.nodeIdToCopy,
          copy_type: state.copyType,
          ac_id: _.get(node, "ac_id", ""),
          task_id: _.get(node, "task_id", ""),
          pid: _.get(node, "node_id", ""),
          from_canvas_id: state.fromCanvasId,
          to_canvas_id: state.toCanvasId
        })
          .then(() => {
            return resolve();
          })
          .catch(err => {
            console.log(err);
            reject();
          });
      } else {
        dispatch("setupNotification", {
          title: __("Paste operation failed"),
          type: "warning",
          message: __("no node has been copied")
        });

        reject(__("no node has been copied"));
      }
    });
  },

  unlinkNode({ commit, dispatch, getters }, { id, unlinkType }) {
    return new Promise((resolve, reject) => {
      const node = getters.renderedNode(id);
      if (
        ((unlinkType === "unlink_node" && !getters.hasChild(node.node_id)) ||
          unlinkType === "unlink_branch") &&
        getters.parentsIncludeNonRemovableNodes(id)
      ) {
        dispatch("setupNotification", {
          title: __("Unlinking not permitted "),
          type: "warning",
          message: __("Selected node/s cannot be unlinked")
        });

        reject(
          // eslint-disable-next-line
          __("Cannot unlink a node that results in empty otherwise path, cancelling unlink operation")
        );
      } else {
        commit("CHANGE_LOADING", true);

        unlinkNode({
          unlink_node_id: _.get(node, "node_id", ""),
          unlink_type: unlinkType,
          ac_id: _.get(node, "ac_id", ""),
          task_id: _.get(node, "task_id", "")
        })
          .then(async ({ data }) => {
            const { ac_id, task_id, node_id } = node;
            let parent = getters.getParent(node_id);
            await dispatch("reInitializeNodesAndCanvases", {
              nodes: _.get(data, "data", []),
              ac_id,
              task_id
            });

            let selectedNodeId = !_.isEmpty(parent)
              ? parent.node_id
              : getters.currentCanvas.first_node_id;
            await dispatch("setClickedRenderedNodeId", selectedNodeId);
            dispatch("centerCanvas", selectedNodeId);

            commit("CHANGE_LOADING", false);
            EventBus.$emit("log-user-activity", "unlinked", node);
            resolve();
          })
          .catch(err => {
            commit("CHANGE_LOADING", false);
            console.log(err);
            dispatch("setupNotification", {
              title: __("Unlinking node/s failed"),
              type: "error",
              message: `${err.response.data.message}`
            });

            reject();
          });
      }
    });
  },

  GotoTask({ getters }, { id }) {
    const node = getters.getOriginalNode(id);
    if (node) {
      let task_id = _.get(node, "link_task_node.data.task_id", "");
      Router.push({ name: "callflow", params: { task_id: task_id } });
    }
  },

  askPermissionForDelete({ getters, dispatch, commit }, { id, deleteType }) {
    const node = getters.renderedNode(id);

    if (
      ((deleteType === "delete_node" && !getters.hasChild(node.node_id)) ||
        deleteType === "delete_branch") &&
      getters.parentsIncludeNonRemovableNodes(id)
    ) {
      dispatch("setupNotification", {
        title: __("Delete Not Permitted"),
        type: "warning",
        message: __("Selected node/s cannot be deleted")
      });
    } else {
      dispatch(
        "folders/checkInUse",
        {
          content_model: "Node",
          content_id: id,
          mode: deleteType
        },
        { root: true }
      ).then(res => {
        if (_.isEmpty(res)) {
          commit("SET_GOTO_NODE_USAGES", []);
          commit("SET_DELETE_CONFIGURATION", { id, deleteType });
          dispatch("setShowDeleteConfirmationBox", true);
        } else {
          commit("SET_CANVAS_USAGES", []);
          commit("SET_NODE_DELETE_TYPE", deleteType);
          commit("SET_GOTO_NODE_USAGES", [...res]);
          EventBus.$emit("open-delete-confirmation-dialog-box", {
            content_model: "Node",
            content_id: id
          });
          console.log(res);
        }
      });
    }
  },

  deleteNode(
    { commit, dispatch, getters, state },
    { id, deleteType, canvasId }
  ) {
    return new Promise((resolve, reject) => {
      const node = getters.renderedNode(id);

      if (
        ((deleteType === "delete_node" && !getters.hasChild(node.node_id)) ||
          deleteType === "delete_branch") &&
        getters.parentsIncludeNonRemovableNodes(id)
      ) {
        dispatch("setupNotification", {
          title: __("Delete Not Permitted"),
          type: "warning",
          message: __("Selected node/s cannot be deleted")
        });
        reject(
          // eslint-disable-next-line
          __("Cannot delete a node that results in deletion of otherwise path, cancelling delete operation")
        );
      } else {
        // reset the copy status if the deleted node is the node to be copied or
        // deleted branch has the copied node
        if (
          state.nodeIdToCopy &&
          _.includes(
            _.map(getters.getSubGraph(node.node_id), "node_id"),
            state.nodeIdToCopy
          )
        ) {
          commit("RESET_COPY_STATE");
        }
        commit("CHANGE_LOADING", true);

        const {
          first_node_id: currentCanvasFirstNodeId
        } = getters.currentCanvas;

        // if deletion of the node/branch would result in an empty canvas
        // then main canvas has to be rendered
        const moveBackToMainCanvas =
          getters.nodesToRender.length === 1 ||
          (deleteType === "delete_branch" &&
            node.node_id === currentCanvasFirstNodeId);

        deleteNode(node.node_id, deleteType, canvasId)
          .then(async ({ data }) => {
            let parent = {};
            if (moveBackToMainCanvas) {
              commit("SET_SELECTED_CANVAS", getters.mainCanvas.canvas_name);
            } else {
              parent = _.cloneDeep(getters.getParent(node.node_id));
            }
            const { ac_id, task_id } = node;
            await dispatch("reInitializeNodesAndCanvases", {
              nodes: _.get(data, "data", []),
              ac_id,
              task_id
            });

            let node_id = !_.isEmpty(parent)
              ? parent.node_id
              : getters.currentCanvas.first_node_id;
            await dispatch("setClickedRenderedNodeId", node_id);
            dispatch("centerCanvas", node_id);

            commit("CHANGE_LOADING", false);

            EventBus.$emit("log-user-activity", "deleted", node);
            resolve();
          })
          .catch(err => {
            commit("CHANGE_LOADING", false);
            console.log(err);

            dispatch("setupNotification", {
              title: __("Deleted node/s failed"),
              type: "error",
              message: `${err.response.data.message}`
            });

            reject();
          });
      }
    });
  },

  async focusOnNode({ dispatch, getters }, { node_id, goto_node_id }) {
    if (node_id !== "") {
      const canvasOfNode = getters.canvasOfNode(node_id);
      const canvasOfGotoNode = getters.canvasOfNode(goto_node_id);
      if (!_.isEqual(canvasOfNode, canvasOfGotoNode)) {
        await dispatch("changeSelectedCanvas", canvasOfGotoNode);
      } else {
        await dispatch("setNodesInChart", getters.nodesToRender);
      }
      await dispatch("setClickedRenderedNodeId", goto_node_id);
      dispatch("centerCanvas", goto_node_id);
    }
  },

  // eslint-disable-next-line no-unused-vars
  goBack({ commit, dispatch }) {
    return new Promise(resolve => {
      let source = state.gotoBack.goto_source;
      let target = state.gotoBack.goto_target;
      commit("SET_GOTO_BACK", { source: "", target: "" });
      dispatch("focusOnNode", {
        node_id: target,
        goto_node_id: source
      });
      resolve();
    });
  },

  gotoNode({ commit, dispatch, getters }, { id }) {
    return new Promise(resolve => {
      const node = getters.renderedNode(id);
      commit("SET_GOTO_BACK", {
        source: id,
        target: _.get(node, "goto_node.data.goto_node_id", "")
      });
      if (node.goto_node.data.goto_node_id !== "") {
        dispatch("focusOnNode", {
          node_id: id,
          goto_node_id: _.get(node, "goto_node.data.goto_node_id", "")
        });
      }
      resolve();
    });
  },

  copyNodeUrl({ getters, dispatch, rootState }, { id }) {
    return new Promise(resolve => {
      const node = getters.renderedNode(id);
      let path = window.location.href;
      const el = document.createElement("textarea");
      el.value =
        getCleanedUpCallflowUrl(path) +
        `/${node.node_id}?ac_id=${node.ac_id}&sp_id=${rootState.app.selectedServiceProviderId}`;
      document.body.appendChild(el);
      el.setAttribute("readonly", "");
      el.select();
      const res = document.execCommand("copy");
      if (!res) {
        dispatch("setupNotification", {
          title: __("Node URL copying failed."),
          type: "error",
          message: __("Cannot copy node url")
        });
      }
      document.body.removeChild(el);
      resolve();
    });
  },

  removeCanvases({ commit }) {
    commit("REMOVE_CANVASES");
  },

  removeNodes({ commit }) {
    commit("REMOVE_NODES");
  },

  toggleNodeSubmit({ commit }, isSubmit) {
    commit("SET_NODE_SUBMIT", isSubmit);
  },

  toggleValidationsInProgress({ commit }, validationsInProgress) {
    commit("SET_VALIDATIONS_IN_PROGRESS", validationsInProgress);
  },

  toggleNodeCancelCompletedState({ commit }, isCancelCompleted) {
    commit("SET_NODE_CANCEL_COMPLETED_STATE", isCancelCompleted);
  },

  toggleNodeUpdateStatus({ commit }, isNodeChanged) {
    commit("TOGGLE_NODE_UPDATE_STATUS", isNodeChanged);
  },

  /**
   * keep a copy of the node name for when edit a node name and change the node type
   * @param commit
   * @param nodeName
   */
  updateNodeNameCopy({ commit }, nodeName) {
    commit("SET_NODE_NAME_COPY", nodeName);
  },

  deleteCanvas({ commit, getters, state }, canvas) {
    return new Promise((resolve, reject) => {
      commit("CHANGE_LOADING", true);
      if (
        state.nodeIdToCopy &&
        _.includes(
          getters.canvasToNodesMapKeyedByCanvasId[canvas.canvas_id].nodes,
          state.nodeIdToCopy
        )
      ) {
        commit("RESET_COPY_STATE");
      }
      deleteCanvas(canvas)
        .then(data => {
          commit("CHANGE_LOADING", false);
          commit("SET_TASK_MODIFIED_FLAG", true);
          resolve(data);
        })
        .catch(err => {
          commit("CHANGE_LOADING", false);
          console.log(err);
          reject(err);
        });
    });
  },

  // user presence feature
  async reloadTaskAndOverwrite(
    { commit, getters, dispatch },
    { canvas, nodeId = null }
  ) {
    // commit("CHANGE_LOADING", true);
    commit("SET_SELECTED_CANVAS", canvas);
    await dispatch("setNodesInChart", getters.nodesToRender);

    dispatch("setClickedNode", nodeId);
    // commit("CHANGE_LOADING", false);
  },

  changeLoadingState({ commit }, status) {
    if (status === true || status === false) {
      commit("CHANGE_LOADING", status);
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
