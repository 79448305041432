<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      isReceiving: true,
      email: "",
      token: "",
      resetErrorMessage: "",
      resetHasError: false
    };
  },
  methods: {
    ...mapActions("users", {
      validatePasswordResetRequest: "validatePasswordResetRequest",
      validate2FAResetRequest: "validate2FAResetRequest"
    }),
    processReset() {
      this.email = this.$route.query.email
        .replace(/ /g, "+")
        .replace(/%40/gi, "@")
        .replace(/%3A/gi, ":")
        .replace(/%24/g, "$")
        .replace(/%2C/gi, ",")
        .replace(/%3B/gi, ";")
        .replace(/%20/g, "+");
      this.token = this.$route.query.token;
    },
    redirectToLoginPage() {
      this.$router.push({ path: "/login" }).catch(() => {});
    }
  }
};
</script>
