<template>
  <div v-if="formAction" style="padding: 20px">
    <div v-if="multipleItemsSelectedCount" class="side-panel-selected-count">
      <p>
        Selected
        <el-tag>
          <strong>{{ multipleItemsSelectedCount }}</strong>
        </el-tag>
        items.
      </p>
    </div>
    <div v-else class="side-panel-content-info">
      <div class="field-group">
        <div class="titles">{{ __("Name") }}</div>
        <div class="details">{{ contentFormInfo.name }}</div>
      </div>

      <div class="field-group">
        <div class="titles">{{ __("Return Type") }}</div>
        <div class="details">{{ contentFormInfo.function_return_type }}</div>
      </div>

      <div class="field-group">
        <div class="titles">{{ __("Description") }}</div>
        <div class="details">{{ contentFormInfo.description }}</div>
      </div>

      <div class="field-group">
        <div class="titles">{{ __("Arguments") }}</div>
        <ul>
          <li
            class="details"
            v-for="(arg,
            index) in contentFormInfo.javascript_function_parameters"
            :key="index"
          >
            {{ arg.name }} ({{ arg.parameter_type }})
          </li>
        </ul>
      </div>
    </div>

    <div style="margin-top: 20px;" class="side-panel-action-list">
      <el-dropdown
        @command="command => handleAction(command)"
        placement="bottom-start"
      >
        <el-button plain class="btn-plain">{{ __("Actions") }}</el-button>
        <el-dropdown-menu slot="dropdown" style="width: 200px;">
          <el-dropdown-item
            v-show="!multipleItemsSelectedCount"
            command="edit"
            :disabled="!can('content.javascript-functions.write')"
          >
            <span>{{ __("Edit") }}</span>
          </el-dropdown-item>
          <el-dropdown-item
            command="move"
            :disabled="!can('content.javascript-functions.write')"
          >
            <span>{{ __("Move to folder") }}</span>
          </el-dropdown-item>
          <el-dropdown-item
            v-show="!multipleItemsSelectedCount"
            command="check_in_use"
            :disabled="!!contentFormInfo.is_refreshing"
          >
            <span>{{ __("Check in use") }}</span>
          </el-dropdown-item>
          <el-dropdown-item
            v-show="!multipleItemsSelectedCount"
            command="refresh"
          >
            <span>{{ __("Refresh check in use") }}</span>
          </el-dropdown-item>

          <el-dropdown-item
            v-show="!multipleItemsSelectedCount"
            :disabled="!can('content.javascript-functions.write')"
            command="delete"
          >
            <span>{{ __("Delete") }}</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <el-dialog
      :visible.sync="openModal"
      v-if="openModal"
      fullscreen
      :show-close="false"
      custom-class="editContentItemModal"
      destroy-on-close
      v-loading="isSubmitting"
      :modal="false"
    >
      <el-scrollbar :native="false">
        <div class="max-vh">
          <el-form
            ref="codeEditor"
            :rules="rules"
            :model="contentForm"
            label-position="top"
            size="large"
            :disabled="isArchivedStatus"
          >
            <code-editor
              :value="contentForm"
              :generate-code-enabled="showCodeGenerateTool"
              @all-argument-saved="allArgumentSaved = $event"
              @update-selected-libraries="updateSelectedLibraries"
            />
          </el-form>
          <div
            slot="footer"
            style="display: flex;margin-left: 25%;margin-bottom: 20px; margin-top: 30px;"
          >
            <el-button
              slot="reference"
              :disabled="isDisabled"
              type="primary"
              class="submitBtn"
              v-if="showConfirmSave"
              :loading="loading"
              @click="confirmSave"
              >{{ saveButtonLabel }}
            </el-button>
            <el-button
              v-else
              :disabled="isDisabled"
              type="primary"
              @click="submitForm"
              class="submitBtn"
              :loading="loading"
              >{{ saveButtonLabel }}
            </el-button>
            <el-button @click="handleCancel" class="cancelBtn"
              >{{ __("Cancel") }}
            </el-button>
          </div>
        </div>
      </el-scrollbar>
    </el-dialog>
  </div>
</template>

<script>
import CodeEditor from "../components/CodeEditor";
import { mapState, mapActions } from "vuex";
import BaseContentInfoPanel from "@/views/build/content/mixins/BaseContentInfoPanel";
import { searchJavascriptFunction } from "@/api/javascript";
import { EventBus } from "@/EventBus";
import { SYS_VAR } from "@/constants/systemVariables";
import BaseContent from "@/views/build/content/mixins/BaseContent";
import _ from "lodash";
import {
  CHECK_IN_USE_ACTION,
  DELETE_ACTION,
  EDIT_ACTION,
  MOVE_ACTION,
  REFRESH_ACTION
} from "@/constants/contents";
import { maxCodeLimitCharacters } from "@/constants/javscriptFunctions";

export default {
  mixins: [BaseContentInfoPanel, BaseContent],
  components: { CodeEditor },

  data() {
    let validateFunctionName = async (rule, value, callback) => {
      let reservedVariableNames = [SYS_VAR.PREFIX];

      if (reservedVariableNames.includes(value)) {
        callback(__(":value is a reserved keyword.", { value: value }));
      } else {
        let hasError = false;
        await searchJavascriptFunction(value)
          .then(res => {
            if (res.data.found && value !== this.contentFormInfo.name) {
              hasError = true;
            }
          })
          .catch(() => {
            hasError = false;
          });

        if (hasError) {
          callback(__("Function :value  already exists.", { value: value }));
        } else {
          callback();
        }
      }
    };

    const validateFunctionReturnType = async (rule, value, callback) => {
      let hasError = false;
      hasError |= _.isEmpty(value);

      if (hasError) {
        callback(__("JavaScript function type not selected"));
      } else {
        callback();
      }
    };

    return {
      rules: {
        name: [
          {
            required: true,
            message: __("Please input function name"),
            trigger: "blur"
          },
          { validator: validateFunctionName, trigger: "blur" }
        ],
        function_return_type: [
          {
            required: true,
            message: __("Please input JavaScript type"),
            trigger: "blur"
          },
          { validator: validateFunctionReturnType, trigger: "blur" }
        ],
        description: [
          {
            required: true,
            message: __("Please input description"),
            trigger: "blur"
          }
        ]
      },
      additionalValidateRoute: "javascript-functions",
      isContent: true,
      viewMode: "allow_read",
      allArgumentSaved: true,
      showCodeGenerateTool: true,
      forceDeleteEventName: "initiate-force-delete-JavascriptFunction",
      safeDeleteEventName: "initiate-safe-delete-JavascriptFunction"
    };
  },

  computed: {
    ...mapState("app", {
      selectedAccountId: state => state.selectedAccountId
    }),
    ...mapState("javascripts", {
      loading: state => state.loading
    }),
    ...mapState("folders", {
      selectedFolderId: state => state.selectedFolderId
    }),

    /**
     * display 1. in use by x nodes 2. parameter values too large 3. allArguments not saved
     * @returns {VNode}
     */
    confirmMessage() {
      const h = this.$createElement;
      let confirmMessages = [];

      let paragraphStyle = { style: "line-height: 16px; margin-bottom: 6px" };

      // do ajax call here

      //1. in use by x nodes
      if (this.showIsInUse) {
        confirmMessages.push(
          h(
            "p",
            paragraphStyle,
            __("Is currently in use by :useCount node:moreCount.", {
              useCount: this.contentForm.computed.is_in_use_count,
              moreCount:
                this.contentForm.computed.is_in_use_count > 1 ? "s" : ""
            })
          )
        );
      }

      // 2. parameter values too large
      if (this.hasParameterTestValuesTooLarge) {
        confirmMessages.push(
          h("p", paragraphStyle, `${this.parameterTestValuesTooLargeMessage}.`)
        );
      }

      // 3. allArguments not saved
      if (!this.allArgumentSaved) {
        confirmMessages.push(
          h("p", paragraphStyle, __("Delete unsaved argument and continue?"))
        );
      }

      return h("div", null, confirmMessages);
    },

    /**
     * check parameter test values too large
     * @returns {boolean}
     */
    hasParameterTestValuesTooLarge() {
      return this.parameterTestValuesTooLarge.length > 0;
    },

    /**
     * parameter test values too large
     * @returns {*[]}
     */
    parameterTestValuesTooLarge() {
      return _.map(
        _.filter(
          this.contentForm.javascript_function_parameters,
          javascriptFunctionParameter =>
            javascriptFunctionParameter.parameter_test_value.length > 4000
        ),
        testValue => testValue.name
      );
    },

    /**
     * parameters with too large test values message
     * @returns {string}
     */
    parameterTestValuesTooLargeMessage() {
      let parameterTestValuesTooLargeMessage = "";

      if (this.hasParameterTestValuesTooLarge) {
        // eslint-disable-next-line
        parameterTestValuesTooLargeMessage = __("Test Value for Argument:moreOrLess :parameterTestValuesTooLarge exceeds 4000 character limit, will be cleared on :saveButtonLabel", {
            moreOrLess:
              this.parameterTestValuesTooLarge.length === 1 ? "" : "s",
            parameterTestValuesTooLarge: _.join(
              this.parameterTestValuesTooLarge,
              ", "
            ),
            saveButtonLabel: this.saveButtonLabel
          }
        );
      }

      return parameterTestValuesTooLargeMessage;
    },

    /**
     * save button label
     * @returns {string}
     */
    saveButtonLabel() {
      return this.id === -1 ? __("Create") : __("Update");
    },

    /**
     * is disabled
     * @returns {boolean}
     */
    isDisabled() {
      return (
        !this.contentUpdated ||
        !this.can("content.javascript-functions.write") ||
        this.isMaxCodeLimitExceeded
      );
    },

    /**
     * check size of javascript function content
     * @returns {boolean}
     */
    isMaxCodeLimitExceeded() {
      return this.contentForm.function_content.length > maxCodeLimitCharacters;
    },
    /**
     * check a record has been edited if previously created
     */
    showIsInUse() {
      return (
        this.id !== -1 &&
        this.contentNotChanged === false &&
        parseInt(this.contentForm.computed.is_in_use_count) > 0
      );
    },
    /**
     * confirm title
     * @returns {string}
     */
    confirmTitle() {
      return __("Are you sure you want to update this Javascript function?");
    },

    /**
     * Return the form submission state
     * @returns {bool}
     */
    isSubmitting() {
      return this.$store.state.javascripts.loading;
    },

    /**
     * show confirm save
     * @returns {*|boolean}
     */
    showConfirmSave() {
      return (
        this.showIsInUse ||
        !this.allArgumentSaved ||
        this.hasParameterTestValuesTooLarge
      );
    }
  },

  methods: {
    ...mapActions("javascripts", {
      createJSFunc: "createJavascriptFunction",
      updateJsFunc: "updateJavascriptFunction",
      setLoading: "setLoading",
      deleteContentMethod: "deleteJavascriptFunction",
      undoDeleteContent: "undoDeleteJavascriptFunction",
      safeDeleteContent: "safeDeleteJavascriptFunction",
      refreshJavascriptFunction: "refreshJavascriptFunction"
    }),
    //checkInUseCount
    ...mapActions("folders", {
      checkInUseCount: "checkInUseCount"
    }),

    /**
     * clear parameter test values too large before submit
     * @returns {*[]}
     */
    clearParameterTestValuesTooLarge() {
      this.contentForm.javascript_function_parameters = _.map(
        _.cloneDeep(this.contentForm.javascript_function_parameters),
        javascriptFunctionParameter => {
          if (javascriptFunctionParameter.parameter_test_value.length > 4000) {
            javascriptFunctionParameter.parameter_test_value = "";
          }
          return javascriptFunctionParameter;
        }
      );
    },

    addToUploadedFiles(path) {
      this.filesUploaded.push(path);
    },

    /**
     * confirm save when JS in use on node
     */
    confirmSave() {
      this.$confirm(this.confirmMessage, this.confirmTitle, {
        confirmButtonText: __("Confirm"),
        cancelButtonText: __("Cancel"),
        type: "warning"
      })
        .then(() => {
          // any params with too large test content must be cleared out
          if (this.hasParameterTestValuesTooLarge) {
            this.clearParameterTestValuesTooLarge();
          }
          this.submitForm();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: __("canceled")
          });
        });
    },
    submitForm() {
      this.setLoading(true);
      this.$refs.codeEditor.validate(valid => {
        if (valid) {
          this.contentForm.ac_id = this.selectedAccountId;
          this.contentForm.folder_id = this.selectedFolderId;
          const process = Object.keys(this.contentForm).includes(
            "javascript_function_id"
          )
            ? this.updateJsFunc
            : this.createJSFunc;
          process(this.contentForm)
            .then(data => {
              this.$message({
                type: "success",
                message: __("Operation successful")
              });
              EventBus.$emit("list-changed", data.data);
              this.handleCancel();
            })
            .catch(err => {
              this.$message({
                type: "error",
                message: err.message
              });
            });
        } else {
          this.setLoading(false);
        }
      });
    },

    openMoveToFolderDialog() {
      let content_ids = [this.contentForm.javascript_function_id];
      if (this.checkedContents.length) {
        content_ids = this.checkedContents.map(item => {
          if (item && item.javascript_function_id) {
            return item.javascript_function_id;
          }
        });
      }

      EventBus.$emit("open-move-to-folder-modal", {
        content_ids: [...content_ids],
        content_model: "JavascriptFunction"
      });
    },

    openCheckInUseDialog() {
      EventBus.$emit("open-check-in-use-modal", {
        content_model: "JavascriptFunction",
        content_id: this.contentForm.javascript_function_id
      });
    },

    handleAction(command) {
      switch (command) {
        case EDIT_ACTION:
          this.handleEdit();
          break;
        case MOVE_ACTION:
          this.openMoveToFolderDialog();
          break;
        case CHECK_IN_USE_ACTION:
          this.openCheckInUseDialog();
          break;
        case REFRESH_ACTION:
          this.refreshCheckInUse();
          break;
        case DELETE_ACTION:
          this.handleDelete(
            this.contentForm,
            "JavascriptFunction",
            this.contentForm.javascript_function_id
          );
          break;
      }
    },
    /**
     * update selected libraries
     * @param selectedLibraries
     */
    updateSelectedLibraries(selectedLibraries) {
      this.contentForm.libraries = _.cloneDeep(selectedLibraries);
    },

    /**
     * check js function in use
     * @param content_model
     * @param content_id
     * @returns {Promise<unknown>}
     */
    checkJavascriptFunctionInUseCount({ content_model, content_id }) {
      return new Promise((resolve, reject) => {
        this.checkInUseCount({
          content_model,
          content_id
        })
          .then(data => {
            this.$set(
              this.contentForm.computed,
              "is_in_use_count",
              data.is_in_use_count
            );
            resolve();
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    refreshCheckInUse() {
      // call backend api
      EventBus.$emit("call-refresh-check-in-use-job", {
        content_model: "JavascriptFunction",
        content_id: this.contentForm.javascript_function_id,
        content_name: this.contentForm.name,
        refresh: this.refreshJavascriptFunction
      });
    }
  },
  watch: {
    contentForm: {
      deep: true,
      handler(newVal, oldVal) {
        if (
          !_.isEmpty(newVal) &&
          newVal !== oldVal &&
          newVal.javascript_function_id !== undefined &&
          newVal.javascript_function_id !== -1
        ) {
          this.checkJavascriptFunctionInUseCount({
            content_model: "JavascriptFunction",
            content_id: newVal.javascript_function_id
          });
        }
      }
    },
    selectedAccountId: {
      immediate: true,
      async handler(newVal) {
        this.showCodeGenerateTool = newVal !== "all";
      }
    }
  },
  created() {
    EventBus.$on(this.forceDeleteEventName, ({ content }) => {
      this.initiateDelete(content);
    });

    EventBus.$on(this.safeDeleteEventName, ({ content }) => {
      this.initiateSafeDelete(content);
    });
  },
  beforeDestroy() {
    EventBus.$off(this.forceDeleteEventName);
    EventBus.$off(this.safeDeleteEventName);
  }
};
</script>

<style scoped lang="scss">
@import "~@/styles/content-edit-info.scss";

::v-deep .variable-form-dialog {
  min-width: 600px !important;
  padding: 0;

  .el-dialog__header {
    padding: 0;
  }

  .el-dialog__body {
    padding: 0;
    overflow: hidden;
  }

  .el-dialog__footer {
    padding: 0;
  }
}

.content-sidebar {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;

  .content-sidebar-body {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .content-sidebar-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    // todo to update/remove after getting rid of global content creator from outside canvas
    margin-bottom: 50px;
  }
}

.variable-form-wrapper {
  display: flex;
  justify-content: center;
}

.form-footer {
  display: flex;

  .empty {
    flex: 1;
  }

  .button-group {
    margin-right: 25%;
  }
}

.slide-fade-enter-active {
  transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-leave-active {
  transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active for <2.1.8 */
 {
  transform: translateX(10px);
  opacity: 0;
}
</style>
