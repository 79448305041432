<!--suppress ALL -->
<template>
  <div
    style="width: 100%;display: flex; flex-direction: column; align-items: center;"
    @click="handleClearSelection"
  >
    <div style="width: 100%; position: relative">
      <page-header
        style="padding: 20px 30px 0 30px;width: calc(100% - 45px)"
        :title="__('Tasks')"
        :navItems="navItems"
        @navItemChanged="handleNavItemChanged"
      ></page-header>
      <!--        <el-button class="createBtn" @click.stop="createContentItem"-->
      <!--          >Create Task-->
      <!--        </el-button>-->
      <el-button
        v-show="cantWriteOrNoTaskFilter"
        :disabled="cantWriteOrNoTaskFilter"
        class="createBtn"
        style="position: absolute; right: 25px; top: 25px;"
        >{{
          __("Create :task_filter Task", {
            task_filter: capitalize(taskFilter)
          })
        }}</el-button
      >
      <el-dropdown
        v-show="!cantWriteOrNoTaskFilter"
        split-button
        type="primary"
        class="createTaskDropDown"
        @click.stop="createTaskItem(taskFilter)"
        @command="createTaskItem"
      >
        {{
          __("Create :task_filter Task", {
            task_filter: capitalize(taskFilter)
          })
        }}
        <el-dropdown-menu slot="dropdown">
          <div>
            <el-tooltip
              effect="dark"
              placement="right"
              :content="tooltipMessage"
              :open-delay="500"
              :disabled="permit('Voice Task')"
            >
              <span class="wrapper">
                <el-dropdown-item
                  command="voice"
                  :disabled="!permit('Voice Task')"
                  >{{ __("Create Voice Task") }}
                </el-dropdown-item>
              </span>
            </el-tooltip>
          </div>
          <div>
            <el-tooltip
              effect="dark"
              placement="right"
              :content="tooltipMessage"
              :open-delay="500"
              :disabled="permit('Messaging')"
            >
              <span class="wrapper">
                <el-dropdown-item
                  command="messaging"
                  :disabled="!permit('Messaging')"
                  >{{ __("Create Messaging Task") }}
                </el-dropdown-item>
              </span>
            </el-tooltip>
          </div>
          <div>
            <el-tooltip
              effect="dark"
              placement="right"
              :content="tooltipMessage"
              :open-delay="500"
              :disabled="permit('Workflow')"
            >
              <span class="wrapper">
                <el-dropdown-item
                  command="workflow"
                  :disabled="!permit('Workflow')"
                  >{{ __("Create Workflow Task") }}
                </el-dropdown-item>
              </span>
            </el-tooltip>
          </div>
          <div>
            <el-tooltip
              effect="dark"
              placement="right"
              :content="tooltipMessage"
              :open-delay="500"
              :disabled="permit('ChatBot')"
            >
              <span class="wrapper">
                <el-dropdown-item
                  command="chatbot"
                  :disabled="!permit('ChatBot')"
                  >{{ __("Create ChatBot Task") }}
                </el-dropdown-item>
              </span>
            </el-tooltip>
          </div>
          <div>
            <el-tooltip
              effect="dark"
              placement="right"
              :content="tooltipMessage"
              :open-delay="500"
              :disabled="permit('WhatsApp')"
            >
              <span class="wrapper">
                <el-dropdown-item
                  command="whatsapp"
                  :disabled="!permit('WhatsApp')"
                  >{{ __("Create WhatsApp Task") }}
                </el-dropdown-item>
              </span>
            </el-tooltip>
          </div>
        </el-dropdown-menu>
      </el-dropdown>
      <div style="width: 100%">
        <el-main style="padding-top: 10px">
          <div style="display: flex; color: #696969; padding-left: 10px">
            <div
              style="display: flex;"
              v-if="['voice', 'messaging', 'whatsapp'].includes(taskFilter)"
            >
              <div style="padding-right: 20px">
                {{ __("Phone Numbers - Total") }}:
                <code>{{ phoneNumberSummary.total }}</code>
              </div>
              <div style="padding-right: 20px">
                {{ __("In Use") }} :
                <code>{{ phoneNumberSummary.in_use }}</code>
              </div>
              <div style="padding-right: 20px">
                {{ __("Available") }} :
                <code>{{ phoneNumberSummary.available }}</code>
              </div>
            </div>
            <div>
              <div
                style="display: flex"
                v-loading="loading"
                v-if="
                  ['messaging', 'workflow', 'chatbot', 'whatsapp'].includes(
                    taskFilter
                  )
                "
              >
                <div
                  v-if="
                    ['messaging', 'chatbot', 'whatsapp'].includes(taskFilter)
                  "
                >
                  {{ __("Number of Messages Left:") }}
                </div>
                <div v-if="['workflow'].includes(taskFilter)">
                  {{ __("Number of Runs Left:") }}
                </div>
                <el-tooltip
                  :disabled="!checkFeatureWarningLimitReached"
                  :content="featureWarningLimitReachedContent"
                  placement="right"
                >
                  <code
                    :style="checkFeatureWarningLimitReached && { color: 'red' }"
                    >{{ featureUsageAndLimit.featureUsagesLeft }}</code
                  >
                </el-tooltip>
                <div
                  v-if="featureUsageAndLimit.featureBurstUsagesLeft"
                  style="display: flex"
                >
                  <div style="padding-left: 20px">
                    {{ __("Number of Burst Messages Left") }}:
                  </div>
                  <el-tooltip
                    :disabled="!checkFeatureBurstWarningLimitReached"
                    :content="checkFeatureWarningLimitTooltip"
                    placement="right"
                  >
                    <code
                      :style="
                        checkFeatureBurstWarningLimitReached && { color: 'red' }
                      "
                      >{{ featureUsageAndLimit.featureBurstUsagesLeft }}</code
                    >
                  </el-tooltip>
                </div>
              </div>
            </div>
          </div>
          <el-form class="form-container">
            <PaginationToolbar
              :sort-by-options="sortByOptions"
              :content-api="contentAPI"
              @success="$emit('replaceContent', $event)"
              :auto-refresh-content="!modalCoverContent && featureAvailable"
              :refresh-interval="10000"
              :key="`tasks-list`"
              :page-sizes="[15, 30, 45, 60]"
              :page-size="15"
              show-slot
              style="padding: 0 10px"
              :search-options="searchOptions"
              @search="handleSearch"
            >
              <div
                style="display: flex; align-items: center; padding: 0 10px"
                class="buttonGroup"
                slot="buttonGroup"
              >
                <el-tooltip
                  :content="__('Show only favorite Tasks')"
                  placement="top"
                >
                  <el-button
                    icon="el-icon-star-off"
                    circle
                    class="circleBtn"
                    style="height: 36px;width: 36px"
                    :disabled="
                      !tasks.length && !isFavoriteSelected && !isMappedSelected
                    "
                    @click="toggleFavoriteTasks"
                    :class="{
                      selected: isFavoriteSelected
                    }"
                  ></el-button>
                </el-tooltip>
                <el-tooltip
                  :content="__('Show only mapped Tasks')"
                  placement="top"
                >
                  <el-button
                    icon="el-icon-phone-outline"
                    circle
                    class="circleBtn"
                    style="height: 36px;width: 36px"
                    @click="toggleMappedTasks"
                    :disabled="
                      !tasks.length && !isFavoriteSelected && !isMappedSelected
                    "
                    :class="{
                      selected: isMappedSelected
                    }"
                  ></el-button>
                </el-tooltip>
              </div>
            </PaginationToolbar>
          </el-form>
          <el-scrollbar
            v-loading="checkInUseLoading"
            :native="true"
            :element-loading-text="__('checking usages') + '...'"
          >
            <div
              class="contentCardsContainer"
              v-loading="
                loading ||
                  (taskDuplicating && !showTaskExportConfigurationModal)
              "
              :element-loading-text="getLoadingText"
            >
              <el-row
                type="flex"
                justify="start"
                class="list-cards"
                v-if="tasks.length"
              >
                <TaskCard
                  v-for="(task, index) in tasks"
                  :task="task"
                  :class="{
                    selected: selectedItemIndex === index,
                    cardContainer: true,
                    locked: task.protected
                  }"
                  :key="index"
                  :calls="getCalls(task)"
                  @handleSelect="handleSelect(index, task)"
                  @handleEdit="handleEdit(index, task)"
                  @updateTaskLock="updateTaskLock(task)"
                  @updateTaskFavorite="updateTaskFavorite(task)"
                  @handleDelete="handleDelete(task, 'Task', task.task_id)"
                  @handleCheckItemInUse="
                    handleCheckItemInUse('Task', task.task_id)
                  "
                  @duplicate="temporaryHandleInitialDuplicate"
                  @handleManageNumbers="handleShowManageNumbers(index, task)"
                  @handleTaskPublish="handleTaskPublish(index, task)"
                  @initiateExport="handleInitiateExport"
                ></TaskCard>
                <manage-numbers
                  :task="tasks[this.selectedItemIndex]"
                  :open-modal="openManageNumbersModal"
                  @handleCancel="handleCancelManageNumbers"
                ></manage-numbers>
                <PublishTask
                  :task_id="getTaskIdForIndex"
                  :template_image="getTaskImageForIndex"
                  :open-modal="openPublishTaskModal"
                  :task-stats="taskToExportStats"
                  :export-config="exportConfiguration"
                  :loading-snap-shot="loadingSnapshot"
                  :snapshot-loading-text="getLoadingText"
                  :task="taskToExport"
                  v-if="openPublishTaskModal"
                  @handleCancel="handleCancelPublishTask"
                ></PublishTask>
              </el-row>

              <div v-show="shouldShowEmptyTasksCard">
                <div
                  style="width: 100%; display: flex; justify-content: center; align-items: center; height: 100%;flex-direction: column"
                >
                  <img
                    :src="require('@/assets/images/emptyStates/tasks.svg')"
                    onload="SVGInject(this)"
                    alt="No Data"
                    height="286"
                    width="286"
                  />
                  <div
                    style="font-weight: bold; padding-top: 10px; color: #181F29"
                  >
                    {{ __("No :taskFilter Tasks", { taskFilter: taskFilter }) }}
                  </div>
                  <div style="color: #696969">
                    {{
                      __("Get started by creating a :taskFilter task.", {
                        taskFilter: taskFilter
                      })
                    }}
                  </div>
                  <el-button
                    v-show="cantWriteOrNoTaskFilter"
                    :disabled="cantWriteOrNoTaskFilter"
                    style="position: relative;top: 0;right: 0; margin: 20px"
                    class="createBtn"
                    >{{
                      __("Create :task_filter Task", {
                        task_filter: capitalize(taskFilter)
                      })
                    }}</el-button
                  >
                  <el-dropdown
                    split-button
                    type="primary"
                    class="createTaskDropDown"
                    style="position: relative;top: 0;right: 0; margin: 20px"
                    @click.stop="createTaskItem(taskFilter)"
                    v-show="!cantWriteOrNoTaskFilter"
                    @command="createTaskItem"
                  >
                    {{
                      __("Create :task_filter Task", {
                        task_filter: capitalize(taskFilter)
                      })
                    }}
                    <el-dropdown-menu slot="dropdown">
                      <div>
                        <el-tooltip
                          effect="dark"
                          placement="right"
                          :content="tooltipMessage"
                          :open-delay="500"
                          :disabled="permit('Voice Task')"
                        >
                          <span class="wrapper">
                            <el-dropdown-item
                              command="voice"
                              :disabled="!permit('Voice Task')"
                              >{{ __("Create Voice Task") }}
                            </el-dropdown-item>
                          </span>
                        </el-tooltip>
                      </div>
                      <div>
                        <el-tooltip
                          effect="dark"
                          placement="right"
                          :content="tooltipMessage"
                          :open-delay="500"
                          :disabled="permit('Messaging')"
                        >
                          <span class="wrapper">
                            <el-dropdown-item
                              command="messaging"
                              :disabled="!permit('Messaging')"
                              >{{ __("Create Messaging Task") }}
                            </el-dropdown-item>
                          </span>
                        </el-tooltip>
                      </div>
                      <div>
                        <el-tooltip
                          effect="dark"
                          placement="right"
                          :content="tooltipMessage"
                          :open-delay="500"
                          :disabled="permit('Workflow')"
                        >
                          <span class="wrapper">
                            <el-dropdown-item
                              command="workflow"
                              :disabled="!permit('Workflow')"
                              >{{ __("Create Workflow Task") }}
                            </el-dropdown-item>
                          </span>
                        </el-tooltip>
                      </div>
                      <div>
                        <el-tooltip
                          effect="dark"
                          placement="right"
                          :content="tooltipMessage"
                          :open-delay="500"
                          :disabled="permit('ChatBot')"
                        >
                          <span class="wrapper">
                            <el-dropdown-item
                              command="chatbot"
                              :disabled="!permit('ChatBot')"
                              >{{ __("Create ChatBot Task") }}
                            </el-dropdown-item>
                          </span>
                        </el-tooltip>
                      </div>
                      <div>
                        <el-tooltip
                          effect="dark"
                          placement="right"
                          :content="tooltipMessage"
                          :open-delay="500"
                          :disabled="permit('WhatsApp')"
                        >
                          <span class="wrapper">
                            <el-dropdown-item
                              command="whatsapp"
                              :disabled="!permit('WhatsApp')"
                              >{{ __("Create WhatsApp Task") }}
                            </el-dropdown-item>
                          </span>
                        </el-tooltip>
                      </div>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </div>
              <div v-show="shouldShowEmptySearchCriteriaMessage">
                <div
                  style="width: 100%; display: flex; justify-content: center; align-items: center;flex-direction: column"
                >
                  <div
                    style="font-weight: bold; padding-top: 50px; color: #181F29"
                  >
                    {{ __("No Tasks found for the search criteria!") }}
                  </div>
                </div>
              </div>
            </div>
          </el-scrollbar>
        </el-main>
      </div>
    </div>
    <delete-item
      :show-force-delete="false"
      :title="__('Task in use')"
      :description="taskInUseErrorDesc"
      key="task-delete-item"
      :delete-confirmation-event="deleteConfirmationEvent"
    ></delete-item>
    <check-item-in-use
      :check-in-use-dialog-event="checkInUseDialogEvent"
    ></check-item-in-use>
    <el-dialog
      v-if="showTaskExportConfigurationModal && !showNewLayout"
      :visible.sync="showTaskExportConfigurationModal"
      custom-class="task-export-modal"
      append-to-body
      destroy-on-close
      fullscreen
      v-loading="exportingTask"
      :element-loading-text="getLoadingText"
    >
      <div slot="title" class="dialog-header">
        <responsive-container>
          <h1 class="title">{{ taskToExport.task_name }}</h1>
        </responsive-container>
      </div>
      <!--      <el-scrollbar v-loading="loadingSnapshot" :native="false">-->
      <div
        v-loading="loadingSnapshot"
        :element-loading-text="getLoadingText"
        class="export-stats-container"
      >
        <responsive-container>
          <task-export-configure
            :task-stats="taskToExportStats"
            :export-config="exportConfiguration"
            :task="taskToExport"
        /></responsive-container>
      </div>
      <!--      </el-scrollbar>-->

      <div slot="footer" class="dialog-footer">
        <responsive-container>
          <el-button
            :disabled="loadingSnapshot || exportingTask || errored"
            type="primary"
            @click="initiateExport"
            >{{ __("Export task") }}</el-button
          >
          <el-button
            type="primary"
            @click="showTaskExportConfigurationModal = false"
            >{{ __("Cancel") }}</el-button
          >
        </responsive-container>
      </div>
    </el-dialog>
    <el-dialog
      v-if="showTaskExportConfigurationModal && showNewLayout"
      :visible.sync="showTaskExportConfigurationModal"
      custom-class="task-export-modal-new"
      append-to-body
      destroy-on-close
      show-close
    >
      <div
        v-loading="
          loadingSnapshot ||
            (this.taskDuplicating && this.showTaskExportConfigurationModal) ||
            (this.exportingTask && this.showTaskExportConfigurationModal)
        "
        :element-loading-text="getLoadingText"
        class="export-stats-container-new"
      >
        <new-task-export-base-configure
          :action-type="taskActionType"
          :task-collection-count="taskCollectionCount"
          @cancel="showTaskExportConfigurationModal = false"
          @duplicate="initialDuplicate"
          @export="newExport"
          :task-snap="taskToExportStats"
          :export-config="exportConfiguration"
        >
        </new-task-export-base-configure>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import PaginationToolbar from "@/components/PaginationToolbar";
import BaseContent from "@/views/build/content/mixins/BaseContent";
import PageHeader from "@/components/PageHeader";
import _ from "lodash";
import { EventBus } from "@/EventBus";
import TaskCard from "@/views/build/tasks/create-task/components/TaskCard";
import ManageNumbers from "./ManageNumbers";
import PublishTask from "@/views/build/tasks/pages/PublishTask";
import BackButtonHandler from "@/components/BackButtonHandler";
import TaskTypes from "@/constants/nav-items/task-types";
import TaskExportConfigure from "@/views/build/tasks/create-task/components/TaskExport/TaskExportConfigure";
import FileDownloader from "@/mixins/FileDownloader";
import ResponsiveContainer from "@/components/ResponsiveContainer";
import DeleteItem from "@/components/DeleteItem";
import CheckItemInUse from "@/components/CheckItemInUse";
import TaskExport from "@/views/build/tasks/create-task/components/TaskExport/TaskExport";
import NewTaskExportBaseConfigure from "@/views/build/tasks/create-task/components/NewTaskExport/NewTaskExportBaseConfigure.vue";

export default {
  name: "TasksList",
  mixins: [BaseContent, BackButtonHandler, FileDownloader, TaskExport],
  components: {
    CheckItemInUse,
    ResponsiveContainer,
    TaskExportConfigure,
    NewTaskExportBaseConfigure,
    PublishTask,
    PaginationToolbar,
    PageHeader,
    ManageNumbers,
    TaskCard,
    DeleteItem
  },
  data() {
    return {
      openManageNumbersModal: false,
      openPublishTaskModal: false,
      tooltipMessage:
        __("This feature is either not provisioned in your business") +
        "\n" +
        __("plan or you do not have permission to view it."),
      sortByColumns: ["task_name", "task_id"],
      loadingName: false,
      inputValue: "",
      navItems: TaskTypes,
      searchOptions: JSON.stringify({ is_favorite: 0, is_mapped: 0 }),
      isSeachEmpty: true,
      forceDeleteEventName: "initiate-force-delete-Task",
      deleteConfirmationEvent: "open-delete-confirmation-dialog-box-task",
      checkInUseDialogEvent: "open-check-in-use-dialog-box-task",
      useNewTaskExportLayout: false
    };
  },

  computed: {
    ...mapState("app", {
      selectedAccountId: state => state.selectedAccountId,
      enabledFeatures: state => state.enabled_features
    }),
    ...mapState("appfeatures", {
      featureUsageAndLimit: state => state.featureUsageAndLimit
    }),
    ...mapState("folders", {
      checkInUseLoading: state => state.checkInUseLoading,
      checkInUseErrorMessage: state => state.checkInUseErrorMessage
    }),
    ...mapState("tasks", {
      loading: state => state.loading,
      taskDuplicating: state => state.taskDuplicating,
      // tasks: state => state.tasks,
      taskFilter: state => state.taskFilter,
      phoneNumberSummary: state => state.phoneNumberSummary
    }),

    filterSelected() {
      let searchOptions = JSON.parse(this.searchOptions);
      return !!searchOptions.is_favorite || !!searchOptions.is_mapped;
    },

    isFavoriteSelected() {
      let searchOptions = JSON.parse(this.searchOptions);
      return !!searchOptions.is_favorite;
    },

    isMappedSelected() {
      let searchOptions = JSON.parse(this.searchOptions);
      return !!searchOptions.is_mapped;
    },

    checkFeatureWarningLimitReached() {
      if (this.featureUsageAndLimit.featureUsagesLeft === 0) {
        return true;
      }
      if (
        this.featureUsageAndLimit.featureUsagesLeft /
          this.featureUsageAndLimit.featureHardLimit <
        0.05
      ) {
        return true;
      } else {
        return false;
      }
    },

    checkFeatureBurstWarningLimitReached() {
      if (this.featureUsageAndLimit.featureBurstUsagesLeft === 0) {
        return true;
      }
      if (
        this.featureUsageAndLimit.featureBurstUsagesLeft /
          this.featureUsageAndLimit.featureBurstLimit <
        0.05
      ) {
        return true;
      } else {
        return false;
      }
    },

    ...mapGetters("tasks", {
      tasksOfType: "tasksOfType"
    }),

    tasks() {
      return this.tasksOfType(this.taskFilter);
    },

    activeNavItem() {
      return _.find(this.navItems, navItem => navItem.active);
    },
    getTaskIdForIndex() {
      if (this.tasks[this.selectedItemIndex]) {
        return this.tasks[this.selectedItemIndex].task_id;
      } else {
        return -1;
      }
    },
    getTaskImageForIndex() {
      if (this.tasks[this.selectedItemIndex]) {
        return this.tasks[this.selectedItemIndex].task_image;
      } else {
        return "";
      }
    },
    cantWriteOrNoTaskFilter() {
      return !this.canWrite() || !this.taskFilter;
    },

    featureAvailable() {
      return this.enabledFeatures.includes(this.capitalize(this.taskFilter));
    },

    shouldShowEmptySearchCriteriaMessage() {
      return (
        !this.tasks.length &&
        !this.loading &&
        (!this.isSeachEmpty || this.filterSelected)
      );
    },

    shouldShowEmptyTasksCard() {
      return (
        !this.tasks.length &&
        !this.loading &&
        this.isSeachEmpty &&
        !this.filterSelected
      );
    },

    /**
     * Content for the task in use error description
     * @returns {String} Translated message for the task in use error description
     */
    taskInUseErrorDesc() {
      // eslint-disable-next-line
      return __("This task cannot be deleted as it is used in some link task nodes. Deleting this task might break the associated tasks.");
    },

    /**
     * Message for the feature warning limit reached content
     * @returns {String} Translated content for the feature warning limit reached content
     */
    featureWarningLimitReachedContent() {
      return ["messaging", "chatbot", "whatsapp"].includes(this.taskFilter)
      /* eslint-disable */
        ? __("Task will not be able to send/receive any messages when it will reach to 0")
        : __("Task will not be run via api/scheduler when it will reach to 0");
      /* eslint-enable */
    },

    showNewLayout() {
      return (
        this.useNewTaskExportLayout &&
        (this.taskActionType === "Duplicate" ||
          this.taskActionType === "Export")
      );
    }
  },

  created() {
    EventBus.$on(this.forceDeleteEventName, ({ content }) => {
      this.initiateDelete(content);
    });
  },
  beforeDestroy() {
    EventBus.$off(this.forceDeleteEventName);
  },

  methods: {
    ...mapActions("tasks", {
      contentAPI: "getTasks",
      getPhoneNumberSummary: "getPhoneNumberSummary",
      deleteContentMethod: "deleteTask",
      undoDeleteContent: "undoDeleteTask",
      setTaskNameEditable: "setTaskNameEditable",
      setTaskNameUnEditable: "setTaskNameUnEditable",
      updateTaskName: "updateTaskName",
      setTaskFilter: "setTaskFilter",
      updateTaskFavorite: "updateTaskFavorite",
      updateTaskLock: "updateTaskLock",
      getLanguages: "getLanguages",
      changeLoading: "changeLoading",
      changeTaskDuplicationDoneFlag: "changeTaskDuplicationDoneFlag",
      changeTaskDuplicatingFlag: "changeTaskDuplicatingFlag"
    }),

    ...mapActions("appfeatures", {
      getFeatureUsageAndLimit: "getFeatureUsageAndLimit"
    }),

    ...mapActions("app", {
      getEnabledFeaturesForUser: "getEnabledFeaturesForUser"
    }),

    handleSearch(val) {
      this.isSeachEmpty = _.isEmpty(val);
    },

    toggleFavoriteTasks() {
      let searchOptions = JSON.parse(this.searchOptions);
      if (!searchOptions.is_favorite) {
        this.searchOptions = JSON.stringify({
          ...searchOptions,
          is_favorite: 1
        });
      } else {
        this.searchOptions = JSON.stringify({
          ...searchOptions,
          is_favorite: 0
        });
      }
    },

    toggleMappedTasks() {
      let searchOptions = JSON.parse(this.searchOptions);
      if (!searchOptions.is_mapped) {
        this.searchOptions = JSON.stringify({
          ...searchOptions,
          is_mapped: 1
        });
      } else {
        this.searchOptions = JSON.stringify({
          ...searchOptions,
          is_mapped: 0
        });
      }
    },

    backButtonHandler() {
      if (this.openManageNumbersModal) {
        this.handleCancelManageNumbers();
        return false;
      }

      if (this.showTaskExportConfigurationModal) {
        this.showTaskExportConfigurationModal = false;
        return false;
      }

      if (this.openPublishTaskModal) {
        this.handleCancelPublishTask();
        return false;
      }
      return true;
    },

    getCalls(task) {
      switch (task.task_type) {
        case "voice":
        case "workflow":
        case "messaging":
        case "whatsapp":
        case "chatbot":
          return task.active_calls;
        default:
          return 443;
      }
    },
    permit(taskType) {
      return this.can(taskType) && this.canWrite();
    },

    assignTaskFilter(taskType) {
      if (taskType) {
        taskType = taskType.toLowerCase();
      }
      this.setTaskFilter(taskType);
    },

    showInput(task_id, task_name) {
      this.setTaskNameEditable(task_id);
      this.inputValue = task_name;
      this.$nextTick(() => {
        this.$refs.saveTaskName[0].focus();
      });
    },

    handleNavItemChanged(navItem, refreshList = true) {
      this.navItems = _.map(this.navItems, navItemRow => {
        navItemRow.active = false;
        return navItemRow;
      });
      let matchedItem = _.find(this.navItems, item => {
        return item.label.toLowerCase() === navItem.label.toLowerCase();
      });
      if (matchedItem) {
        matchedItem.active = true;
      }
      this.assignTaskFilter(navItem.label);
      if (["voice", "messaging", "whatsapp"].includes(this.taskFilter)) {
        this.getPhoneNumberSummary();
      }
      if (refreshList) {
        EventBus.$emit("list-changed", null, { page: 1 });
      }
    },

    onTaskDuplicationCompletionEvent(taskId, { request_id: requestId }) {
      this.stopListeningToTaskImportExportChannel(taskId, requestId);
      this.$message({
        message: __("Task duplicated successfully"),
        type: "success"
      });
      this.changeTaskDuplicationDoneFlag(true);
      this.showTaskExportConfigurationModal = false;
      EventBus.$emit("list-changed");
    },

    onTaskDuplicationErrorEvent(
      taskId,
      { request_id: requestId, request_info: requestInfo }
    ) {
      this.stopListeningToTaskImportExportChannel(taskId, requestId);
      this.$message({
        type: "error",
        message: _.get(requestInfo, "error") || __("Task duplication failed")
      });
      this.changeTaskDuplicationDoneFlag(true);
      this.changeTaskDuplicatingFlag(false);
    },

    capitalize(label) {
      return _.upperFirst(label);
    },
    createTaskItem(taskType) {
      this.assignTaskFilter(taskType);
      this.$emit("open-create-modal", taskType);
      EventBus.$emit("open-create-modal");
    },
    handleShowManageNumbers(index) {
      this.selectedItemIndex = index;
      this.openManageNumbersModal = true;
    },
    handleTaskPublish(index, task) {
      this.selectedItemIndex = index;
      this.openPublishTaskModal = true;
      this.getTaskSnapshot(task);
    },
    handleCancelManageNumbers(refreshList = false) {
      this.selectedItemIndex = -1;
      this.openManageNumbersModal = false;
      if (["voice", "messaging", "whatsapp"].includes(this.taskFilter)) {
        this.getPhoneNumberSummary();
      }
      if (refreshList) {
        EventBus.$emit("list-changed");
      }
    },
    handleCancelPublishTask() {
      this.selectedItemIndex = -1;
      this.openPublishTaskModal = false;
    },
    getNoTasksImage() {
      return require("@/assets/images/emptyStates/tasks.svg");
    },
    async toggleNewTaskExportLayout() {
      this.useNewTaskExportLayout = await this.showFeature(
        this.$getConst("MANAGE_IVA_COLLECTION")
      );
    },
    async temporaryHandleInitialDuplicate(task, actionType) {
      if (this.useNewTaskExportLayout) {
        this.handleInitialDuplicate(task, actionType);
      } else {
        await this.duplicate(task);
      }
    }
  },

  mounted() {
    if (this.selectedAccountId !== "all") {
      this.getEnabledFeaturesForUser(this.selectedAccountId);
    }
    let label = this.taskFilter;
    this.handleNavItemChanged(
      {
        label: this.capitalize(label)
      },
      false
    );
  },
  watch: {
    taskFilter: {
      immediate: true,
      handler: function(val) {
        if (
          this.activeNavItem &&
          val &&
          val.toLowerCase() !== this.activeNavItem.label.toLowerCase()
        )
          this.handleNavItemChanged({
            label: this.capitalize(val)
          });
        if (["messaging", "workflow", "chatbot", "whatsapp"].includes(val)) {
          this.getFeatureUsageAndLimit(val);
        }
      }
    },
    selectedAccountId: {
      immediate: true,
      async handler(newVal) {
        if (newVal === "all") {
          this.useNewTaskExportLayout = false;
        } else {
          await this.toggleNewTaskExportLayout();
        }
        this.$nextTick(() => {
          if (
            ["messaging", "workflow", "chatbot", "whatsapp"].includes(
              this.taskFilter
            )
          ) {
            this.getFeatureUsageAndLimit(this.taskFilter);
          }
          return Promise.all([
            this.getLanguages(),
            this.getPhoneNumberSummary()
          ])
            .then(() => {})
            .catch(err => console.log(err));
        });
      }
    },
    checkInUseErrorMessage: {
      handler() {
        if (this.checkInUseErrorMessage) {
          this.$message({
            type: "error",
            message: this.checkInUseErrorMessage
          });
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
@import "~@/styles/content-buttons.scss";
@import "~@/styles/pagination-toolbar.scss";
@import "~@/styles/button.scss";
.list-cards {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
  padding: 0 10px;
}

.createTaskDropDown {
  position: absolute;
  top: 25px;
  right: 25px;
  border: none;
  color: white;
  background: $content-theme-color;
  border-radius: 6px;

  ::v-deep button {
    height: 44px;
    background: var(--theme-color) !important;
    border-color: var(--theme-color) !important;
    &:hover {
      background: var(--theme-hover-color) !important;
      border-color: var(--theme-hover-color) !important;
      color: white;
    }
  }

  &:hover {
    ::v-deep button {
      background: $content-theme-hover-color;
      border-color: var(--theme-hover-color) !important;
    }
  }
}

.contentCardsContainer {
  height: calc(100vh - 300px);
  max-height: calc(100vh - 300px);
}

.selected {
  color: $content-theme-color;
  border-color: $content-theme-color;
  i {
    background-color: $content-theme-color;
  }
}

.export-stats-container {
  min-height: 75vh;
}

.export-stats-container-new {
  min-height: 25vh;
}

code {
  margin-left: 5px;
  color: $content-theme-color;
}
</style>

<style lang="scss">
.task-export-modal {
  margin-top: 0 !important;
  margin-bottom: 0;
  padding: 0 15px;
  height: 100%;

  .el-scrollbar__wrap {
    height: calc(100vh - 250px);
  }

  .dialog-header {
    .title {
      margin: 0;
    }
  }

  .dialog-footer {
    text-align: left;
  }
}

.task-export-modal-new {
  margin-top: 50px !important;
  margin-bottom: 50px !important;
  padding: 0 15px;
  min-width: 540px;
  max-width: 40%;

  .el-dialog__header {
    padding: 0;
    .el-dialog__headerbtn {
      z-index: 100;
    }
  }

  .el-dialog__body {
    padding: 10px;
  }
}
</style>
