<template>
  <div class="resetForm">
    <reset-two-fa-form />
  </div>
</template>

<script>
const ResetTwoFaForm = () => import("./components/Reset2FAForm.vue");
export default {
  name: "Reset2FA",
  components: {
    ResetTwoFaForm
  },
  mounted() {
    console.log("here");
  }
};
</script>

<style lang="scss" scoped>
.resetForm {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
