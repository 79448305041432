<script>
import List from "./pages/list";
import CreateOrEdit from "./pages/createOrEdit";
import BaseIndex from "@/views/build/content/mixins/BaseIndex";
import { mapActions, mapState } from "vuex";
import store from "@/store";

export default {
  name: "UsersManageIndex",
  mixins: [BaseIndex],
  components: {
    List,
    CreateOrEdit
  },

  data() {
    return {
      selectedContent: this.initContent()
    };
  },

  computed: {
    ...mapState("app", {
      userType: state => state.userType
    }),
    ...mapState("users", {
      contents: state => state.users
    })
  },

  methods: {
    ...mapActions("serviceproviders", {
      fetchAccountsForServiceProvider: "fetchAccountsForServiceProvider"
    }),

    initContent() {
      return {
        email: "",
        password: "",
        display_name: "",
        access_scope: "",
        twofa_enabled: false,
        user_status: false,
        sync_permission: true,
        pending_delete: false,
        user_permissions: [],
        user_role: "",
        user_role_scope: "",
        account_mappings: [
          { scope: "ac", accounts: [], service_providers: [] }
        ],
        ac_sp_map_role_permission: [],
        account_permissions: [
          {
            accounts: [],
            user_role: "",
            sync_permission: true
          }
        ],
        system_controlled_twofa: false
      };
    }
  },

  mounted() {
    // this.fetchAccountsForServiceProvider();
    this.contentType = "Users";
    this.primaryKey = "user_id";
    if (store.getters.userRole === "system") {
      this.scope = "sys";
    }
  }
};
</script>

<style lang="scss" scoped></style>
